import { FC, MouseEvent, useCallback, useEffect, useRef } from "react";
import { useMapContext, useMapRefsContext } from "@okopok/axes_context";
import classNames from "classnames";
import * as d3 from "d3";
import { D3DragEvent } from "d3";
import { observer } from "mobx-react";

import { useProject } from "models/project/project";

import { NodeType, useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";
import { Label } from "../label";
import { NodeIcon } from "../nodeIcon";
import { formatScale, getZoomParameters } from "../utils";

import { Icon } from "./icon";

import cn from "./nodes.module.less";

type NodeProps = {
  onClick: (id: string) => void;
  isActive: boolean;
  node: NodeType;
};

const Node: FC<NodeProps> = observer(({ node, isActive, onClick }) => {
  const nodeRef = useRef(null);

  const { scale, zoom } = useMapContext();
  const { zoomedScale } = useMapRefsContext();
  const project = useProject();
  const manager = useInfrastructureMapContext();

  const { zoomScale } = getZoomParameters(zoom);

  const isFactual = project?.actualStateDate.isAfter(node.startedAt);

  const onPositionChanged = useCallback(
    (event: D3DragEvent<SVGElement, any, any>, uuid: string) => {
      const { x, y } = formatScale(event, zoomedScale);
      manager.updateNodePosition({ x, y, uuid });
      manager.updateCursorPosition({ x, y });
    },
    [manager, zoomedScale]
  );

  useEffect(() => {
    const dragHandler = d3
      .drag()
      .on("drag", (drag) => {
        if (manager.isCreate && !node.isFactual) {
          onPositionChanged?.(drag, node.uuid);
        }
      })
      .on("end", () => manager.updateDragging(false));
    d3.select(nodeRef.current).call(dragHandler as any);
  }, [onPositionChanged, manager, manager.isCreate, node.uuid, node.isFactual]);

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    onClick?.(node.uuid);
    if (manager.isSelection) {
      manager.selection.setSelectedNodeIds(node.uuid, !!(event.ctrlKey || event.metaKey || event.shiftKey));
    }
    if (manager.isCreate) {
      manager.pushPipe(node);
    }
  };

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      manager.tooltip.setActiveObject({ uuid: node.uuid, type: "node" });
    },
    [manager, node.uuid]
  );

  const handleMouseLeave = () => {
    manager.tooltip.setActiveObject(null);
  };

  return (
    <g
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      className={classNames((manager.isDrawing || node.isDisabled || node.isDisabledDate) && cn.disabled)}
      transform={`translate(${scale.x(node.x)}, ${scale.y(node.y)}) scale(${zoomScale})`}
    >
      <NodeIcon displacement={28 / 2}>
        {manager.showMapNodeText && (
          <Label y={-15} x={-12}>
            {node.title}
          </Label>
        )}
        <g ref={nodeRef} className={cn.icon}>
          <Icon
            isSelected={isActive}
            isDisabled={node.isDisabled}
            isDisabledDate={!!node.isDisabledDate}
            type={node.type}
            isFactual={isFactual}
            pipeMode={manager.pipeMode}
          />
        </g>
      </NodeIcon>
    </g>
  );
});

export { Node };
