import { Button } from "antd";

import { global } from "models/global";
import { save } from "services/back/calculate/utils";

import cn from "./errorDownloadMessage.module.less";

const errorDownloadMessage = (text: string, file: any) => {
  return (
    <div className={cn.warningBlock}>
      <div className={cn.warningText}>
        {text} (почта технической поддержки <a href="mailto:iprm_tehpod@lukoil.com">iprm_tehpod@lukoil.com</a>, приложите файл с полной информацией о
        запросе) <Button onClick={() => save(file)}>Скачать файл с полной информацией о запросе</Button>
      </div>
      <Button className={cn.closeWarning} onClick={() => global.messageApi?.destroy()}>
        X
      </Button>
    </div>
  );
};

export { errorDownloadMessage };
