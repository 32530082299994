import { reqCamel } from "utils/request";

import { ProjectParticipantInfo } from "./projectParticipants";

type UserInfo = ProjectParticipantInfo & { title: string; roles: string[] };

const getUsers = async (): Promise<UserInfo[]> =>
  (await reqCamel.get<UserInfo[]>("users")).map(({ fullName, ...rest }) => ({
    ...rest,
    fullName,
    title: fullName,
  }));

const addUser = async (email: string) => await reqCamel.post<UserInfo>("users/create", { email });

const deleteUser = async (email: string) => await reqCamel.delete<{ id: number }>("users", { email });

export { addUser, deleteUser, getUsers };
