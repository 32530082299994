import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction, when } from "mobx";

import { Well } from "models/project/fact/well/well";
import { SimulationInput } from "services/back/infrastructure/calculate";
import {
  deletePipeSystem,
  getOisPipeSystemOrMines,
  getPipeBoundaryCondition,
  getPipeSystem,
  savePipeSystem,
  updatePipeBoundaryCondition,
} from "services/back/infrastructure/infrastructure";
import { PipeBoundaryCondition } from "services/back/infrastructure/types";
import { riseMetrics } from "services/finance/utils";
import { convertPressure } from "utils/convertePressure";
import { getRandomUid } from "utils/random";

import { Fact } from "../fact";
import { Forecast } from "../forecast/forecast";
import { sumUp } from "../production/aggregateFunctions";
import { ProductionDatum } from "../production/production";

import { Aggregation } from "./aggregation";
import { CalculateStore } from "./calculateStore";
import { InfrastructureCatalog } from "./infrastructureCatalog";
import { InfrastructureValidator } from "./infrastructureValidator";
import { Nodes, NodeType } from "./nodes";
import { Pipes } from "./pipes";
import { formatElementsDisabled } from "./utils";

const { atmToBar } = convertPressure;

type BoundaryCondition = {
  oilRate: number;
  waterRate: number;
};
type MinesBoundaryConditions = {
  [mineId: number]: Record<"prod" | "inj", BoundaryCondition>;
};

class Infrastructure {
  public nodes = new Nodes(this);
  public pipes = new Pipes(this);
  public infrastructureValidator = new InfrastructureValidator(this);
  public currentDate: Dayjs | undefined = dayjs();
  public catalog = new InfrastructureCatalog();
  public calculateStore = new CalculateStore(this);
  public drainSourceBoundaryConditions: PipeBoundaryCondition[] = [];
  public isUpdated: boolean = false;
  public aggregation = new Aggregation(this.forecast?.range!);

  constructor(public fact: Fact, public forecast: Forecast | null) {
    makeObservable(this, {
      isLoading: computed,
      wells: computed,
      linkMineNode: computed,
      range: computed,
      indexPriceEcyMap: computed,
      currentDate: observable.ref,
      drainSourceBoundaryConditions: observable,
      isUpdated: observable,
      markUpdated: action,
      setCurrentDate: action,
      fillInfrastructureOisPipeOrMines: action,
      saveDrainSources: action,
    });

    this.currentDate = fact.forecastDateRange.from;
    this.init();

    this.aggregation.fromRaw([]);

    reaction(
      () => this.calculateStore.economicData,
      () => this.aggregation.fromRaw(riseMetrics(this.calculateStore.economicData?.schema ?? []))
    );

    reaction(
      () => this.wells.length,
      () => this.init()
    );
  }

  public get indexPriceEcyMap() {
    const ecyStore = (this.forecast ?? this.fact)?.ecyStore;
    const ecyMap = new Map<number, (number | null)[]>();
    for (const ecy of [...ecyStore.listSystemECY, ...ecyStore.listECY]) {
      const filteredMetrics = ecy.metrics?.find((el) => el.title === "Индексы цен");
      const values =
        filteredMetrics?.children?.find((el) => el.title === "Индекс цен в капитальном строительстве")?.values ?? [];
      ecyMap.set(ecy.id, values);
    }
    return ecyMap;
  }

  private async init() {
    const id = this.forecast ? this.forecast.id : this.fact.id;
    let pipeSystem = await getPipeSystem(id);
    if (!pipeSystem) {
      pipeSystem = await getOisPipeSystemOrMines(id, true);
    }
    this.drainSourceBoundaryConditions = await getPipeBoundaryCondition(id);
    if (pipeSystem == null) return;
    this.nodes.init(Nodes.nodesFromPipeSystem(pipeSystem, this));
    this.pipes.init(Pipes.pipesFromPipeSystem(pipeSystem, this));
  }

  public get isLoading(): boolean {
    return this.nodes.isLoading || this.pipes.isLoading;
  }

  public get range() {
    if (!this.forecast) {
      return { from: this.fact.factRange.from, to: this.fact.factRange.to };
    }
    return { from: this.forecast.range.from, to: this.forecast.range.to };
  }

  public markUpdated = (v: boolean = true) => {
    this.isUpdated = v;
  };

  public findDrainSourceBoundaryCondition(uuid: string, date?: Dayjs | null): PipeBoundaryCondition | null {
    const currentDate = date ? date : this.currentDate;
    const boundaryConditions = this.drainSourceBoundaryConditions;
    const matchingConditions = boundaryConditions.filter((item) => item.nodeUuid === uuid);

    const validConditions = matchingConditions.filter((item) => {
      const itemDate = dayjs(item.date);
      return itemDate.isBefore(currentDate, "day") || itemDate.isSame(currentDate, "day");
    });

    if (validConditions.length === 0) {
      return null;
    }
    const closestCondition = validConditions.reduce((prev, current) => {
      return dayjs(prev.date).isAfter(current.date) ? prev : current;
    });
    return {
      ...closestCondition,
      boundaryCondition: {
        ...closestCondition.boundaryCondition,
        pressure: atmToBar(closestCondition.boundaryCondition?.pressure) ?? 0,
      },
    };
  }

  get wells(): Well[] {
    if (!this.fact.wells.wells.length) {
      return [];
    }
    if (this.forecast === null) {
      return this.fact!.wells.wells;
    }
    return [...this.fact.wells.wells!, ...this.forecast.wells.wells!];
  }

  get linkMineNode() {
    const mines = this.nodes.data?.filter((el) => el.type === "mine") ?? [];
    const pipes = this.pipes.data ?? [];
    const result: (NodeType & { regime: "prod" | "inj" })[] = [];

    mines.forEach((mine) => {
      const connectedPipes = pipes.filter((pipe) => pipe.from === mine.uuid || pipe.to === mine.uuid);
      const regimes = new Set(connectedPipes.map((pipe) => pipe.segmentType));
      if (regimes.size === 0) {
        result.push({ ...mine, regime: "prod" });
      } else {
        regimes.forEach((regime) => {
          if (!result.some((m) => m.uuid === mine.uuid && m.regime === regime)) {
            result.push({ ...mine, regime });
          }
        });
      }
    });

    return result;
  }

  setCurrentDate = (date: Dayjs | null) => {
    if (date === null) {
      console.error("attempt to set `null` date");
      return;
    }
    this.currentDate = date;
  };

  private async minesBoundaryConditions(dateProps?: Dayjs): Promise<MinesBoundaryConditions> {
    const date = dateProps ? dateProps : this.currentDate!;
    const [year, month] = [date.year(), date.month() + 1];
    const productionSource = (this.forecast ?? this.fact!).production;
    await when(() => productionSource.isLoading === false);
    await when(() => this.fact?.producingObjects.isLoading === false);
    const prodByMines = new Map<number, ProductionDatum[]>();
    const waterInjTonnsByMines = new Map<number, number>();
    this.wells.forEach((well) => {
      const wellProd = productionSource.wellData(well.id)?.getForMonth(year, month);
      if (!wellProd) {
        return;
      }
      prodByMines.get(well.mineId)?.push(wellProd) ?? prodByMines.set(well.mineId, [wellProd]);
      const accWaterInj = waterInjTonnsByMines.get(well.mineId) ?? 0;
      waterInjTonnsByMines.set(
        well.mineId,
        accWaterInj + (wellProd.water_inj ?? 0) * (well.producingObject?.data.densityWater ?? 1000)
      );
    });
    const result: MinesBoundaryConditions = {};
    const daysInMonth = date.daysInMonth();
    prodByMines.forEach((prods, mineId) => {
      const { oil_prod, water_prod } = sumUp(prods);
      const water_inj_t = waterInjTonnsByMines.get(mineId);
      const prod = {
        oilRate: ((oil_prod ?? 0) * 1000) / daysInMonth,
        waterRate: ((water_prod ?? 0) * 1000) / daysInMonth,
      };
      const inj = { oilRate: 0, waterRate: ((water_inj_t ?? 0) * 1000) / daysInMonth };
      result[mineId] = { prod, inj };
    });
    return result;
  }

  forSolverJSON(isSave?: boolean, date?: Dayjs): Promise<SimulationInput> {
    const generateSimulationData = (minesBoundaryConditions?: MinesBoundaryConditions) => {
      const filteredNodes = Infrastructure.filterDisabled(this.nodes.data!, !!minesBoundaryConditions, date);
      const filteredSegments = Infrastructure.filterDisabled(this.pipes.data!, !!minesBoundaryConditions, date);
      const filteredLinkMineNode = Infrastructure.filterDisabled(this.linkMineNode!, !!minesBoundaryConditions, date);

      const boundaryCondition = (regime: "prod" | "inj", mineId?: number) => {
        if (!minesBoundaryConditions || !mineId || !minesBoundaryConditions[mineId])
          return { oilRate: 0, waterRate: 0 };
        return minesBoundaryConditions[mineId!][regime] ?? { oilRate: 0, waterRate: 0 };
      };
      return {
        nodes: filteredNodes.map(({ uuid, title, x, y, altitude }) => ({
          x,
          y,
          z: altitude,
          uuid,
          title,
        })),
        segments: filteredSegments.map((segment) => ({
          startedAt: segment.startedAt.format("YYYY-MM-DD"),
          finishedAt: segment.finishedAt ? segment.finishedAt.format("YYYY-MM-DD") : null,
          title: segment.title,
          steel: segment.steel ?? "ст.",
          uuid: segment.uuid,
          segmentType: segment.segmentType,
          workPressure: segment.workPressure ?? 4000000,
          diameter: (segment.diameterOuter ?? 0) - (segment.thickness ?? 0) || 50,
          diameterOuter: segment.diameterOuter || 50,
          roughness: segment.roughness || 0.0254,
          thickness: segment.thickness || 10,
          firstNodeUuid: segment.from,
          secondNodeUuid: segment.to,
          limitingPressureGradient: atmToBar(segment.limitingPressureGradient),
          limitingVelocity: segment.limitingVelocity,
          construction: segment.construction,
          reconstruction: segment.reconstruction,
          length: segment.length,
          oisLength: segment.oisLength,
        })),
        linksMineNode: filteredLinkMineNode.map(({ uuid: nodeUuid, mineId, title, regime, isFactual }) => ({
          // пока гарантируется генератором вершин
          mineId: mineId!,
          nodeUuid,
          title,
          uuid: getRandomUid(),
          boundaryCondition: boundaryCondition(regime, mineId),
          regime,
          isFactual,
        })),
        stations: filteredNodes
          .filter(({ type }) => type === "pumping")
          .map((station) => ({
            startedAt: station.startedAt.format("YYYY-MM-DD"),
            title: station.title,
            finishedAt: station.finishedAt ? station.finishedAt.format("YYYY-MM-DD") : null,
            uuid: getRandomUid(),
            stationType: station.stationType || "НС",
            nodeUuid: station.uuid,
            construction: station.construction,
            reconstruction: station.reconstruction,
            power: station.power,
            deltaPressure: atmToBar(station.deltaPressure),
          })),
        drainSources: filteredNodes
          .filter(({ type }) => type === "drain" || type === "source")
          .map(({ uuid: nodeUuid, startedAt, type, title }) => ({
            ...this.findDrainSourceBoundaryCondition(nodeUuid, date),
            startedAt: startedAt.format("YYYY-MM-DD"),
            uuid: getRandomUid(),
            mode: type as "drain" | "source",
            nodeUuid,
            title,
          })),
        externalSources: filteredNodes
          .filter((el) => el.type === "externalSource")
          .map((source) => {
            const boundaryCondition = source.boundaryConditions?.find((el) => dayjs(el.date).isSame(date, "month"));
            return {
              startedAt: source.startedAt.format("YYYY-MM-DD"),
              finishedAt: source.finishedAt ? source.finishedAt.format("YYYY-MM-DD") : null,
              uuid: getRandomUid(),
              title: source.title,
              nodeUuid: source.uuid,
              boundaryCondition: boundaryCondition ?? {
                oilRate: 0,
                waterRate: 0,
                date: dayjs(date).format("YYYY-MM-DD"),
              },
              boundaryConditions: source.boundaryConditions ?? [],
            };
          }),
      };
    };
    if (isSave) {
      return Promise.resolve(generateSimulationData());
    }
    return this.minesBoundaryConditions(date).then(generateSimulationData);
  }

  public fillInfrastructureOisPipeOrMines = (isMinesOnly?: boolean) => {
    if (!this.forecast?.id) return;
    return getOisPipeSystemOrMines(this.forecast?.id, isMinesOnly).then((pipeSystem) => {
      if (pipeSystem === null) return;
      this.nodes.init(Nodes.nodesFromPipeSystem(pipeSystem, this));
      this.pipes.init(Pipes.pipesFromPipeSystem(pipeSystem, this));
      return true;
    });
  };

  public delete = async (): Promise<void> => {
    if (this.forecast === null) {
      return;
    }
    await deletePipeSystem(this.forecast.id);
  };

  public save = async (): Promise<void> => {
    const pipeSystemJSON = await this.forSolverJSON(true);
    await savePipeSystem(this.forecast ? this.forecast.id : this.fact.id, pipeSystemJSON);
    this.markUpdated(false);
  };

  public saveDrainSources = async (item: Partial<NodeType>) => {
    if (this.forecast === null) return;
    if (item && item.pressure === 0) return;
    const node = ("title" in item ? item : this.nodes.data?.find((el) => el.uuid === item.uuid)) as NodeType;
    const drainSources = this.drainSourceBoundaryConditions;
    if (item) {
      const newItem = {
        nodeUuid: node.uuid,
        title: node.title,
        mode: node.type as "drain" | "source",
        uuid: getRandomUid(),
        boundaryCondition: { pressure: item.pressure ?? 0 },
        date: dayjs().format("YYYY-MM-DD"),
      };
      drainSources.push(newItem);
      this.drainSourceBoundaryConditions = drainSources;
    }
    this.markUpdated();
    await updatePipeBoundaryCondition(this.forecast.id, drainSources);
  };

  public static filterDisabled = <T extends { startedAt: Dayjs; finishedAt?: Dayjs | null }>(
    array: T[],
    isFilter: boolean,
    date?: Dayjs
  ): T[] => {
    if (!isFilter) return array;
    return formatElementsDisabled(array, date).filter(({ isDisabledDate }) => !isDisabledDate);
  };
}

export { Infrastructure };
