import type { FC } from "react";

import { Icon } from "elements/icon/icon";
import { SharedIconProps } from "elements/icons/sharedIconProps";

const ASSET: FC<{ modified: boolean }> = ({ modified }) => (
  <g style={{ fill: "none" }}>
    <path d="M23 9L9 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23 16L13 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23 23L13 23" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="9" cy="16" r="1" fill="currentColor" stroke="currentColor" />
    <circle cx="9" cy="23" r="1" fill="currentColor" stroke="currentColor" />
    {modified && <circle cx="24" cy="8" r="3" fill="#DB4432" stroke="white" strokeWidth="2" />}
  </g>
);

const GroupRows: FC<SharedIconProps & { modified?: boolean }> = ({ modified = false, ...props }) => (
  <Icon {...props} viewBox="4 4 24 24" content={<ASSET modified={modified} />} />
);

export { GroupRows };
