import { FC, useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { LoggerStore } from "models/logger";
import { useProject } from "models/project/project";
import { useProjects } from "models/project/projects";

const useLogsColumns = (projectColumn = false): Column[] => {
  return useMemo(
    (): Column[] => [
      {
        title: "Действие",
        dataKey: "title",
        type: "string",
        width: { min: 10, max: 1000, competitiveness: 10 },
      },
      {
        title: "Пользователь",
        dataKey: "user",
        type: "string",
        width: { min: 10, max: 1000, competitiveness: 10 },
      },
      {
        title: "Дата",
        dataKey: "date",
        type: "string",
        width: { min: 10, max: 120, competitiveness: 10 },
      },
      {
        title: "Время",
        dataKey: "time",
        type: "string",
        width: { min: 10, max: 100, competitiveness: 10 },
      },
      //@ts-ignore
      ...(projectColumn
        ? [
            {
              title: "Проект",
              dataKey: "projectName",
              type: "string",
              width: { min: 10, max: 1000, competitiveness: 10 },
            },
          ]
        : []),
      {
        title: "Сценарий",
        dataKey: "scenarioTitle",
        //@ts-ignore
        type: "string",
        width: { min: 10, max: 1000, competitiveness: 10 },
      },
    ],
    [projectColumn]
  );
};

const Logs: FC = observer(() => {
  const columns = useLogsColumns();
  const { logger } = global;
  const project = useProject()!;
  const projects = useProjects()!;

  const store = useMemo(() => new LoggerStore(logger, projects, project.id), [logger, project.id, projects]);

  return logger.isLoadingLog ? (
    <FullScreenLoader />
  ) : (
    <SimpleTableContext exportFileName="Логирование" columns={columns} data={store} hideExpandColumn showIndexColumn={false}>
      <Widget />
    </SimpleTableContext>
  );
});

export { Logs, useLogsColumns };
