import { FC, useState } from "react";
import { Expand, TableItem } from "@okopok/components/Table";
import { Table } from "@okopok/components/Table/Table";
import { Button, Dropdown, Menu, MenuProps } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Icon } from "elements/icon/icon";
import { Plus } from "elements/icons/plus";
import { Loader } from "elements/loader";
import { SimpleTableContext } from "features/tableDebug/simpleTable";

import { useInfrastructure } from "../useInfrastructure";

import { useFacilitiesTableModel } from "./facilitiesManager/useFacilitiesTableModel";
import { ReactComponent as UploadIcon } from "./uploadIcon.svg";

import cn from "./infrastructureFacilities.module.less";

type MenuItemKey = "prod" | "inj" | "mines" | "stations";
type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    label: "Нефтепровод",
    key: "prod",
  },
  {
    label: "Водовод",
    key: "inj",
  },
  {
    label: "Кустовые площадки",
    key: "mines",
  },
  {
    label: "НС/УПН/УПСВ/УКПГ",
    key: "stations",
  },
];

const buttonItems = [
  {
    key: "НС",
    label: "НС",
  },
  {
    key: "УПН",
    label: "УПН",
  },
  {
    key: "УПСВ",
    label: "УПСВ",
  },
  {
    key: "УКПГ",
    label: "УКПГ",
  },
];

const InfrastructureFacilities: FC = observer(() => {
  const [current, setCurrent] = useState<MenuItemKey>("prod");

  const infrastructure = useInfrastructure();
  const { store, columns } = useFacilitiesTableModel(current);

  const isStationsTable = current === "stations";

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key as MenuItemKey);
  };

  const getRowClassName = (indexPath: { length: number }, expand?: Expand) => {
    if (expand === undefined) return cn.tableRowPlain;
    return indexPath.length === 1 ? `${cn.tableRowPrimary}` : cn.tableRowPlain;
  };

  if (infrastructure.catalog.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={cn.navMenu}>
        <Menu onClick={onClick} selectedKeys={[current]} items={items} mode="horizontal" />
        {current === "stations" ? (
          <Dropdown menu={{ items: buttonItems, onClick: (e) => store.push(e.key) }}>
            <Button className={cn.importBtn} icon={<Plus width="16" height="16" />}>
              Добавить
            </Button>
          </Dropdown>
        ) : (
          <Button onClick={() => store.push()} className={cn.importBtn} icon={<Plus width="16" height="16" />}>
            Добавить
          </Button>
        )}
      </div>
      <div className={cn.tableWrap}>
        <SimpleTableContext
          data={store}
          columns={columns}
          hideExpandColumn={current !== "stations"}
          exportFileName="Каталог"
          tableOptions={{
            isRowEditable: (tableItem: TableItem<any>) => !tableItem.value.isBlocked,
            onRow: (tableItem: TableItem<any>) => ({
              className: classNames({
                [cn.disabled]: tableItem.value.isBlocked,
                [getRowClassName(tableItem.indexPath, tableItem.expand)]: isStationsTable,
              }),
            }),
          }}
        >
          <PageFrameTitlePortal model={store} onSave={store.save} permissionSection="infrastructure">
            <Button className={cn.importBtn} disabled icon={<Icon width="16" height="16" content={<UploadIcon />} viewBox="0 0 16 16" />}>
              Импортировать
            </Button>
          </PageFrameTitlePortal>
          <div style={{ height: "100%" }}>
            <Table headerClassName={cn.tableHeader} className={cn.table} />
          </div>
        </SimpleTableContext>
      </div>
    </>
  );
});

export { InfrastructureFacilities };
