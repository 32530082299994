import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";

import {
  calculateInjection,
  getInputParams,
  type InjectionPredictionSettings,
  saveInputParams,
} from "services/back/injectionPrediction/calculateInjection";
import { saveInjection } from "services/back/injectionPrediction/saveInjection";
import { calculateVoronoi, type VoronoiGrid } from "services/back/injectionPrediction/voronoi";

import { Forecast } from "../forecast";

class InjectionResults {
  public currentDate: Dayjs | null = dayjs();
  public currentProducingObjectId: number | null = null;
  private currentVoronoiGrid: VoronoiGrid | null | undefined = null;

  public calculatedResultsSettings: InjectionPredictionSettings | null = null;
  public isCalculating: boolean = false;

  private readonly voronoiSaved = observable.map<string, VoronoiGrid>();

  constructor(private forecast: Forecast) {
    makeObservable<InjectionResults, "currentVoronoiGrid" | "resetVoronoi">(this, {
      currentDate: observable.ref,
      currentProducingObjectId: observable,
      currentVoronoiGrid: observable,
      calculatedResultsSettings: observable.ref,
      isCalculating: observable,

      voronoi: computed,

      calculate: action,
      resetVoronoi: action,
      resetSettings: action,
    });

    reaction(
      () => this.currentDate,
      () => this.resetVoronoi()
    );

    reaction(
      () => this.currentProducingObjectId,
      () => this.resetVoronoi()
    );

    this.currentProducingObjectId = forecast.fact.producingObjects?.first?.id ?? null;
    this.resetVoronoi();

    this.resetSettings();
  }

  public async calculate(settings: InjectionPredictionSettings) {
    if (this.isCalculating) {
      return;
    }
    this.isCalculating = true;
    try {
      const injData = await calculateInjection(
        this.forecast.id,
        this.forecast.compensationCoefficients.inverted,
        settings
      );

      await saveInjection(injData);
      await this.forecast.production.update(injData.map(({ well_id: wellId }) => ({ wellId })));

      const savedParams = await saveInputParams(settings, this.forecast.fact.projectId, this.forecast.id);
      this.resetSettings(savedParams);
    } catch (err) {
      console.error(err);
    } finally {
      this.isCalculating = false;
    }
  }

  public async resetSettings(params?: InjectionPredictionSettings | null) {
    if (params === undefined) {
      try {
        this.calculatedResultsSettings = await getInputParams(this.forecast.fact.projectId, this.forecast.id);
      } catch (err) {
        console.error("Ошибка при загрузке inputParams:", err);
      }
    } else {
      this.calculatedResultsSettings = params;
    }
  }

  public get voronoi(): VoronoiGrid | null | undefined {
    return this.currentVoronoiGrid;
  }

  private async resetVoronoi(): Promise<void> {
    if (this.currentDate === null || this.currentProducingObjectId === null) {
      runInAction(() => {
        this.currentVoronoiGrid = null;
      });
      return;
    }
    runInAction(() => {
      this.currentVoronoiGrid = undefined;
    });
    const month = this.currentDate.month() + 1;
    const year = this.currentDate.year();
    const producingObjectId = this.currentProducingObjectId;
    const key = InjectionResults.voronoiMapKey(month, year, producingObjectId);
    if (this.voronoiSaved.has(key)) {
      runInAction(() => {
        this.currentVoronoiGrid = this.voronoiSaved.get(key)!;
      });
      return;
    }
    try {
      const calculatedVoronoi = await this.calculateVoronoi(month, year, producingObjectId);
      runInAction(() => {
        if (calculatedVoronoi !== undefined) {
          this.voronoiSaved.set(key, calculatedVoronoi);
          this.currentVoronoiGrid = calculatedVoronoi;
        } else {
          this.currentVoronoiGrid = null;
        }
      });
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.currentVoronoiGrid = null;
      });
    }
  }

  private async calculateVoronoi(month: number, year: number, producingObjectId: number) {
    return calculateVoronoi(this.forecast.id, producingObjectId, [month, year]);
  }

  private static voronoiMapKey(month: number, year: number, producingObjectId: number): string {
    return `${month}.${year}-${producingObjectId}`;
  }
}

export { InjectionResults };
