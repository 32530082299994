import { ReactNode, useMemo } from "react";
import { Axis } from "@okopok/axes_context";

import { Domain, joinDomains, valuesDomain } from "utils/boundDomain";

import { BarsDataModel } from "./barDataModel";

const YEAR = 31536000000 / 2;

const useScaleFromBars = (
  bars: BarsDataModel | undefined | null,
  titles?: Map<string, ReactNode>,
  rightAxis?: Boolean,
  yearsOnly?: Set<string>,
  showZero?: boolean,
  paddingLR: number = YEAR,
  paddingT: number = 0,
  paddingB: number = 0
): Axis[] => {
  const result = useMemo(() => {
    if (
      bars === undefined ||
      bars === null ||
      bars.bars.length === 0 ||
      !(bars.bars.find((bar) => bar.y.find((y) => y !== null) !== undefined) !== undefined)
    ) {
      return [
        new Axis(
          "x",
          "bottom",
          { min: 0, max: 1 },
          titles?.get("x") ? `${titles?.get("x")}` : undefined,
          undefined,
          yearsOnly?.has("x")
        ),
      ];
    }
    const axisKey = bars.axisKey;
    let domain: Domain | undefined;
    const preparedBars = bars.preparedBars;
    let xDomain = {
      min: Math.min(...Array.from(preparedBars.keys()).map((key) => +key)),
      max: Math.max(...Array.from(preparedBars.keys()).map((key) => +key)),
    };
    for (let [, barInfos] of bars.preparedBars.entries()) {
      const known = domain;
      domain = joinDomains(
        valuesDomain([
          0,
          bars.inRow
            ? Math.max(...barInfos.map((bar) => (bar.limit !== undefined ? Math.max(bar.value, bar.limit) : bar.value)))
            : barInfos.reduce(
                (prev, current) =>
                  prev +
                  (current.value > 0
                    ? Math.max(current.value, current.limit ?? current.value)
                    : Math.min(current.value, current.limit ?? current.value)),
                0
              ),
        ]),
        known
      );
    }
    xDomain.min = +xDomain.min - paddingLR;
    xDomain.max = +xDomain.max + paddingLR;
    if (!domain) {
      domain = { min: 1, max: 1 };
    }
    if (+domain!.min === +domain!.max) {
      domain!.max = +domain!.min + 1;
    }
    if (+domain.min < 0 && paddingB > 0) {
      domain.min = +domain.min - (Math.abs(+domain.max - +domain.min) / 100) * paddingB;
    }
    if (+domain.max > 0 && paddingT > 0) {
      const result = +domain.max - (Math.abs(+domain.max - +domain.min) / 100) * paddingT;
      domain.max = !showZero ? result : result < 0 ? 15 : result;
    }
    const axes: Axis[] = [
      new Axis(
        "x",
        "bottom",
        xDomain,
        titles?.get("x") ? `${titles?.get("x")}` : undefined,
        undefined,
        yearsOnly?.has("x")
      ),
      new Axis(axisKey, rightAxis ? "right" : "left", domain!, `${titles?.get(axisKey)}`, true),
    ];

    return axes;
  }, [bars, paddingLR, titles, yearsOnly, rightAxis, showZero, paddingT, paddingB]);
  return result;
};

export { useScaleFromBars };
