import { action, computed, makeObservable, observable } from "mobx";

import { getOIZ, type OIZParams, setOIZ } from "services/back/producingObjectsParams";

import { Fact } from "../fact";
import { Forecast } from "../forecast/forecast";

class OIZModel {
  public data?: OIZParams;

  constructor(private fact: Fact, private forecast: Forecast | null = null) {
    makeObservable<OIZModel, "init">(this, {
      data: observable.ref,
      isLoading: computed,
      init: action,
      submit: action,
    });
    this.init();
  }

  public get paramsRange() {
    return this.forecast?.range ?? this.fact.factRange;
  }

  public get isLoading(): boolean {
    return this.data === undefined;
  }

  private async init() {
    const data = await getOIZ(this.fact.projectId, this.forecast?.id);
    if (data === null) {
      await this.submit(this.defaultData());
    } else {
      this.data = data;
    }
  }

  public async submit(params: OIZParams) {
    const savedData = await setOIZ(this.fact.projectId, this.forecast?.id, params);
    if (savedData) {
      this.data = savedData;
    }
  }

  private defaultData(): OIZParams {
    return {
      [OIZModel.METRICS[0]]: this.paramsRange.array.fill(1),
      [OIZModel.METRICS[1]]: this.paramsRange.array.fill(0),
    };
  }

  static METRICS = [
    "Остаточные извлекаемые запасы проектного месторождения на ЛУ",
    "Остаточные извлекаемые запасы второго месторождения на ЛУ",
  ] as const;
}

export { OIZModel };
