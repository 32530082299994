import { FC, useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { LoggerStore } from "models/logger";
import { useProject } from "models/project/project";
import { useProjects } from "models/project/projects";

const logColumns: Column[] = [
  {
    title: "Сценарий",
    dataKey: "scenarioTitle",
    type: "string",
    width: { min: 10, max: 1000, competitiveness: 10 },
  },
  {
    title: "Модуль",
    dataKey: "moduleTitle",
    type: "string",
    width: { min: 10, max: 1000, competitiveness: 10 },
  },
  {
    title: "Действие",
    dataKey: "title",
    type: "string",
    width: { min: 10, max: 1000, competitiveness: 10 },
  },
  {
    title: "Пользователь",
    dataKey: "user",
    type: "string",
    width: { min: 10, max: 1000, competitiveness: 10 },
  },
  {
    title: "Дата",
    dataKey: "date",
    type: "string",
    width: { min: 10, max: 120, competitiveness: 10 },
  },
  {
    title: "Время",
    dataKey: "time",
    type: "string",
    width: { min: 10, max: 100, competitiveness: 10 },
  },
];

const ScenarioLog: FC = observer(() => {
  const { logger } = global;
  const project = useProject()!;
  const projects = useProjects()!;

  const store = useMemo(() => new LoggerStore(logger, projects, project.id, true), [logger, project.id, projects]);

  return logger.isLoadingLog ? (
    <FullScreenLoader />
  ) : (
    <SimpleTableContext exportFileName="Журнал действий" columns={logColumns} data={store} hideExpandColumn showIndexColumn={false}>
      <Widget />
    </SimpleTableContext>
  );
});

export { ScenarioLog };
