import { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo } from "react";
import { RadiusSettingOutlined } from "@ant-design/icons";
import { Button, InputNumber, Popover, Slider, SliderSingleProps, Tooltip } from "antd";
import FormItem from "antd/es/form/FormItem";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { Reset } from "elements/icons/reset";

import { useInfrastructureMapContext } from "./InfrastructureMapManager/InfrastructureMapManager";

import cn from "./InfrastructureMap.module.less";

const HidePipesButton: FC = observer(() => {
  const {
    minMaxRange,
    pipeColorMode,
    calculateStore,
    customMinMaxRange,
    velocityPressureGradientRange,
    setVelocityPressureGradientRange,
    setCustomMinMaxRange,
    resetCustomMinMaxRange,
  } = useInfrastructureMapContext();

  const [minRange, maxRange] = [minMaxRange[0].toFixed(3), minMaxRange[1].toFixed(3)];

  const [min, max] = [Number(minRange), Number(maxRange)];

  const marks: SliderSingleProps["marks"] = {
    [min]: min,
    [max]: max,
  };

  const haveSetting = useMemo(() => {
    const [customMin, customMax] = customMinMaxRange;
    if (customMin !== null || customMax !== null) {
      return true;
    }

    if (velocityPressureGradientRange) {
      const [vMin, vMax] = velocityPressureGradientRange;
      return vMin !== min || vMax !== max;
    }

    return false;
  }, [velocityPressureGradientRange, customMinMaxRange, max, min]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, type: "min" | "max") => {
    let { value } = event.target;

    value = value.replace(/,/g, ".");

    value = value.replace(/[^0-9.]/g, "");

    const dotIndex = value.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = value.slice(0, dotIndex + 1);
      const afterDot = value.slice(dotIndex + 1).replace(/\./g, "");
      value = beforeDot + afterDot;
    }

    setCustomMinMaxRange(Number(value), type);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter") {
      (event.target as HTMLInputElement).blur();
    }
  };

  useEffect(() => {
    setVelocityPressureGradientRange([min, max]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <div className={cn.hidePipesSliderContent}>
      <div>Диапазон отображения заливки</div>
      <div className={cn.hidePipesSliderWrapper}>
        <Slider
          onChange={setVelocityPressureGradientRange}
          className={cn.hidePipesSlider}
          range
          value={velocityPressureGradientRange ?? undefined}
          defaultValue={[min, max]}
          min={min}
          max={max}
          marks={marks}
          step={0.01}
        />
        <Button icon={<Reset />} onClick={() => setVelocityPressureGradientRange([min, max])} />
      </div>
      <div>
        <div>Мин. и макс. значения {pipeColorMode === "velocity" ? "скорости потока, м/с" : "градиента давления, атм/км"}</div>
        <div className={cn.setValuesBlock}>
          <FormItem label="мин.">
            <InputNumber
              onKeyDown={handleKeyDown}
              placeholder="Задать мин. значение"
              value={customMinMaxRange[0]}
              onBlur={(event) => handleChange(event, "min")}
            />
          </FormItem>
          <FormItem label="макс.">
            <InputNumber
              onKeyDown={handleKeyDown}
              placeholder="Задать макс. значение"
              value={customMinMaxRange[1]}
              onBlur={(event) => handleChange(event, "max")}
            />
          </FormItem>
          <Button icon={<Reset />} onClick={resetCustomMinMaxRange} />
        </div>
      </div>
    </div>
  );

  return (
    <Tooltip title="Скрыть трубы, не попадающие в диапазон">
      <Popover trigger="click" content={content}>
        <Button
          className={classNames(cn.resetBtn, haveSetting && cn.haveSetting)}
          icon={<RadiusSettingOutlined />}
          disabled={!calculateStore.hydraulicData}
        />
      </Popover>
    </Tooltip>
  );
});

export { HidePipesButton };
