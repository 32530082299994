import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0043 2C8.82188 2 7.65099 2.2329 6.55853 2.68542C5.46607 3.13793 4.47344 3.80118 3.63731 4.63731C2.80118 5.47344 2.13793 6.46608 1.68542 7.55854C1.2329 8.65099 1 9.82188 1 11.0043C1 11.5566 1.44772 12.0043 2 12.0043H10.0043C10.2696 12.0043 10.5239 11.899 10.7115 11.7115C10.899 11.5239 11.0043 11.2696 11.0043 11.0043L11.0043 3C11.0043 2.44772 10.5566 2 10.0043 2ZM7.3239 4.53317C7.86386 4.30951 8.42846 4.15482 9.00435 4.07175L9.00435 10.0043H3.07175C3.15482 9.42846 3.30951 8.86387 3.53317 8.3239C3.88518 7.47409 4.40111 6.70194 5.05153 6.05153C5.70194 5.40111 6.47409 4.88518 7.3239 4.53317Z"
    />
    <path d="M13.5072 2.71137C12.9986 2.49628 12.4118 2.73427 12.1967 3.24295C11.9816 3.75162 12.2196 4.33836 12.7283 4.55345C13.7821 4.99906 14.7107 5.69602 15.4329 6.5834C16.0174 7.30162 16.4524 8.12688 16.7148 9.01048C16.811 9.00627 16.9077 9.00415 17.0049 9.00415C17.6379 9.00415 18.2514 9.09419 18.8346 9.26297C18.8341 9.26043 18.8336 9.25788 18.8331 9.25534C18.5474 7.81254 17.9125 6.46171 16.9841 5.32095C16.0557 4.18018 14.8619 3.28421 13.5072 2.71137Z" />
    <path d="M8.35192 19.8569C9.16091 20.0076 9.98366 20.0461 10.7973 19.9738C10.4994 19.363 10.2776 18.7028 10.1456 18.0076C9.6687 18.0175 9.19037 17.9787 8.71806 17.8907C7.59327 17.6813 6.5369 17.1995 5.64133 16.4874C4.74575 15.7754 4.03823 14.8548 3.58063 13.8062C3.35974 13.3 2.77032 13.0687 2.26414 13.2896C1.75795 13.5105 1.52667 14.0999 1.74756 14.6061C2.33583 15.9542 3.24537 17.1376 4.39667 18.0529C5.54796 18.9683 6.90596 19.5877 8.35192 19.8569Z" />
    <path d="M12.0049 14.6333L12.4519 15.0633C12.6458 15.2499 12.8 15.4737 12.9053 15.7213C13.0107 15.9689 13.065 16.2352 13.065 16.5043C13.065 16.7734 13.0107 17.0397 12.9053 17.2873C12.8 17.5349 12.6458 17.7587 12.4519 17.9453L12.0049 18.3753C12.2049 18.9493 12.4949 19.4783 12.8579 19.9453L13.4599 19.7673C13.7199 19.6906 13.9929 19.6676 14.2621 19.6997C14.5313 19.7317 14.7912 19.8182 15.0259 19.9538C15.2607 20.0895 15.4654 20.2714 15.6277 20.4886C15.79 20.7058 15.9064 20.9537 15.9699 21.2173L16.1439 21.9323C16.7142 22.0285 17.2966 22.0285 17.8669 21.9323L18.0389 21.2163C18.1025 20.9527 18.219 20.7048 18.3814 20.4876C18.5438 20.2704 18.7486 20.0886 18.9835 19.953C19.2184 19.8175 19.4783 19.7311 19.7476 19.6992C20.0169 19.6673 20.2898 19.6904 20.5499 19.7673L21.1519 19.9453C21.5139 19.4783 21.8039 18.9493 22.0049 18.3753L21.5579 17.9453C21.364 17.7587 21.2098 17.5349 21.1044 17.2873C20.9991 17.0397 20.9448 16.7734 20.9448 16.5043C20.9448 16.2352 20.9991 15.9689 21.1044 15.7213C21.2098 15.4737 21.364 15.2499 21.5579 15.0633L22.0049 14.6333C21.8073 14.0673 21.5192 13.5371 21.1519 13.0633L20.5499 13.2413C20.2898 13.318 20.0169 13.341 19.7477 13.3089C19.4785 13.2768 19.2186 13.1903 18.9838 13.0547C18.7491 12.9191 18.5443 12.7372 18.3821 12.52C18.2198 12.3028 18.1034 12.0549 18.0399 11.7913L17.8659 11.0763C17.2959 10.9801 16.7139 10.9801 16.1439 11.0763L15.9709 11.7923C15.9073 12.0559 15.7908 12.3038 15.6284 12.521C15.466 12.7381 15.2611 12.92 15.0263 13.0555C14.7914 13.1911 14.5314 13.2774 14.2622 13.3094C13.9929 13.3413 13.7199 13.3181 13.4599 13.2413L12.8579 13.0633C12.4906 13.5371 12.2025 14.0673 12.0049 14.6333ZM17.0049 18.0043C16.2049 18.0043 15.5549 17.3323 15.5549 16.5043C15.5549 15.6763 16.2049 15.0043 17.0049 15.0043C17.8049 15.0043 18.4549 15.6763 18.4549 16.5043C18.4549 17.3323 17.8049 18.0043 17.0049 18.0043Z" />
  </g>
);

const TechnologyIcon: FC<SharedIconProps> = (props) => <Icon {...props} viewBox="0 0 24 24" content={ASSET} />;

export { TechnologyIcon };
