import { useMemo } from "react";
import { UserAddOutlined } from "@ant-design/icons";
import { Widget } from "@okopok/components/Table";
import { Input } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { UsersStore } from "models/users";

import cn from "./usersTable.module.less";

const useColumns = (): Column[] => {
  return useMemo(
    (): Column[] => [
      {
        dataKey: "fullName",
        title: "Сотрудник",
        width: { min: 10, max: 1000, competitiveness: 10 },
        type: "string",
      },
      {
        key: "department",
        title: "Отдел",
        width: { min: 10, max: 1000, competitiveness: 10 },
        type: "string",
      },
      {
        key: "organization",
        title: "Организация",
        width: { min: 10, max: 1000, competitiveness: 10 },
        type: "string",
      },
      {
        dataKey: "email",
        title: "Электронный адрес",
        width: { min: 10, max: 1000, competitiveness: 10 },
        type: "string",
      },
      {
        key: "position",
        title: "Должность",
        width: { min: 10, max: 1000, competitiveness: 10 },
        type: "string",
      },
      {
        title: "Функциональная роль",
        width: { min: 270, max: 300, competitiveness: 1 },
        isSticky: true,
        dataKey: "roles",
        type: "tag",
        options: global.roles.selector ?? [],
        editable: true,
        onCell: () => ({ className: cn["role-select"] }),
      },
    ],
    []
  );
};

const UsersTable = observer(() => {
  const columns = useColumns();
  const roles = global.user?.roles;
  const canDelete = roles?.includes("Администратор системы");

  const store = useMemo(() => new UsersStore(canDelete), [canDelete]);

  return (
    <SimpleTableContext
      data={store}
      columns={columns}
      exportFileName="Пользователи системы"
      theme={{
        headerHeight: 39,
        borderColor: "#f0f0f0",
      }}
      tableOptions={{
        onRow: () => ({ className: cn.row }),
      }}
    >
      <PageFrameTitlePortal>
        <Input placeholder="Добавить участника..." suffix={<UserAddOutlined />} onPressEnter={async (e) => store.addUser(e.currentTarget.value)} />
      </PageFrameTitlePortal>
      <Widget />
    </SimpleTableContext>
  );
});

export { UsersTable };
