// eslint-disable-next-line simple-import-sort/imports
import { FC, useEffect, useState } from "react";
import { Button, Form, Input, Tooltip, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { ApproveIcon } from "elements/icons/approve";
import { ReactComponent as ToIcon } from "features/projects/icons/to.svg";
import { ReactComponent as WellIcon } from "features/projects/icons/well.svg";
import { ProjectInfoCard } from "features/projects/projectInfoCard";
import { global } from "models/global";
import { Forecast } from "models/project/fact/forecast/forecast";
import { Project, useProject } from "models/project/project";

import { ReactComponent as Gtms } from "./icons/gtms.svg";
import { StatusCard, StatusComponent, Statuses, StatusSections } from "./statusCard";

import cn from "./scenarioInfo.module.less";
import { ReloadIcon } from "elements/icons/reload";
import { Loader } from "elements/loader";
import { useFact } from "models/project/fact/fact";
import { ADMIN_ROLES_IDS } from "services/back/roles";

const { Text, Title } = Typography;

type ScenarioInfoProps = {
  scenario: Forecast | null;
  onNavigate: (key: string, postfix: string) => void;
};

type FormState = {
  title: string;
  variant: string;
};

const STATUS_SECTIONS: StatusSections[] = ["tech", "economic", "ranking", "infrastructure"];

const GeneralStatus: FC<{ scenario: Forecast; project: Project }> = observer(({ scenario, project }) => {
  const fact = useFact()!;
  const forecasts = fact.forecasts;

  let generalStatus: Statuses = scenario.getScenarioStatus()?.status ?? "inProgress";
  const isCompletedSections = STATUS_SECTIONS.every((section) => scenario.getScenarioStatus(section)?.status === "approved");
  const userRoles = project.participants.getById(global.user?.id ?? 0).roles;
  let adminPermissions = false;

  const approvedForecast = forecasts.find((forecast) => forecast.generalStatus === "approved");

  const tooltip =
    approvedForecast && generalStatus === "onApproval"
      ? `Сценарий ${approvedForecast.title} уже утвержден`
      : !isCompletedSections
      ? "Согласованы не все модули"
      : "";

  const changeStatus = (scenario: Forecast, generalStatus: string) => {
    switch (generalStatus) {
      case "inProgress":
        scenario.setSectionStatus("sendToApproval");
        break;
      case "onApproval":
        scenario.setSectionStatus("approve");
        break;
      case "approved":
        scenario.setSectionStatus("rejectApprove");
        break;
    }
  };

  for (const { id } of userRoles) {
    if (ADMIN_ROLES_IDS.includes(id)) {
      adminPermissions = true;
    }
  }

  if (scenario.getScenarioStatus()?.isLoading) {
    return <Loader />;
  }

  if (adminPermissions) {
    return (
      <div className={cn["admin-button"]}>
        <Tooltip title={tooltip}>
          <Button
            className={cn["approve-button"]}
            type="primary"
            icon={generalStatus === "onApproval" ? <ApproveIcon width="16" height="16" /> : <ReloadIcon width="12" height="12" />}
            onClick={() => changeStatus(scenario, generalStatus)}
            loading={scenario.getScenarioStatus()?.isLoading}
            disabled={(!!approvedForecast && generalStatus === "onApproval") || !isCompletedSections}
          >
            {generalStatus === "inProgress" ? "Отправить на утверждение" : generalStatus === "onApproval" ? "Утвердить" : "Вернуть в работу"}
          </Button>
        </Tooltip>
        {generalStatus === "onApproval" && (
          <Button
            className={cn["approve-button"]}
            type="primary"
            icon={<ReloadIcon width="12" height="12" />}
            onClick={() => scenario.setSectionStatus("rejectOnApproval")}
            loading={scenario.getScenarioStatus()?.isLoading}
          >
            Вернуть в работу
          </Button>
        )}
      </div>
    );
  } else {
    return (
      <div className={cn.status}>
        <StatusComponent status={generalStatus} section="general" />
      </div>
    );
  }
});

const ScenarioInfo: FC<ScenarioInfoProps> = observer(({ scenario, onNavigate }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const wellsForecastCount = scenario?.wells?.wells.length;
  const gtmsForecastCount = scenario?.interventions?.interventions.length;

  useEffect(() => {
    if (scenario) {
      setValue(scenario.title);
      form.setFieldsValue({
        title: scenario.title,
        variant: "",
      });
    }
  }, [scenario, scenario?.title, form]);

  const onFinish = (values: FormState) => {
    if (scenario) {
      scenario.submitTitle(values.title);
    }
  };

  const onChange = (values: FormState) => {
    if (values.title) {
      return setValue(isFocused ? values.title : scenario?.title!);
    }
  };

  const getBody = (project: Project) => {
    if (!scenario) {
      return <Title level={2}>Выберите сценарий</Title>;
    } else {
      const { title } = scenario;
      const key = scenario.id.toString();
      const wellsCount = scenario.wells?.wells.length ?? 0;
      const gtmsCount = scenario.interventions?.interventions.length ?? 0;

      return (
        <>
          <Title level={2} className={cn.title}>
            {title}
          </Title>
          <div className={cn.info}>
            <Button
              icon={<Icon content={<ToIcon />} width="14" height="14" viewBox="0 0 14 12" />}
              className={cn["link-button"]}
              onClick={() => onNavigate(key, "wells")}
            >
              К сценарию
            </Button>
            <GeneralStatus scenario={scenario} project={project} />
          </div>
          <Form form={form} layout="vertical" className={cn.form} onFinish={onFinish} onValuesChange={onChange}>
            <Form.Item name="variant" label="Вариант разработки">
              <Input disabled />
            </Form.Item>
            <Form.Item name="title" label="Переименовать сценарий">
              <Input
                placeholder={value}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className={classNames(!isFocused && cn.blur)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>

          <div className={cn["card-container"]}>
            <ProjectInfoCard
              className={cn.margin}
              title="Скважин НФ"
              icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<WellIcon />} />}
            >
              <Typography.Text className={cn["card-text"]}>{wellsCount ?? wellsForecastCount}</Typography.Text>
            </ProjectInfoCard>
            <ProjectInfoCard title="Инвест. ГТМ" icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<Gtms />} />}>
              <Typography.Text className={cn["card-text"]}>{gtmsCount ?? gtmsForecastCount}</Typography.Text>
            </ProjectInfoCard>
          </div>
          {STATUS_SECTIONS.map((section) => (
            <StatusCard section={section} scenario={scenario} />
          ))}
        </>
      );
    }
  };

  const project = useProject()!;

  return (
    <div className={cn.container}>
      <div className={cn.header}>
        <Text className={cn.title} strong>
          Информация о сценарии
        </Text>
      </div>
      <div className={cn.body}>{getBody(project)}</div>
    </div>
  );
});

export { ScenarioInfo };
