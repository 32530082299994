import { useMemo } from "react";
import classNames from "classnames";

import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { compareValues } from "features/infrastructure/utils";
import { Column } from "features/tableDebug/simpleTable";

import { PipesModel } from "../models/pipes";

import cn from "../infrastructureResultsTable.module.less";

const onCell = (limit: number, value: number, className?: string) => {
  const isWarn = compareValues(limit ?? 0, value ?? 0);
  return {
    className: classNames(className, isWarn && cn.warning),
  };
};

const tooltip = (tableItem: any, type: "velocity" | "pressureGradient") => {
  let text = "Превышен лимит. ";
  if (type === "velocity") {
    text = compareValues(tableItem.limitingVelocity ?? 0, tableItem.velocity ?? 0) ? `Лимит:${tableItem.limitingVelocity}` : "";
  } else {
    text = compareValues(tableItem.limitingPressureGradient ?? 0, tableItem.pressureGradient ?? 0)
      ? `Лимит:${tableItem.limitingPressureGradient}`
      : "";
  }
  return text;
};

const columns: Column[] = [
  {
    title: "Наименование трубопровода",
    width: { min: 200, max: 250, competitiveness: 1 },
    isSticky: true,
    dataKey: "title",
    type: "string",
  },
  {
    title: "Дата",
    width: { min: 100, max: 200, competitiveness: 1 },
    isSticky: true,
    dataKey: "date",
    type: "string",
  },
  {
    title: "Вид трубопровода",
    width: { min: 220, max: 250, competitiveness: 1 },
    dataKey: "type",
    type: "string",
  },
  {
    title: "Протяженность, м",
    width: { min: 180, max: 200, competitiveness: 1 },
    dataKey: "length",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Внешний диаметр, мм",
    width: { min: 140, max: 160, competitiveness: 1 },
    dataKey: "diameterOuter",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Толщина стенки, мм",
    width: { min: 120, max: 140, competitiveness: 1 },
    dataKey: "thickness",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Расход жидкости, м3/сут",
    width: { min: 180, max: 200, competitiveness: 1 },
    dataKey: "fluidRateM3",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Расход нефти, т/сут",
    width: { min: 130, max: 150, competitiveness: 1 },
    dataKey: "oilRateT",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Обводненность, д.ед",
    width: { min: 140, max: 160, competitiveness: 1 },
    dataKey: "wc",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Перепад давления, атм",
    width: { min: 180, max: 200, competitiveness: 1 },
    dataKey: "pressureDelta",
    type: "number",
    unit: "real_6",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Давление в начале, атм",
    width: { min: 180, max: 200, competitiveness: 1 },
    dataKey: "firstPressure",
    type: "number",
    unit: "real_6",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Давление в конце, атм",
    width: { min: 160, max: 180, competitiveness: 1 },
    dataKey: "secondPressure",
    type: "number",
    unit: "real_6",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Градиент давления, атм/км ",
    width: { min: 180, max: 200, competitiveness: 1 },
    dataKey: "pressureGradient",
    type: "number",
    unit: "real_6",
    tooltip: ({ value }: any) => tooltip(value, "pressureGradient"),
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: ({ value }: any) => onCell(value.limitingPressureGradient, value.pressureGradient, classNames(cn.tableCellAlignRight, cn.noPadding)),
  },
  {
    title: "Скорость потока жид-ти, м/сек ",
    width: { min: 200, max: 220, competitiveness: 1 },
    dataKey: "velocity",
    type: "number",
    unit: "real_6",
    tooltip: ({ value }: any) => tooltip(value, "velocity"),
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: ({ value }: any) => onCell(value.limitingVelocity, value.velocity, classNames(cn.tableCellAlignRight, cn.noPadding)),
  },
];

const useResultPipesTableStore = (mode: "prod" | "inj") => {
  const infrastructure = useInfrastructure()!;
  const { isCalculation } = infrastructure.calculateStore;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new PipesModel(infrastructure, mode), [infrastructure, mode, isCalculation]);
  return { columns, store };
};

export { useResultPipesTableStore };
