import { FC } from "react";
import { ValidatorManagerContextProvider } from "routing/outlines/result/resultInformer";
import { PageFrame, type TabItem } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Ranking as RankingFeature } from "features/ranking/ranking";

const TABS: TabItem[] = [
  {
    key: "drilling",
    label: "Эксплуатационное бурение",
    children: <RankingFeature />,
    default: true,
  },
  {
    key: "reconstruction",
    label: "Реконструкция скважин",
    children: <RankingFeature />,
  },
];

const Ranking: FC = () => (
  <ForecastSelector>
    <ValidatorManagerContextProvider>
      <PageFrame title="Ранжирование ГТМ" tabs={TABS} />
    </ValidatorManagerContextProvider>
  </ForecastSelector>
);

export { Ranking };
