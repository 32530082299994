import React, { ReactNode, useMemo, useState } from "react";
import { ChartContext, Lines } from "@okopok/axes_context";
import { Button, Dropdown } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Stick } from "elements/card/card";
import { CardTitlePortal } from "elements/card/cardTitlePortal";
import { useScaleFromLines } from "elements/charts/lines/useScaleFromLines";
import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import { Format } from "elements/format/format";
import { ColumnsSettings } from "elements/icons/columnsSettings";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";
import { SummaryModel } from "models/summary";

import { SettingsChart } from "./settingsChart/settingsChart";
import { ExpensesChartModel } from "./expensesChartModel";

import cn from "./expensesChart.module.less";

const YEAR = 31536000000 / 2;

const ExpensesChart = observer(({ model }: { model: SummaryModel }) => {
  const [open, setOpen] = useState(false);
  const chartModel = useMemo(() => new ExpensesChartModel(model), [model]);

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={cn.layout}>
      <CardTitlePortal>
        <Dropdown
          dropdownRender={() => <SettingsChart onClose={handleClose} onChange={chartModel.setSelectedParams} />}
          open={open}
          onOpenChange={handleOpenChange}
          trigger={["click"]}
        >
          <Button type="text" icon={<ColumnsSettings width="20" height="20" />} />
        </Dropdown>
      </CardTitlePortal>
      <div className={cn.wrap}>
        <div className={classNames(cn.chartStick, cn.inLine)}>
          {chartModel.lines.map((line) => (
            <Stick title={line.title}>
              <span className={cn.big}>
                <Format>{+line.y.reduce((prev, curr) => +prev + +curr, 0)}</Format>
              </span>
            </Stick>
          ))}
        </div>
        <ChartContext
          showGridByDefault={{ x: false, y: "other" }}
          axes={useScaleFromLines(
            chartModel.lines,
            YEAR,
            new Map<string, ReactNode>(
              Object.entries({
                other: `млн ₽`,
              })
            ),
            new Set(["other"]),
            undefined
          )}
          className={cn.chart}
        >
          <TooltipContext.Provider value={chartModel.tooltipManager}>
            <Lines data={chartModel.lines} />
            <PointerLine />
          </TooltipContext.Provider>
        </ChartContext>
        <TooltipDiv manager={chartModel.tooltipManager} />
      </div>
    </div>
  );
});

export { ExpensesChart };
