import { FC, MouseEvent, useCallback } from "react";
import { useMapContext } from "@okopok/axes_context";
import classNames from "classnames";
import { observer } from "mobx-react";

import { useProject } from "models/project/project";
import { useStatedRef } from "utils/useInteractiveRef";

import { InfrastructureMapManager, NodeType, PipeType, useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";
import { Label } from "../label";
import { getZoomParameters, scaleMapForStrokeWidthDasharray as scaleMap } from "../utils";

import { EmptyLine } from "./emptyLine";
import { GradientLine } from "./gradientLine";

import cn from "./pipes.module.less";

const shouldDisablePipe = (pipe: PipeProps["pipe"], manager: InfrastructureMapManager, type: "water" | "oil") => {
  if (pipe.isDisabledDate || pipe.isDisabled || manager.pipeMode !== type || manager.isDrawing) return true;
  const [min, max] = manager.velocityPressureGradientRange ?? [0, 0];
  const pressureGradient = Number(pipe.pressureGradient.toFixed(3));
  const velocity = Number(pipe.velocity.toFixed(3));

  if (manager.pipeColorMode === "velocity") {
    return velocity < min || velocity > max;
  }
  return pressureGradient < min || pressureGradient > max;
};

const getValue = (manager: InfrastructureMapManager, isCalculated: boolean, pipe: PipeProps["pipe"]): { v1: number; v2: number } | {} => {
  const [min, max] = manager.customMinMaxRange;
  const clampValue = (value: number, min: number, max: number) => {
    return { v1: value > max ? max : value, v2: value < min ? min : value };
  };
  if (manager.pipeColorMode === "velocity" && isCalculated) {
    if (min !== null && max !== null) {
      return clampValue(pipe.velocity, min, max);
    } else {
      return { v1: Math.abs(pipe.velocity), v2: Math.abs(pipe.velocity) };
    }
  }
  if (manager.pipeColorMode === "pressure") {
    if (min !== null && max !== null) {
      return clampValue(pipe.pressureGradient, min, max);
    } else {
      return { v1: pipe.pressureGradient, v2: pipe.pressureGradient };
    }
  }
  return {};
};

type PipeProps = {
  pipe: { velocity: number; from: NodeType; to: NodeType; isEmpty: boolean; pressureGradient: number } & Omit<PipeType, "from" | "to">;
  type: "water" | "oil";
};

const Pipe: FC<PipeProps> = observer(({ pipe, type }) => {
  const manager = useInfrastructureMapContext();
  const { zoom, scale } = useMapContext();
  const { zoomScaleFactor, zoomScale } = getZoomParameters(zoom);
  const project = useProject();

  const disabled = shouldDisablePipe(pipe, manager, type);
  const isCalculated = !!manager.calculateStore.hydraulicData?.[type === "oil" ? "prod" : "inj"]?.segments?.length;

  const isFactual = project?.actualStateDate.isAfter(pipe.startedAt);
  const dashArray = !isFactual ? scaleMap[zoom as keyof typeof scaleMap] ?? 5 : undefined;

  const handlePipesSelected = (event: MouseEvent, id: string) => {
    event.stopPropagation();
    manager.selection.setSelectedPipeIds(id, !!(event.ctrlKey || event.metaKey || event.shiftKey));
  };

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      manager.tooltip.setActiveObject({ uuid: pipe.uuid, type: "pipe" });
    },
    [manager, pipe.uuid]
  );

  const angleRef = useStatedRef<number>(0);

  const value = getValue(manager, isCalculated, pipe);
  const isReverse = "v1" in value && manager.pipeColorMode === "pressure" ? pipe.to.pressure > pipe.from.pressure : pipe.velocity < 0;
  const noMovement =
    (manager.pipeColorMode === "pressure" && pipe.to.pressure === pipe.from.pressure) ||
    (manager.pipeColorMode === "velocity" && pipe.velocity === 0);

  return (
    <g
      className={classNames(disabled && cn.pipeDisabled)}
      onClick={(event) => (manager.isSelection ? handlePipesSelected(event, pipe.uuid) : undefined)}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => manager.tooltip.setActiveObject(null)}
    >
      {pipe.isEmpty && (
        <EmptyLine
          dashArray={dashArray}
          scale={scale}
          from={pipe.from}
          to={pipe.to}
          strokeWidth={zoomScaleFactor}
          className={classNames({
            [cn.pipeWater]: !pipe.isSelected && !isCalculated && type === "water",
            [cn.pipeOil]: !pipe.isSelected && !isCalculated && type === "oil",
            [cn.pipeSelected]: pipe.isSelected,
          })}
        >
          {zoom > 1 && (
            <g transform={`scale(${zoomScale}), translate(20, 0), rotate(${-angleRef.current})`}>
              {manager.showMapPipeText && <Label>{pipe.title}</Label>}
            </g>
          )}
        </EmptyLine>
      )}
      {!pipe.isEmpty && (
        <GradientLine
          from={pipe.from}
          to={pipe.to}
          {...value}
          strokeWidth={zoomScaleFactor}
          strokeDasharray={!isFactual ? scaleMap[zoom as keyof typeof scaleMap] : undefined}
          className={classNames({
            [cn.pipeWater]: !pipe.isSelected && !isCalculated && type === "water",
            [cn.pipeOil]: !pipe.isSelected && !isCalculated && type === "oil",
            [cn.pipeSelected]: pipe.isSelected,
          })}
          childrenPos={0.5}
          angleRef={angleRef}
        >
          <g transform={`scale(${zoomScale})`}>
            {isCalculated &&
              (noMovement ? (
                <g transform="translate(-8,-8)">
                  <rect x="3.33" y="3.33" className={cn.squareIcon} />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.33 3.33V12.67H12.67V3.33H3.33ZM3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H3Z"
                    fill="white"
                  />
                </g>
              ) : (
                <path
                  d="M7.11289 14.4964L1.31306 1.56097L15.6238 5.98551L10.2511 9.27651L7.11289 14.4964Z"
                  className={cn.arrowIcon}
                  transform={`rotate(${isReverse ? 180 : 0}), translate(10,0), rotate(138)`}
                />
              ))}

            {zoom > 1 && <g transform={`translate(20, 0), rotate(${-angleRef.current})`}>{manager.showMapPipeText && <Label>{pipe.title}</Label>}</g>}
          </g>
        </GradientLine>
      )}
    </g>
  );
});

export { Pipe };
