import { FC, useEffect, useState } from "react";
import { DownloadOutlined, HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button } from "antd";
import dayjs from "dayjs";
import { objectToCamel } from "ts-case-convert";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";

import { Format } from "elements/format/format";
import { useModal } from "elements/modal/modal";
import { CommitInfo, getVersionInfo, Version } from "services/versionInfo";

import frontendVersion from "./frontendVersion.json";

import cn from "./aboutModal.module.less";

const FRONTEND_VERSION = objectToCamel(frontendVersion);

type FrontVersion = ObjectToCamel<typeof frontendVersion>;

const handmadeCheck = (v: FrontVersion): FrontVersion => {
  // если это заглушка
  if (v.commitSha === "92d4817a0a36826a5813c0f0f08ed01dc74fc11a") {
    return {
      commitDate: new Date().toISOString(),
      commitSha: "develop",
      revision: "handmade",
    };
  }
  return v;
};

const ThreadInfo: FC<{ info: CommitInfo }> = ({ info }) => (
  <ul>
    <li>
      <Format>{dayjs(info.commitDate).format("YYYY-MM-DD HH:mm:ss")}</Format>
    </li>
    <li>
      <Format>{info.commitSha}</Format>
    </li>
    <li>
      <Format>{info.revision}</Format>
    </li>
  </ul>
);

const ModalContent = () => {
  const [versionInfo, setVersionInfo] = useState<Version>();
  const [currentPage, setCurrentPage] = useState("about");

  useEffect(() => {
    getVersionInfo().then(
      (result) => {
        result.software.front = handmadeCheck(FRONTEND_VERSION);
        setVersionInfo(result);
      },
      () => console.error("Не получилось получить версию")
    );
  }, []);

  const breadcrumbs = [
    {
      href: "#",
      title: <HomeOutlined key="about" onClick={() => handleBreadcrumbClick("about")} />,
    },
    ...(currentPage === "videos"
      ? [
          {
            href: "#",
            title: (
              <span key="video" onClick={() => handleBreadcrumbClick("video")}>
                Видеоролики
              </span>
            ),
          },
        ]
      : []),
    ...(currentPage === "info"
      ? [
          {
            href: "#",
            title: <span key="info">Сведения о версии ПО</span>,
          },
        ]
      : []),
    ...(currentPage === "specs"
      ? [
          {
            href: "#",
            title: <span key="specs">Спецификации</span>,
          },
        ]
      : []),
  ];

  const goToVideos = () => {
    setCurrentPage("videos");
  };

  const goToInfo = () => {
    setCurrentPage("info");
  };

  const goToSpecs = () => {
    setCurrentPage("specs");
  };

  const handleBreadcrumbClick = (key: string) => {
    switch (key) {
      case "video":
        goToVideos();
        break;
      case "info":
        goToInfo();
        break;
      case "specs":
        goToSpecs();
        break;
      case "about":
        setCurrentPage("about");
        break;
      default:
        setCurrentPage("about");
    }
  };

  const videoList = [
    { id: 1, title: "Урок 1. Создание и настройка проекта", url: "/static/Видео-уроки/Урок 1. Создание и настройка проекта.mp4" },
    {
      id: 2,
      title: "Урок 2. Прогноз добычи программы ГТМ на полное развитие",
      url: "/static/Видео-уроки/Урок 2. Прогноз добычи программы ГТМ на полное развитие.mp4",
    },
    { id: 3, title: "Урок 3. Расчет дебита жидкости", url: "/static/Видео-уроки/Урок 3. Расчет дебита жидкости.mp4" },
    { id: 4, title: "Урок 4. Расчет дебита нефти", url: "/static/Видео-уроки/Урок 4. Расчет дебита нефти.mp4" },
    {
      id: 5,
      title: "Урок 5. Критерии остановки и корректировка прогноза",
      url: "/static/Видео-уроки/Урок 5. Критерии остановки и корректировка прогноза.mp4",
    },
    {
      id: 6,
      title: "Урок 6. Прогноз закачки по скважинам базового и нового фонда",
      url: "/static/Видео-уроки/Урок 6. Прогноз закачки по скважинам базового и нового фонда.mp4",
    },
    { id: 7, title: "Урок 7. Оценка эффекта от операционных ГТМ", url: "/static/Видео-уроки/Урок 7. Оценка эффекта от операционных ГТМ.mp4" },
    {
      id: 8,
      title: "Урок 8. Построение прогноза методом материального баланса",
      url: "/static/Видео-уроки/Урок 8. Построение прогноза методом материального баланса.mp4",
    },
    {
      id: 9,
      title: "Урок 9. Расчет экономики для двух ЛУ с налоговыми режимами ДНС и НДД",
      url: "/static/Видео-уроки/Урок 9. Расчет экономики для двух ЛУ с налоговыми режимами ДНС и НДД.mp4",
    },
    {
      id: 10,
      title: "Урок 10. Экономическая оптимизация программы инвестиционных мероприятий",
      url: "/static/Видео-уроки/Урок 10. Экономическая оптимизация программы инвестиционных мероприятий.mp4",
    },
    { id: 11, title: "Урок 11. Базовый ресурсно-календарного плана", url: "/static/Видео-уроки/Урок 11. Базовый ресурсно-календарного плана.mp4" },
    {
      id: 12,
      title: "Урок 12. Оптимизация ресурсно-календарного плана",
      url: "/static/Видео-уроки/Урок 12. Оптимизация ресурсно-календарного плана.mp4",
    },
    {
      id: 13,
      title: "Урок 13. Построение и расчет параметров наземной инфраструктуры",
      url: "/static/Видео-уроки/Урок 13. Построение и расчет параметров наземной инфраструктуры.mp4",
    },
    { id: 14, title: "Урок 14. Отчеты", url: "/static/Видео-уроки/Урок 14. Отчеты.mp4" },
    {
      id: 15,
      title: "Урок 15. Сравнение сценариев и факторный анализ",
      url: "/static/Видео-уроки/Урок 15. Сравнение сценариев и факторный анализ.mp4",
    },
  ];

  const specsList = [
    {
      id: 1,
      title: "Спецификация корректировка нефизичных прогнозов добычи",
      url: "/static/Спецификация корректировка нефизичных прогнозов добычи.pdf",
    },
    { id: 2, title: "Спецификация метода материального баланса", url: "/static/Спецификация метода материального баланса.pdf" },
    { id: 3, title: "Спецификация методики расчета нефти и жидкости", url: "/static/Спецификация методики расчета нефти и жидкости.pdf" },
    { id: 4, title: "Спецификация модуль нагнетания", url: "/static/Спецификация модуль нагнетания.pdf" },
    { id: 5, title: "Спецификация операционные ГТМ", url: "/static/Спецификация операционные ГТМ.pdf" },
    { id: 6, title: "Спецификация прогноз закачки", url: "/static/Спецификация прогноз закачки.pdf" },
    { id: 7, title: "Спецификация Ресурсно календарное планирование", url: "/static/Спецификация Ресурсно календарное планирование.pdf" },
    { id: 8, title: "Спецификация по экономическим расчетам", url: "/static/economy.pdf" },
  ];

  return (
    <div className={cn.wrap}>
      <Breadcrumb className={cn.breadcrumbs} items={breadcrumbs} />
      {versionInfo && currentPage === "info" && (
        <div className={cn.blockInfo}>
          <div>
            <span>Дата сборки: </span>
            <Format>{dayjs(versionInfo?.buildDate).format("YYYY-MM-DD HH:mm:ss")}</Format>
          </div>
          {(
            Object.entries({
              back: "Бэкенд",
              deploy: "Система сборки",
              front: "Фронтенд",
            }) as [["back" | "front" | "deploy", string]]
          ).map(([key, title]) => (
            <div key={key}>
              <span>{title}: </span>
              <ThreadInfo info={versionInfo.software[key]} />
            </div>
          ))}
        </div>
      )}
      {currentPage === "about" && (
        <div className={cn.btnWrapper}>
          {versionInfo && (
            <>
              <Button onClick={goToInfo} type="primary" className={cn.btn}>
                {" "}
                Сведения о версии ПО
              </Button>
              <br />
            </>
          )}
          <Button onClick={goToVideos} type="primary" className={cn.btn}>
            {" "}
            Обучающие видеоролики
          </Button>
          <br />
          <Button href="/static/guide.pdf" type="primary" icon={<DownloadOutlined />} className={cn.btn}>
            {" "}
            Инструкция пользователя
          </Button>
          <br />
          <Button href="/static/ReleaseNotes.pdf" type="primary" icon={<DownloadOutlined />} className={cn.btn}>
            {" "}
            История изменений
          </Button>
          <br />
          <Button type="primary" className={cn.btn} onClick={goToSpecs}>
            {" "}
            Спецификации расчетных модулей
          </Button>
        </div>
      )}
      {currentPage === "videos" && (
        <div>
          <ul className={cn.list}>
            {videoList.map((video) => (
              <li key={video.id}>
                <a href={video.url} target="_blank" rel="noopener noreferrer">
                  {video.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      {currentPage === "specs" && (
        <div>
          <ul className={cn.list}>
            {specsList.map((spec) => (
              <li key={spec.id}>
                <a href={spec.url} target="_blank" rel="noopener noreferrer">
                  {spec.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const useAboutModal = () => {
  const showModal = useModal();

  return () => showModal(ModalContent, undefined, "О программе", null, null, "600px", undefined, { height: "474px", overflowY: "auto" });
};
export { useAboutModal };
