import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayJS from "dayjs";
import { makeAutoObservable, reaction } from "mobx";

import { StatusActionType, StatusSections } from "features/scenariosList/statusCard";
import { User } from "models/user";
import { backendStorage, BackendStorageMock } from "services/back/backendStorage";

import { Project } from "./project/project";
import { Projects } from "./project/projects";
import { global } from "./global";

type LogBase = {
  date: string;
  user: User;
  title: string;
  scenarioId?: number | null;
  projectName?: string;
  scenarioTitle?: string | null;
};

const ACTION_TITLES: Record<StatusActionType, string> = {
  sendToApproval: "Отправлено на согласование",
  revokeOnApproval: "Отозвано с согласования",
  rejectOnApproval: "Отклонено с согласования",
  approve: "Согласовано",
  rejectApprove: "Отклонено согласование",
};

const SECTION_TITLES: Partial<Record<StatusSections, string>> = {
  tech: "Технологические показатели",
  economic: "Экономические показатели",
  ranking: "Ресурсно-календарное планирование",
  infrastructure: "Наземная инфраструктура",
};

type StatusRequestTitle = "post:status";

type LogScenarioAction = LogBase & {
  title: StatusRequestTitle;
  action: StatusActionType;
};

type LogTitle = string | StatusRequestTitle;

type LogNote = LogBase | LogScenarioAction;

const ACTION_MAP: Record<string, string> = {
  "post:productions/forecast": "Сохранение прогноза",
  "post:gtms": "Создание ГТМ",
  "delete:gtms": "Удаление ГТМ",
  "post:wells/": "Создание скважины",
  "get:ranking_invest:storage": "Получение ранжирования",
  "post:ranking_invest:storage": "Сохранение критерия ранжирования",
  "post:ranking_settings:storage": "Сохранение настроек ранжирования",
  "post:ranking_sequential:storage": "Сохранение ручного ранжирования",
  "post:scenarios": "Создание сценария",
  "post:scenarios/copy": "Копирования сценария",
  "post:pipe_boundary_condition": "Сохранение инфраструктуры",
  "post:calculation/infrastructure": "Расчет инфраструктуры",
  "demo/schemas/new_calculation?scenario_id=": "Экономический расчет",
  "entering-project": "Вход в проект",
  "delete-user": "Удален пользователь",
};

const ROLE_TYPE: Record<string, string> = {
  functional: "функциональная",
  organization: "организационная",
};

const trNote = (title: string) => {
  if (title in ACTION_MAP) {
    return ACTION_MAP[title];
  }
  const [baseTitle, jsonPart] = title.split("::");

  let extraInfo: Record<string, any> | null = null;
  try {
    extraInfo = jsonPart ? JSON.parse(jsonPart) : null;
  } catch {
    extraInfo = null;
  }

  if (baseTitle === "post:roles/assign_role" || baseTitle === "delete:roles/users") {
    if (extraInfo) {
      const { type, roleId, userId } = extraInfo;
      const action = baseTitle === "post:roles/assign_role" ? "Присвоена" : "Удалена";
      return `${action} ${ROLE_TYPE[type] ?? ""} роль ${global.roles.mapIds![roleId].title} пользователю ${
        global.users.mapIds![userId].fullName
      }`;
    }
  }

  for (const [key, value] of Object.entries(ACTION_MAP)) {
    if (baseTitle.startsWith(key)) {
      return `${value} ${baseTitle.slice(key.length)}`;
    }
  }

  return title;
};

const createDayjs = (date: string) => {
  if (dayJS(date).day()) {
    return dayJS(date);
  }
  return dayJS(date, "DD/MM/YYYY hh:mm");
};

const sortNodes = (node1: LogNode, node2: LogNode) => {
  const date1 = createDayjs(node1.logNote.date);
  const date2 = createDayjs(node2.logNote.date);
  if (date1.isSame(date2)) {
    return 0;
  }
  return date1.isBefore(date2, "s") ? -1 : 1;
};

const extractSection = (title: string): string | undefined => {
  const match = title.match(/^post:status\/([^/:]+):storage/);
  return match ? match[1] : undefined;
};

const formatDateTime = (dateStr: string) => {
  let date = dayJS(dateStr);
  if (!date.isValid()) {
    date = dayJS(dateStr, "DD/MM/YYYY HH:mm");
  }

  return {
    date: date.format("DD-MM-YYYY"),
    time: date.format("HH:mm"),
  };
};

class Logger {
  private logger?: BackendStorageMock<void, LogNote[]>;
  private logs: LogNote[] = [];
  private globalLogs: Record<number, LogNote[]> = {};
  private project?: Project;
  private scenarioId?: number;
  public isLoadingLog = false;

  constructor() {
    this.logger = new BackendStorageMock<void, LogNote[]>("log", undefined, undefined, false);
    makeAutoObservable(this);
    reaction(
      () => this.project,
      async (newProject) => {
        const projectId = newProject?.id ?? 0;
        this.logger = new BackendStorageMock<void, LogNote[]>(
          "log",
          projectId === 0 ? undefined : projectId,
          undefined,
          false
        );
        if (this.globalLogs[projectId]) {
          this.logs = this.globalLogs[projectId];
        } else {
          this.logs = (await this.logger.getItem()) ?? [];
          this.isLoadingLog = false;
        }
        this.addNote("entering-project");
      }
    );
  }

  addNote = async <T extends Partial<LogNote>>(key: LogTitle, scenarioId?: number, options?: T) => {
    if (this.logger && global.user) {
      const logs = (await this.logger.getItem()) ?? [];
      const newLog: LogNote = {
        date: new Date().toString(),
        title: key,
        user: global.user,
        scenarioId: this.scenarioId ?? scenarioId,
        ...options,
      };

      const newLogs = await this.logger.setItem([...logs, newLog]);
      this.logs = newLogs ?? [];
    } else {
      console.assert("Логирование может быть произведено только в рамках проекта");
    }
  };

  setProject = (newProject?: Project | null) => {
    this.project = newProject ?? undefined;

    if (!this.globalLogs[newProject?.id ?? 0]) {
      this.isLoadingLog = true;
    }
  };

  setScenarioId = (newScenarioId: number) => {
    this.scenarioId = newScenarioId;
  };

  projectLog = (id: number | undefined): LogNote[] => {
    return this.globalLogs[id ?? 0];
  };

  loadAllLogs = (projectIds: (number | undefined)[]) => {
    this.isLoadingLog = true;
    Promise.all(
      projectIds.map((pId) =>
        backendStorage
          .getItem<LogNote[]>("log", pId, undefined)
          .then((data) => (this.globalLogs[pId ?? 0] = data ?? []))
      )
    ).then(() => (this.isLoadingLog = false));
  };

  get currentProjectLog(): LogNote[] {
    return this.logs;
  }

  get isLoading(): boolean {
    return this.isLoadingLog;
  }

  get logsNumber(): number {
    return Object.keys(this.globalLogs).length;
  }
}

class LoggerStore extends TableNode<LogNote | { user: string }, LogNode> {
  constructor(private logger: Logger, projects: Projects, pId?: number, onlyScenarioLog = false) {
    super();
    if (pId) {
      const project = projects.at(pId)!;
      this.childrenStore = new ChildrenStoreArray(
        this,
        logger.currentProjectLog
          .map((log) => new LogNode(this, log, project))
          .filter((log) => {
            return onlyScenarioLog ? log.logNote.title.includes("post:status") : true;
          })
      );
    } else {
      const logNodes: LogNode[] = [];

      for (const project of Array.from([undefined, ...(projects.values ?? [])])) {
        const logs = logger.projectLog(project?.id) ?? [];
        logNodes.push(...logs.map((log) => new LogNode(this, log, project)));
      }
      logNodes.sort(sortNodes);

      this.childrenStore = new ChildrenStoreArray(this, logNodes);
    }
  }

  public get getLogs() {
    return this.logger.currentProjectLog;
  }
}

class LogNode extends TableNode<LogNote | { user: string }> {
  public asDRow(): LogNote | { user: string; title: string; time?: string; moduleTitle?: string } {
    const section = extractSection(this.logNote.title);

    return {
      ...this.logNote,
      ...formatDateTime(this.logNote.date),
      scenarioTitle: this.logNote.scenarioTitle ?? this.project?.getScenario(this.logNote.scenarioId)?.title,
      projectName: this.project?.title,
      user: this.logNote.user?.name,
      title:
        "action" in this.logNote ? ACTION_TITLES[this.logNote.action as StatusActionType] : trNote(this.logNote.title),
      moduleTitle: section ? SECTION_TITLES[section as StatusSections] : undefined,
    };
  }
  constructor(parentNode: LoggerStore, public readonly logNote: LogNote, private project?: Project) {
    super(parentNode);
  }
}

export { Logger, LoggerStore };
export type { LogNote };
