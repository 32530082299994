import type { Forecast } from "models/project/fact/forecast/forecast";
import { OIZModel } from "models/project/fact/operatingParams/oiz";
import { depositPredicate } from "services/finance/deposits";
import { Metric } from "services/finance/utils";
import { expandArr } from "utils/expandArray";

import { CALCULATE_PREDEFINE } from "./calculatePredefine";
import { fillValues, linkParent, sophisticatedSearch } from "./utils";

const CODE_TITLES = {
  "Прочие расходы": {
    "Расходы по добыче нефти и газа, сбор газа попутного": "Прочие расходы",
    "Расходы по искусственному воздействию на пласт": "Прочие расходы (возд.)",
    "Расходы по транспортировке нефтесодержащей жидкости и газа": "Прочие расходы (транспорт.)",
    "Расходы по технологической подготовке нефти": "Прочие расходы (подготов.)",
  },
  Энергия: {
    "Расходы по искусственному воздействию на пласт": "Энергия",
    "Расходы по транспортировке нефтесодержащей жидкости и газа": "Энергия (транспорт.)",
    "Расходы по технологической подготовке нефти": "Энергия (подготов.)",
  },
};

function operatingSchema(forecast: Forecast, regionKey: number, regionName: string) {
  const predef = CALCULATE_PREDEFINE.lz[0].schema_operation.schema;
  const schema: any[] = [];
  const valuesLength = forecast.wholeRange.length;

  const nddYear = forecast.fact.NDDYear;
  const stateNddValuesCount = nddYear && forecast.fact.lastDescribedYear - nddYear;

  function addValues(metric: any, values: Array<number | null>) {
    if (values.length !== valuesLength) {
      throw new Error(`Metric incomplete (${values.length}/${valuesLength}): ${metric.title} ${values}`);
    }
    schema.push({ ...metric, values });
  }

  function fillUnion(metric: any, stateMetrics: Metric[], forecastMetrics: Metric[]): Array<number | null> | undefined {
    const stateMetric = stateMetrics.find((m) => m.title === metric.title);
    const forecastMetric = forecastMetrics.find((m) => m.title === metric.title);
    if (stateMetric !== undefined || forecastMetric !== undefined) {
      let values = fillValues(forecast, stateMetric, forecastMetric, metric);
      if (stateNddValuesCount && stateMetric?.values?.length === stateNddValuesCount) {
        // taxNdd table
        values = expandArr(values, forecast.fact.lastDescribedYear - stateNddValuesCount, forecast.wholeRange);
      }
      return values;
    }
    return undefined;
  }

  const operatingParams = linkParent(
    forecast.fact.operatingParams.get(regionKey).table!.metrics!.flatMap((m) => m.flatten())
  );

  const operatingRevenueState = forecast.fact.operatingRevenue
    .get(regionKey)
    .table!.metrics!.flatMap((m) => m.flatten());
  const operatingRevenueForecast = forecast.operatingRevenue.get(regionKey).table!.metrics!.flatMap((m) => m.flatten());

  const taxWealthState = forecast.fact.wealthTax.get(regionKey).table!.metrics!.flatMap((m) => m.flatten());
  const taxNddState = forecast.fact.nddTax.table!.metrics!.flatMap((m) => m.flatten());
  const taxSeveranceState = forecast.fact.severanceTax.table!.metrics!.flatMap((m) => m.flatten());
  const depositsState = forecast.fact.depositParams.table!.metrics!.flatMap((m) => m.flatten());
  const referenceState = forecast.fact.referenceParams.get(regionKey).table!.metrics!.flatMap((m) => m.flatten());

  const taxWealthForecast = forecast.wealthTax.get(regionKey).table!.metrics!.flatMap((m) => m.flatten());
  const taxNddForecast = forecast.nddTax.table!.metrics!.flatMap((m) => m.flatten());
  const taxSeveranceForecast = forecast.severanceTax.table!.metrics!.flatMap((m) => m.flatten());
  const depositsForecast = forecast.depositParams.table!.metrics!.flatMap((m) => m.flatten());
  const referenceForecast = forecast.referenceParams.get(regionKey).table!.metrics!.flatMap((m) => m.flatten());

  const taxModels = [
    [taxWealthState, taxWealthForecast, "taxWealth"],
    [taxNddState, taxNddForecast, "taxNdd"],
    [taxSeveranceState, taxSeveranceForecast, "taxSeverance"],
    // Все эти параметры по факту специфичны для расчета именно налогов так что доставать будем вместе с остальными
    [depositsState, depositsForecast, "taxCorporate"],
    [referenceState, referenceForecast, "taxCorporate"],
  ] as const;

  for (const metric of predef) {
    if (metric.editable.not_editable === true || metric.unit.quantity === 0) {
      if (metric.title === "Год") {
        metric.values = [...forecast.wholeRange];
      }
      schema.push(metric);
      continue;
    }
    let values = [];
    let found = false;

    if (metric.title === "Количество ГРП") {
      addValues(metric, forecast.wholeRange.array.fill(0)); // FIXME
      continue;
    }

    if (metric.title === "Коэффициент выработанности (Кв)") {
      const coef = taxSeveranceState.find((m) => m.title === metric.title)!;
      addValues(metric, expandArr(coef.values!, forecast.wholeRange.from, forecast.wholeRange));
      continue;
    }

    if (metric.title === "Реализация нефти по направлению 3") {
      addValues(metric, forecast.wholeRange.array.fill(null));
      continue;
    }

    if (OIZModel.METRICS.includes(metric.title)) {
      if (regionName.includes("498")) {
        continue;
      }
      const fcData = forecast.oiz.data!;
      const factData = forecast.fact.oiz.data!;
      const title = metric.title;
      const values = fillValues(forecast, { title, values: factData[title] }, { title, values: fcData[title] }, metric);
      addValues(metric, values);
      continue;
    }

    const param = operatingParams.find(sophisticatedSearch(CODE_TITLES, metric));
    if (param !== undefined) {
      values = fillValues(forecast, param, undefined, metric);
      addValues(metric, values);
      continue;
    }

    const revenueValues = fillUnion(metric, operatingRevenueState, operatingRevenueForecast);
    if (revenueValues !== undefined) {
      addValues(metric, revenueValues);
      continue;
    }

    for (const [stateMetrics, forecastMetrics] of taxModels) {
      const taxValues = fillUnion(metric, stateMetrics, forecastMetrics);
      if (taxValues !== undefined) {
        addValues(metric, taxValues);
        found = true;
        break;
      }
    }

    if (!found) {
      console.error(`Metric "${metric.title}" not presented`);
    }
  }

  for (const { title, key } of depositsState.filter(depositPredicate)) {
    const metric = {
      code_title: title.replace("на начало года", "(на нач. г.)"),
      editable: {
        only_before_ndd: true,
        start_from: 2013,
      },
      id: key,
      parent_id: 61,
      title: title,
      unit: { measure: 5, quantity: 5 },
      values: [] as number[],
      visible: {},
    };
    addValues(metric, fillUnion(metric, depositsState, depositsForecast)!);
  }
  return { schema };
}

export { operatingSchema };
