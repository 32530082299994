import { useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";

import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useForecast } from "models/project/fact/forecast/forecast";

import { useOperatingGTMContext } from "../operatingGTM";

import cn from "./decreaseCoeffsTable.module.less";

const useColumns = (): Column[] => {
  const forecast = useForecast();

  return useMemo(
    (): Column[] => [
      {
        dataKey: "title",
        title: "Параметр",
        type: "string",
        isSticky: true,
        width: { min: 380, max: 380, competitiveness: 1 },
        onCell: ({ indexPath }) => ({ style: { paddingLeft: indexPath.length * 15 } }),
        hideFilter: true,
      },

      ...[...forecast!.range].slice(1).map(
        (year, index): Column => ({
          dataKey: `${year}`,
          title: `${year}`,
          type: "number",
          min: 0,
          minStrict: false,
          max: 1,
          width: { min: 130, max: 300, competitiveness: 1 },
          editable: true,
          hideFilter: true,
        })
      ),
    ],
    [forecast]
  );
};
const DecreaseCoeffsTable = observer(() => {
  const store = useOperatingGTMContext()!.DecreaseCoeffsTableModel;
  const columns = useColumns();

  return (
    <SimpleTableContext
      exportFileName="Понижающий коэф-т"
      data={store}
      columns={columns}
      hideExpandColumn
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      }}
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
    >
      <Widget headerClassName={cn.tableHeader} />
    </SimpleTableContext>
  );
});

export { DecreaseCoeffsTable };
