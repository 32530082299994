import { MutableRefObject } from "react";
import { Scale } from "@okopok/axes_context";
import * as d3 from "d3";

import { NodeType } from "./InfrastructureMapManager/InfrastructureMapManager";

const calculateSnapPoint = (
  nodes: NodeType[],
  position: { x: number; y: number },
  scale: Scale,
  zoom: number
): NodeType | undefined => {
  function calculateSnapDistance(zoomLevel: number) {
    const proximity: Record<number, number> = {
      0.5: 35,
      1: 25,
      2: 15,
      4: 10,
      8: 5,
      16: 5,
      32: 1.5,
      64: 1,
      128: 0.3,
      256: 0.2,
      512: 0.1,
      1024: 0.05,
      2048: 0.025,
      4096: 0.01,
      8192: 0.005,
      16384: 0.002,
    } as const;
    return proximity[zoomLevel] ?? 5;
  }

  return (nodes ?? []).find(
    (node) =>
      Math.hypot(scale.x(node.x) - scale.x(position.x), scale.y(node.y) - scale.y(position.y)) <
      calculateSnapDistance(zoom)
  );
};

function isPointInPolygon(point: { x: number; y: number }, polygon: { x: number; y: number }[]) {
  const { x, y } = point;

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const [xi, yi] = [polygon[i].x, polygon[i].y];
    const [xj, yj] = [polygon[j].x, polygon[j].y];

    const yiCrosses = yi > y;
    const yjCrosses = yj > y;
    let isCrosses = yiCrosses !== yjCrosses;

    let segmentCrossX = ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    let isPointLeftOfIntersect = x < segmentCrossX;
    let intersect = isCrosses && isPointLeftOfIntersect;
    if (intersect) inside = !inside;
  }
  return inside;
}

const formatScale = (event: any, scale: MutableRefObject<Scale>) => {
  const { offsetX, offsetY } = event.sourceEvent;
  return {
    x: scale.current.x.invert(offsetX),
    y: scale.current.y.invert(offsetY),
  };
};

type CalculateDistanceType = {
  from?: { x: number; y: number; z?: number } | null;
  to?: { x: number; y: number; z?: number } | null;
  unit?: "m" | "km";
};
const calculateDistance = ({ from = { x: 0, y: 0 }, to = { x: 0, y: 0 }, unit = "m" }: CalculateDistanceType) => {
  if (!(from && to)) {
    return 0;
  }
  const deltaX = (from.x - to.x) ** 2;
  const deltaY = (from.y - to.y) ** 2;
  const deltaZ = ((from.z || 0) - (to.z || 0)) ** 2;
  const distance = (deltaX + deltaY + deltaZ) ** 0.5;
  return unit === "km" ? distance / 1000 : distance;
};

const getSelectedText = (pointLength?: number, pipeLength?: number) => {
  return `Выбрано:${pointLength ? `объекты (${pointLength})` : ""} ${pipeLength ? `трубопроводы (${pipeLength})` : ""}`;
};

const findExistingNode = (nodes: NodeType[], position: { x: number; y: number } | null): NodeType | undefined => {
  if (!position) return undefined;
  return (nodes ?? []).find((node) => Math.hypot(node.x - position.x, node.y - position.y) < 10);
};

const fromUniformArray = (COLOR_NODES: string[], start: number, end: number) =>
  d3.scaleLinear(
    COLOR_NODES.map((_, id) => start + ((end - start) * id) / (COLOR_NODES.length - 1)),
    COLOR_NODES
  );

const LEGEND_MAP_COLOR = [
  "rgb(4, 48, 128)",
  "rgb(6, 68, 178)",
  "rgb(0, 254, 246)",
  "rgb(75, 242, 68)",
  "rgb(254, 228, 0)",
  "rgb(252, 139, 0)",
  "rgb(253, 0, 0)",
  "rgb(255, 173, 234)",
];

const getZoomParameters = (zoom: number) => {
  const zoomScaleFactor = 6 / zoom; //Необходим для ширины pipe
  const zoomScale = (zoomScaleFactor * 20) / 100; //Необходим для текста, node
  return { zoomScaleFactor, zoomScale };
};

const scaleMapForStrokeWidthDasharray = {
  16384: 0.0015,
  8192: 0.0025,
  4096: 0.005,
  2048: 0.01,
  1024: 0.03,
  512: 0.06,
  256: 0.12,
  128: 0.25,
  64: 0.5,
  32: 1,
  16: 2,
  8: 3,
  4: 5,
  2: 10,
  1: 20,
  0.5: 20,
} as const;

export {
  calculateDistance,
  calculateSnapPoint,
  findExistingNode,
  formatScale,
  fromUniformArray,
  getSelectedText,
  getZoomParameters,
  isPointInPolygon,
  LEGEND_MAP_COLOR,
  scaleMapForStrokeWidthDasharray,
};
