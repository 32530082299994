import hash from "object-hash";

import { global } from "models/global";
import { LogNote } from "models/logger";
import { Dec } from "utils/localStorageMock";
import { req } from "utils/request";

type StorageBody<T> = {
  project_id: number;
  scenario_id: number;
  title: string;
  data: T;
  id: number;
};

const backendStorage = {
  async getItem<T = unknown>(
    title: string,
    project_id?: number,
    scenario_id?: number,
    needLog = false
  ): Promise<T | null> {
    const result = await req.get<StorageBody<T>>(`storage/?${req.args({ title, project_id, scenario_id })}`);
    if (result && needLog) {
      await global.logger.addNote(`get:${title}:storage`);
    }
    return (result?.data as T) ?? null;
  },
  async setItem<T = unknown>(
    data: T,
    title: string,
    projectId?: number,
    scenarioId?: number,
    needLog = false,
    logOptions?: Partial<LogNote>
  ): Promise<T | null> {
    const result = await req
      .post<StorageBody<T>>("storage/", {
        data,
        title,
        project_id: projectId,
        scenario_id: scenarioId,
      })
      .then(({ data }) => data)
      .catch(() => {
        return null;
      });

    if (needLog && result) {
      await global.logger.addNote(`post:${title}:storage`, scenarioId, logOptions);
    }
    return result;
  },
  async addItem<T = unknown>(
    data: T,
    title: string,
    projectId?: number,
    scenarioId?: number,
    needLog = false,
    logOptions?: Partial<LogNote>
  ): Promise<T | null> {
    const result = await req
      .post<StorageBody<T>>("storage/data/add", {
        data,
        title,
        project_id: projectId,
        scenario_id: scenarioId,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        e.json().then((data: { detail: string }) => {
          if (data.detail === "Found more or less than one element in storage") {
            this.setItem([data], title, projectId, scenarioId, needLog, logOptions);
          }
        });
        return null;
      });
    if (needLog && result) {
      await global.logger.addNote(`post:${title}:storage-add`, scenarioId, logOptions);
    }
    return result;
  },
  removeItem(title: string, project_id?: number, scenario_id?: number, needLog = false): Promise<void> {
    if (needLog) {
      global.logger.addNote(`delete:${title}:storage`);
    }
    return req.delete(`storage/?${req.args({ title, project_id, scenario_id })}`);
  },
};

class BackendStorageMock<GetArgs = unknown, SetArgs = GetArgs, Response = SetArgs> {
  private hash?: string | null;

  constructor(
    private readonly title: string,
    private readonly projectId?: number,
    private readonly scenarioId?: number,
    useHash = true
  ) {
    if (!useHash) {
      this.hash = null;
    }
  }

  static new(title: string, projectId: number, scenarioId?: number, useHash = true) {
    const mock = new BackendStorageMock(title, projectId, scenarioId, useHash);
    return [...mock.decorators, mock.mockKey];
  }

  private get key(): string {
    if (this.hash === undefined) {
      console.error("key access before hashing. returning raw storageName");
      return this.title;
    }
    if (this.hash === null) {
      return this.title;
    }
    return `${this.title}_${this.hash}`;
  }

  public getItem(needLog = false): Promise<Response | null> {
    return backendStorage.getItem<Response>(this.key, this.projectId, this.scenarioId, needLog);
  }

  public setItem(data: Response | null, needLog = false) {
    return backendStorage.setItem(data, this.key, this.projectId, this.scenarioId, needLog);
  }

  public addItem(data: (Response extends (infer U)[] ? U : never) | null, needLog = false) {
    return backendStorage.addItem(data, this.key, this.projectId, this.scenarioId, needLog);
  }

  public removeItem() {
    return backendStorage.removeItem(this.key, this.projectId, this.scenarioId);
  }

  public get decorators(): [Dec<GetArgs, Response>, Dec<SetArgs, Response>] {
    const getter: Dec<GetArgs, Response> =
      (fn) =>
      async (...args) => {
        const result = await fn(...args);
        if (this.hash !== null) {
          this.hash = hash(result as hash.NotUndefined);
        }

        const stored = await this.getItem();
        if (stored !== null) {
          return stored;
        }
        await this.setItem(result);
        return result;
      };

    const setter: Dec<SetArgs, Response> =
      (fn) =>
      async (...args) => {
        const updatedData = await fn(...args);
        await this.setItem(updatedData);
        return updatedData;
      };

    return [getter, setter];
  }

  public mockKey = <Stored extends {}, Item extends {} | null>(
    getItem: (obj: Stored, ...args: any[]) => Item,
    setItem?: (obj: Stored, ...args: any[]) => (newValue: Item) => Item
  ) => {
    type Fn = (...args: any[]) => Promise<Item>;

    const keyGetter =
      (fn: Fn) =>
      async (...args: any[]) => {
        const obj = await this.getItem();
        if (obj === null) {
          console.warn("Intial object is not stored. Returning value from hardcoded mock for now");
          return fn(...args);
        }
        const item = getItem(obj as any, ...args);
        if (item === undefined) {
          const result = await fn(...args);
          if (setItem === undefined) {
            console.warn("Field not found in stored object. Returning value from hardcoded mock for now");
            return result;
          }
          const obj = await this.getItem();
          setItem(obj as any, ...args)(result);
          await this.setItem(obj);
          return result;
        }
        return item;
      };

    const keySetter =
      (fn: Fn) =>
      async (...args: any[]) => {
        const newValue = await fn(...args);
        const obj = await this.getItem();
        if (obj === null) {
          console.error("Cant set key if initial object is not stored");
          return newValue;
        }
        if (setItem !== undefined) {
          setItem(obj as any, ...args)(newValue);
        } else {
          const item = getItem(obj as any, ...args);
          if (item !== null && newValue !== null) {
            // else item was deleted from array in getItem function
            Object.assign(item, newValue);
          }
        }
        await this.setItem(obj);
        return newValue;
      };

    return [keyGetter, keySetter];
  };
}

export { backendStorage, BackendStorageMock };
