import type { FC } from "react";

import { Icon } from "elements/icon/icon";
import { SharedIconProps } from "elements/icons/sharedIconProps";

const ASSET: FC<{ modified: boolean }> = ({ modified }) => {
  const color = modified ? "#DDDDDD" : "#AD211A";

  return (
    <g style={{ color, stroke: "none" }}>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.3599 17L6.99991 7.99999L8.44157 6.61377L17.8016 15.6138L16.3599 17Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.13991 17L17.4999 7.99999L16.0582 6.61377L6.69824 15.6138L8.13991 17Z" fill="currentColor" />
    </g>
  );
};

const DeleteIcon: FC<SharedIconProps & { modified?: boolean }> = ({ modified = false, ...props }) => (
  <Icon {...props} viewBox="0 0 24 24" content={<ASSET modified={modified} />} />
);

export { DeleteIcon };
