import { Button } from "antd";
import classNames from "classnames";

import { Card, Stick } from "elements/card/card";
import { Format } from "elements/format/format";
import { Eye } from "elements/icons/eye";
import { yearWordChoose } from "services/yearWordChoose";
import { usePersistableState } from "utils/usePersistable";

import { useSummaryContext } from "./summaryPage";

import styles from "./summaryPage.module.less";

const Donut = () => {
  const [hiddenIrr, setHiddenIrr] = usePersistableState<boolean>("hiddenIrr", false);

  const model = useSummaryContext();
  return (
    <Card title="Окупаемость" className={classNames(styles.payBack, styles.card)}>
      <div className={styles["cardBody"]}>
        <div className={styles.section}>
          <Stick
            className={styles.stick}
            title="PI"
            children={
              <span>
                <span className={styles.big}>
                  <Format>{model.payback.pi}</Format>
                </span>{" "}
                д.ед.
              </span>
            }
          />
          <Stick
            className={styles.stick}
            title="PBP"
            children={
              <span>
                {model.payback.pbp === 999 ? (
                  "не окупается"
                ) : (
                  <>
                    <span className={styles.big}>{model.payback.pbp}</span> {yearWordChoose(model.payback.pbp)}
                  </>
                )}
              </span>
            }
          />
          <Stick
            className={styles.stick}
            title="dPBP"
            children={
              <span>
                {model.payback.dpbp === 999 ? (
                  "не окупается"
                ) : (
                  <>
                    <span className={styles.big}>{model.payback.dpbp}</span> {yearWordChoose(model.payback.dpbp)}
                  </>
                )}
              </span>
            }
          />
          <Stick
            title={
              <div className={styles.irrTitle}>
                <div>IRR</div>
                <Button type="text" icon={<Eye isVisible={!hiddenIrr} />} onClick={() => setHiddenIrr(!hiddenIrr)} />
              </div>
            }
            children={
              !hiddenIrr && (
                <span className={styles.big}>
                  {model.payback.irr < 0 ? (
                    "IRR не определен"
                  ) : (
                    <>
                      <Format>{model.payback.irr > 100 ? 100 : model.payback.irr}</Format>%
                    </>
                  )}
                </span>
              )
            }
            className={classNames(styles.stick, styles.irrStick)}
          />
        </div>
      </div>
    </Card>
  );
};

export { Donut };
