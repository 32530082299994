import { FC, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Alert } from "antd";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Preloader } from "features/preloader/preloader";
import { ProcessNotification } from "features/techForecast/processNotification";
import { global } from "models/global";
import { useProject } from "models/project/project";

import { ProjectFolder } from "./authorized/assets/routing";
import { useCurrentPath } from "./authorized/modesMenu/modeSwitch/modeSwitch";
import { usePagePostfix } from "./secondaryMenu/scenarioMenu";
import { SecondaryMenu } from "./secondaryMenu/secondaryMenu";
import { TreeMenu } from "./treeMenu/treeMenu";
import { ProjectContextProvider } from "./projectContextProvider";

import cn from "./projectOutline.module.less";

const needWellTreePages: string[][] = [
  ["reports", "report-oil-state-plan"],
  ["reports", "report-apg-state-plan"],
  ["wells", "summary"],
  ["wells", "tech"],
  ["wells", "injection"],
  ["wells", "injection", "params"],
  ["wells", "prediction"],
  ["wells", "prediction", "results"],
  ["ecy", "wells-report"],
  ["wells", "wells"],
  ["wells", "wells", "base"],
];

const checkNeedWellTree =
  ({ key }: ProjectFolder, pagePostfix: string[]) =>
  (record: string[]) => {
    return [key, ...pagePostfix].join() === record.join();
  };

const isPageNeedWellTree = (folder: ProjectFolder, pagePostfix: string[]) => {
  return needWellTreePages.some(checkNeedWellTree(folder, pagePostfix));
};

const ProjectOutline: FC = observer(() => {
  const project = useProject();
  const params = useParams();
  const paramsParsed = useMainRouterParams();
  const pagePostfix = usePagePostfix();
  const [folder] = useCurrentPath();

  const [showAlert, setShowAlert] = useState(false);

  const isNeedWellTree = isPageNeedWellTree(folder, pagePostfix);
  const isAnyProcessGoing = project?.longProcesses && Object.values(project?.longProcesses!).some((process) => process.going);

  useEffect(() => {
    global.logger.setProject(project);
  }, [project]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isAnyProcessGoing) {
        setShowAlert(true);
        event.preventDefault();
        event.returnValue = "";
      } else {
        setShowAlert(false);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isAnyProcessGoing]);

  useEffect(() => {
    if (!isAnyProcessGoing) {
      setShowAlert(false);
    }
  }, [isAnyProcessGoing]);

  if (project === null) {
    if (paramsParsed.project === undefined) {
      return <FullScreenEmpty>Страница {params.project} не найдена</FullScreenEmpty>;
    }
    return <FullScreenEmpty>Не удалось найти проект с ключом {params.project}</FullScreenEmpty>;
  }

  return (
    <Preloader hooks={[useProject]}>
      <ProjectContextProvider>
        <SecondaryMenu />
        {isNeedWellTree && <TreeMenu />}
        <div className={cn.main}>
          <Outlet />
          {isAnyProcessGoing &&
            project?.longProcesses &&
            Object.keys(project.longProcesses).map((key) => <ProcessNotification process={project.longProcesses[key]} processKey={key} />)}
          {showAlert && (
            <Alert
              message="Cтраница выполняет фоновую задачу синхронизации. Если закрыть вкладку сейчас, данные не будут обновлены"
              type="warning"
              showIcon
              className={cn.alert}
            />
          )}
        </div>
      </ProjectContextProvider>
    </Preloader>
  );
});

export { ProjectOutline };
