import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.2957 3.3767C1.38435 3.53027 1.53052 3.6422 1.70189 3.68773L3.74921 4.23168C4.10506 4.32623 4.47017 4.1144 4.56471 3.75856C4.65926 3.40271 4.44743 3.0376 4.09159 2.94306L2.69142 2.57104L3.06948 1.17288C3.16559 0.817456 2.95537 0.451417 2.59994 0.35531C2.24452 0.259203 1.87848 0.469422 1.78237 0.824847L1.22953 2.8694C1.18324 3.04057 1.20706 3.22313 1.2957 3.3767Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.10098 2.20996C6.03849 2.11762 6.97901 2.35321 7.76229 2.87658C8.54557 3.39995 9.12315 4.17872 9.39661 5.0802C9.67007 5.98168 9.62249 6.95009 9.26199 7.82042C8.90148 8.69076 8.25036 9.40917 7.41955 9.85324C6.58874 10.2973 5.62966 10.4396 4.70572 10.2558C3.78178 10.072 2.95014 9.57355 2.35252 8.84535C1.75489 8.11714 1.42825 7.20424 1.42825 6.2622C1.42825 5.89401 1.12977 5.59553 0.761582 5.59553C0.393393 5.59553 0.0949154 5.89401 0.0949154 6.2622C0.0949154 7.51271 0.528516 8.72454 1.32184 9.6912C2.11515 10.6579 3.21911 11.3196 4.4456 11.5635C5.67208 11.8075 6.94522 11.6186 8.04808 11.0291C9.15093 10.4396 10.0153 9.48599 10.4938 8.33067C10.9724 7.17534 11.0355 5.88983 10.6725 4.69316C10.3095 3.49649 9.54282 2.4627 8.50305 1.76795C7.46328 1.0732 6.21479 0.760473 4.97029 0.883045C3.7258 1.00562 2.5623 1.55591 1.67806 2.44016C1.41771 2.70051 1.41771 3.12262 1.67806 3.38297C1.93841 3.64332 2.36052 3.64332 2.62087 3.38297C3.28699 2.71684 4.16348 2.30229 5.10098 2.20996Z"
      fill="currentColor"
    />
  </g>
);

const ReloadIcon: FC<SharedIconProps> = (props) => <Icon {...props} viewBox="0 0 11 12" content={ASSET} />;

export { ReloadIcon };
