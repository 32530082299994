import { makeAutoObservable, runInAction, transaction } from "mobx";

import { StopCriterion as StopCriterionDump } from "services/back/techForecast/request";

class StopCriterion {
  waterCut: null | number = 99;
  waterCutMin: null | number = null;
  oilRate: null | number = null;
  oilProdMonthDuration: null | number = null;
  oilDropRate: number | null = null;
  liquidDropRate: number | null = null;
  recoverableResources: null | number = null;
  accumOilProd: null | number = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isValid() {
    return Object.values({ ...this.dump, waterCut: this.waterCut }).find((v) => v !== null) !== undefined;
  }

  get invalidationExplain(): string | null {
    return this.isValid ? null : "Не указан ни один критерий остановки";
  }

  holder(field: keyof StopCriterionDump) {
    return (value: number | null) => {
      runInAction(() => {
        this[field] = value;
      });
    };
  }

  get dump(): StopCriterionDump {
    return {
      waterCut: this.waterCut ?? 100,
      waterCutMin: this.waterCutMin,
      oilRate: this.oilRate,
      oilProdMonthDuration: this.oilProdMonthDuration,
      oilDropRate: this.oilDropRate,
      liquidDropRate: this.liquidDropRate,
      recoverableResources: this.recoverableResources,
      accumOilProd: this.accumOilProd ? this.accumOilProd / 1000 : this.accumOilProd,
    };
  }

  applyDump(dump: StopCriterionDump) {
    transaction(() => {
      for (const [key, value] of Object.entries(dump)) {
        this[key as keyof StopCriterionDump] = value;
      }
    });
  }
}

export { StopCriterion };
