import { makeAutoObservable } from "mobx";

import type { ParamsRow } from "features/useMetrics/paramsRow";
import { ParamsNode } from "models/params/paramsNode";
import { MetricTree, riseMetrics } from "services/finance/utils";
import { Range } from "utils/range";
import { numerateTree } from "utils/tree";

import { Fact } from "./fact";
class ECY {
  public metrics?: ParamsNode[];

  constructor(
    public id: number,
    rawData: MetricTree[],
    public title: string,
    public discount: number,
    public readonly years: Range,
    private state: Fact
  ) {
    this.fromRaw(rawData);
    makeAutoObservable(this);
  }

  public fromRaw(raw: MetricTree[]): void {
    this.metrics = raw.map(ParamsNode.fromRaw);
    this.metrics.forEach((v) => v.propagateParent());
  }

  public get asRaw(): MetricTree[] {
    const data = this.metrics!.flatMap((node) => node.flatten());
    return riseMetrics(data);
  }

  public setDiscount(value: number) {
    this.discount = value;
  }

  public get isLoading() {
    return this.metrics === undefined;
  }

  static modifyWays(data: ParamsRow[]) {
    const rowWays = data.pop();
    if (!rowWays || !rowWays.children) return;
    rowWays.children = rowWays.children.filter((el) => !el.title.includes("направление 3"));
    data.push(rowWays);
  }

  public get tableItems(): ParamsRow[] {
    console.assert(this.metrics !== undefined, "Попытка отобразить таблицу до завершения её загрузки");
    const result = this.metrics!.map((v) => v.tableItems);
    ECY.modifyWays(result);
    numerateTree(result);
    return result;
  }

  public clone() {
    const getRandomId = () => {
      const min = -10000000;
      const max = -10;
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const newId = getRandomId();
    const newEcy = new ECY(newId, structuredClone(this.asRaw), this.title, this.discount, this.years, this.state);

    return newEcy;
  }
}

export { ECY };
