import { LineDataModel } from "@okopok/axes_context";
import dayjs from "dayjs";
import { computed, makeObservable } from "mobx";

import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { SummaryModel } from "models/summary";
import { colorCarousel } from "services/colorCarousel";

class IncomingPartChartModel {
  constructor(public summaryModel: SummaryModel) {
    makeObservable(this, {
      tooltipManager: computed,
      lines: computed,
    });
  }

  get tooltipManager() {
    return new TooltipDataManager(this.lines);
  }

  get lines() {
    return [
      new LineDataModel({
        y: this.summaryModel.NPV.data
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.y),
        x: this.summaryModel.NPV.data
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.x),
        axisKey: "other",
        key: "npv",
        color: colorCarousel(3),
        title: `NPV, млн $`,
      }),
      new LineDataModel({
        y: this.summaryModel.cashFlowData
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.y),
        x: this.summaryModel.cashFlowData
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.x),
        axisKey: "other",
        key: "cashFlow",
        color: colorCarousel(4),
        title: `Чистый денежный поток, млн $`,
      }),
    ];
  }
}

export { IncomingPartChartModel };
