import { Format } from "elements/format/format";
import { Nest } from "elements/tree/model/tree";
import { Forecast } from "models/project/fact/forecast/forecast";
import { type FondType, Well } from "models/project/fact/well/well";
import { type WellPad } from "models/project/fact/wellPad/wellPad";
import { type LicenseRegion } from "models/project/licenseRegion/licenseRegion";
import { type ProducingObject } from "models/project/producingObject/producingObject";

const MINE_NEST: Nest<Well, WellPad | null | undefined> = {
  key: "mine",
  title: "Куст",
  getter: (w: Well) => w.pad,
  render: (pad) => <Format>{pad ? pad.title : pad}</Format>,
  renderToString: (pad) => pad?.title,
} as const;

const LR_NEST: Nest<Well, LicenseRegion | null | undefined> = {
  key: "licenseRegion",
  title: "Лицензионный участок",
  getter: (w: Well) => w.licenseRegion,
  render: (lr) => <Format>{lr ? lr.title : lr}</Format>,
  renderToString: (lr) => lr?.title,
} as const;

const FOND_NEST: Nest<Well, FondType> = {
  key: "fond",
  title: "Фонд",
  getter: (w: Well) => w.fond,
  render: (fond) => <Format>{{ New: "Новый", Base: "Базовый" }[fond]}</Format>,
  renderToString: (fond) => fond,
} as const;

const TREE_NESTING_FIELDS: Nest<Well, any>[] = [MINE_NEST, LR_NEST, FOND_NEST];

const NULL_PRODUCING_OBJECT = {
  title: "Остальные",
};

const producingObjectNestingField = (fc: Forecast | null): Nest<Well, Pick<ProducingObject, "title"> | null | undefined> => {
  let getter;
  if (fc === null) {
    getter = (w: Well) => {
      const prodObjects = w.producingObjects;
      return prodObjects?.length ? prodObjects : [NULL_PRODUCING_OBJECT];
    };
  } else {
    getter = (w: Well) => {
      const gtms = fc.interventions.getInterventionsByWellId(w.id);
      const gtmsProducingObjects = gtms.map((g) => g.producingObject).filter((po): po is ProducingObject => !!po);
      const prodObjects = [...new Set([...(w.producingObjects ?? []), ...gtmsProducingObjects])];
      return prodObjects?.length ? prodObjects : [NULL_PRODUCING_OBJECT];
    };
  }
  return {
    key: "producingObject",
    title: "Объект разработки",
    getter,
    render: (pd) => <Format>{pd ? pd.title : pd}</Format>,
    renderToString: (pd) => (pd?.title === NULL_PRODUCING_OBJECT.title ? undefined : pd?.title),
    renderKey: (pd) => pd?.title ?? "unknown producing object",
  };
};

export { producingObjectNestingField, TREE_NESTING_FIELDS };
