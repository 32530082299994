import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, when } from "mobx";

import { UOM } from "elements/uom";
import { global } from "models/global";
import { Fact } from "models/project/fact/fact";
import { Forecast } from "models/project/fact/forecast/forecast";
import { OIZModel } from "models/project/fact/operatingParams/oiz";
import { ProducingObjectsParams } from "models/project/fact/producingObjectsParams/producingObjectsParams";
import { LicenseRegion } from "models/project/licenseRegion/licenseRegion";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { type OIZParams } from "services/back/producingObjectsParams";
import { zip } from "utils/itertools";

import { ProdObjectsGeneric } from "./generics";

type DRow = {
  name: string;
  measure: string | null;
  [year: number]: number | null;

  isEditable?: boolean;
};

type ParamKeys = "reserves";

type ParamsStore<Keys extends ParamKeys, V = number | null> = {
  [producingObjectId: number]: {
    [licenseRegionId: number]: Record<Keys, V[]>;
  };
};
type ParamsStoreValidated<K extends ParamKeys> = ParamsStore<K, number>;

class OIZ extends TableNode<DRow, DummyRow> {
  asDRow = (): DRow => ({
    name: `ОИЗ на участке ХМН-00497-НЭ`,
    measure: null,
  });

  private fact: Fact;
  private forecast: Forecast | null;
  private data: OIZParams | undefined = undefined;

  constructor(root: ReservesDepletion) {
    super(root, { isExpandedChildren: true });
    makeObservable<OIZ, "data">(this, {
      data: observable,
      init: action,
      isValid: computed,
    });
    this.fact = root.fact;
    this.forecast = root.forecast;
  }

  public get isValid(): boolean {
    if (this.data === undefined) {
      return false;
    }
    for (const v of Object.values(this.data)) {
      if (v.includes(null)) {
        return false;
      }
    }
    return true;
  }

  private get source(): OIZModel {
    return (this.forecast ?? this.fact).oiz;
  }

  public async init() {
    await when(() => this.source.isLoading === false);
    this.data = JSON.parse(JSON.stringify(this.source.data!));
    this.childrenStore = new ChildrenStoreArray(
      this,
      OIZModel.METRICS.map((name) => new DummyRow(this, OIZ.SUB_ROWS[name], this.data![name]))
    );
  }

  public async submit() {
    if (this.data === undefined) {
      console.error("OIZ data undefined");
      return;
    }
    return this.source.submit(this.data);
  }

  public get paramsRange() {
    return this.source.paramsRange;
  }

  static SUB_ROWS: Record<(typeof OIZModel)["METRICS"][number], string> = {
    "Остаточные извлекаемые запасы проектного месторождения на ЛУ":
      "ОИЗ проектного месторождения на участке ХМН-00497-НЭ",
    "Остаточные извлекаемые запасы второго месторождения на ЛУ": "ОИЗ второго месторождения на участке ХМН-00497-НЭ",
  };
}

class DummyRow extends TableNode<DRow> {
  asDRow = (): DRow => ({
    name: this.name,
    measure: "млн т",
    ...Object.fromEntries(zip([...this.paramsRange], this.data)),
    isEditable: true,
  });

  constructor(public readonly parent: OIZ, private name: string, private readonly data: (number | null)[]) {
    super(parent, { isExpandedChildren: true });
    this.childrenStore = null;
  }

  private get paramsRange() {
    return this.parent.paramsRange;
  }

  updateValue(year: number, newValue: number | null): [prevValue: any, currValue: any] {
    const idx = this.paramsRange.id(year);
    const prev = this.data[idx];
    return [prev, (this.data[idx] = newValue)];
  }
}

class ReservesDepletion extends TableNode<DRow, ProdObjectsGeneric<ParamKeys> | OIZ> {
  public licenseRegions?: LicenseRegion[];
  public producingObjects?: ProducingObject[];
  public data?: ParamsStore<ParamKeys>;
  private oiz?: OIZ;

  constructor(public readonly fact: Fact, public readonly forecast: Forecast | null) {
    super(null, { isExpandedChildren: true });

    makeObservable<ReservesDepletion, "init" | "initStore">(this, {
      data: observable,
      isValid: computed,
      init: action,
      initStore: action,
    });

    when(
      () => this.fact.producingObjects.isLoading === false && this.params.isLoading === false,
      () => this.init()
    );
  }

  public get params(): ProducingObjectsParams {
    return (this.forecast ?? this.fact).producingObjectsParams;
  }

  public get isValid(): boolean {
    if (!this.data) {
      return false;
    }
    for (const prodObjParams of Object.values(this.data)) {
      for (const lrParams of Object.values(prodObjParams)) {
        for (const paramValues of Object.values(lrParams)) {
          if (paramValues.includes(null)) {
            return false;
          }
        }
      }
    }
    return this.oiz?.isValid ?? true;
  }

  private async init() {
    this.licenseRegions = this.fact.licenseRegions.data;
    this.producingObjects = [...this.fact.producingObjects.values!];

    this.initStore();

    this.oiz = new OIZ(this);
    await this.oiz.init();
    this.childrenStore = new ChildrenStoreArray(this, [
      new ProdObjectsGeneric(this, { key: "reserves", title: "НИЗ", measure: new UOM(5, 5, global.uomResolver) }),
      this.oiz,
    ]);
  }

  private initStore() {
    this.data = {};
    if (!this.producingObjects || !this.licenseRegions || this.params.isLoading) {
      return;
    }
    const paramsRange = this.params.paramsRange;

    for (const prodObj of this.producingObjects) {
      const prodObjData: ParamsStore<ParamKeys>[number] = {};
      for (const lr of this.licenseRegions) {
        prodObjData[lr.id] = {
          reserves:
            this.params.licenseRegionsParams?.[prodObj.id]?.[lr.id]?.reserves.slice() ?? paramsRange.array.fill(null),
        };
      }
      this.data[prodObj.id] = prodObjData;
    }
  }

  public submit = async () => {
    if (!this.data || !this.isValid) {
      return;
    }
    await this.params.updateLRParams(this.data as ParamsStoreValidated<ParamKeys>);
    await this.oiz?.submit();
    this.mutationsManager?.dropMutations();
    this.init();
  };
}

export type { DRow, ParamKeys, ParamsStore };
export { ReservesDepletion };
