import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, ObservableMap } from "mobx";
import { makePersistable } from "mobx-persist-store";

import { WellTechChart } from "features/techForecast/models/well/wellTechChart";
import { WellTechProduction } from "services/back/techForecast/request";
import { Production, WellFactProduction } from "services/back/techForecast/techForecast";

type DRow = {
  date: Dayjs;
  [metricKey: string]: number | string | null | Dayjs;
};

class ResultTableStore extends TableNode<DRow, Datum> {
  public readonly root = this;
  public selectedAxes: ObservableMap<string, string> = observable.map();

  constructor(public readonly store: WellTechProduction | WellFactProduction) {
    super();

    makeObservable(this, {
      selectedAxes: observable,
      setSelectedAxes: action,
      isChangedSelectedAxes: computed,
    });

    makePersistable(this, {
      name: "well_tech_forecast_result_table_settings",
      properties: [
        {
          key: "selectedAxes",
          serialize: (v) => JSON.stringify([...v.entries()]),
          deserialize: (v) => observable.map<string, string>(JSON.parse(v)),
        },
      ],
      storage: window.localStorage,
    });

    const rows: DRow[] = this.createRows(store?.factProduction, store?.forecastProduction);

    this.childrenStore = new ChildrenStoreArray(
      this,
      rows.map((row) => new Datum(this, row))
    );
  }

  public get isChangedSelectedAxes() {
    return [...this.selectedAxes.values()];
  }

  createRows(factData?: Production, forecastData?: Production): DRow[] {
    const combinedRows: DRow[] = [];

    [factData, forecastData].forEach((data) => {
      if (data) {
        this.processData(data, combinedRows);
      }
    });

    return combinedRows;
  }

  public setSelectedAxes = (k: string, v: string) => {
    this.selectedAxes.set(k, v);
  };

  private processData(data: any, combinedRows: DRow[]): void {
    const steps = data.steps;

    steps.forEach((step: number, index: number) => {
      const date = WellTechChart.stepToDayJS(step);
      const row: DRow = { date };

      Object.entries(data).forEach(([metricKey]) => {
        const value = (Array.isArray(data[metricKey]) && data[metricKey][index]) ?? null;
        row[metricKey] = value;
      });

      combinedRows.push(row);
    });
  }
}

class Datum extends TableNode<DRow, Datum> {
  public get root(): ResultTableStore {
    return this.parent.root;
  }

  constructor(private readonly parent: Datum | ResultTableStore, private readonly row: DRow) {
    super(parent, { isExpandedChildren: true });
  }

  asDRow = (): DRow => this.row;
}

export { ResultTableStore };
