import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 6.5H5L5 18H19V6.5ZM5 4.5C3.89543 4.5 3 5.39543 3 6.5V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V6.5C21 5.39543 20.1046 4.5 19 4.5H5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 2.5C9.05228 2.5 9.5 2.94772 9.5 3.5V6.5C9.5 7.05228 9.05228 7.5 8.5 7.5C7.94772 7.5 7.5 7.05228 7.5 6.5L7.5 3.5C7.5 2.94772 7.94772 2.5 8.5 2.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 11C3 10.4477 3.44772 10 4 10H20C20.5523 10 21 10.4477 21 11C21 11.5523 20.5523 12 20 12H4C3.44772 12 3 11.5523 3 11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 2.5C16.0523 2.5 16.5 2.94772 16.5 3.5V6.5C16.5 7.05228 16.0523 7.5 15.5 7.5C14.9477 7.5 14.5 7.05228 14.5 6.5V3.5C14.5 2.94772 14.9477 2.5 15.5 2.5Z"
      fill="currentColor"
    />
  </g>
);

const RkpIcon: FC<SharedIconProps> = (props) => <Icon {...props} viewBox="0 0 24 24" content={ASSET} />;

export { RkpIcon };
