import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MailOutlined, RedditOutlined, UnorderedListOutlined, UserOutlined } from "@ant-design/icons";
import { Menu, MenuProps, Tooltip } from "antd";
import { useMainRouterParams } from "routing/authorizedRouter";

import { DebugZone } from "elements/debugZone/debugZone";
import { Icon } from "elements/icon/icon";
import { global } from "models/global";
import { getUserInfo } from "services/auth";
import { conditionallyArr, ifDebugZoneArr } from "utils/conditionally";

import { FactYearsToggler } from "../factYearsToggler/factYearsToggler";

import { useAboutModal } from "./aboutModal/aboutModal";
import { ReactComponent as AboutIcon } from "./bottomIcons/about.svg";
import { ReactComponent as LogIcon } from "./bottomIcons/log.svg";
import { ReactComponent as LogoutIcon } from "./bottomIcons/logout.svg";
import { ReactComponent as ProjectsIcon } from "./bottomIcons/projects.svg";
import { ReactComponent as SettingsIcon } from "./bottomIcons/settings.svg";
import { ReactComponent as UserIcon } from "./bottomIcons/user.svg";
import { ReactComponent as VersionFem } from "./bottomIcons/versionFem.svg";
import { ModeSwitch } from "./modeSwitch/modeSwitch";

import cn from "./modeMenu.module.less";

const Label: FC<{ title: string } & PropsWithChildren> = ({ title, children }) => (
  <Tooltip placement="right" title={title}>
    <Icon content={children} />
  </Tooltip>
);

const useMenu = (isAdmin: boolean): MenuProps["items"] => [
  {
    key: "toggler",
    icon: <FactYearsToggler />,
  },
  {
    key: "/",
    icon: (
      <Label title="Дашборд проектов">
        <ProjectsIcon />
      </Label>
    ),
  },
  {
    key: "log",
    disabled: true,
    icon: (
      <Label title="Журнал изменений">
        <LogIcon />
      </Label>
    ),
  },
  {
    key: "submenu",
    icon: (
      <Label title="">
        <SettingsIcon />
      </Label>
    ),
    children: [
      {
        key: "setup",
        icon: <Icon width="16px" height="16px" content={<SettingsIcon />} />,
        label: "Настройки системы",
      },
      {
        key: "user",
        icon: <Icon width="16px" height="16px" content={<UserIcon />} />,
        label: "Профиль",
        disabled: true,
      },
      {
        key: "about",
        icon: <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<AboutIcon />} />,
        label: "О программе",
      },
      {
        key: "support",
        icon: <MailOutlined />,
        label: <a href="mailto:iprm_tehpod@lukoil.com">Обратиться в тех. поддержку</a>,
      },
      {
        key: "users",
        icon: <UserOutlined />,
        label: "Пользователи системы",
      },
      ...conditionallyArr(isAdmin, {
        key: "dashboard",
        icon: <UnorderedListOutlined />,
        label: "Логирование действий",
      }),
      ...ifDebugZoneArr({
        key: "table",
        icon: <RedditOutlined />,
        label: <DebugZone>Отладочная таблица</DebugZone>,
      }),
      {
        key: "versionFem",
        icon: <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<VersionFem />} />,
        label: "Версии ФЭМ",
      },
      {
        key: "logout",
        icon: <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<LogoutIcon />} />,
        label: "Выйти",
        onClick: global.logout,
        danger: true,
      },
    ],
  },
];

const useCurrentKey = () => {
  const { pathname } = useLocation();
  let expectedKey = pathname.split("/")[2] ?? "/";
  if (expectedKey !== "about") {
    return expectedKey ?? "";
  }
  return "";
};

const ModesMenu = () => {
  const { project } = useMainRouterParams();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [isAdminRole, setIsAdminRole] = useState<boolean>(false);

  const navigate = useNavigate();
  const aboutModal = useAboutModal();
  const currentKey = useCurrentKey();

  useEffect(() => {
    setSelectedKeys([currentKey]);
  }, [currentKey]);

  useEffect(() => {
    getUserInfo().then((userInfo) => {
      const isAdmin = userInfo.roles.some((role) => role === "Администратор системы");
      setIsAdminRole(isAdmin);
    });
  }, []);

  const handleClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "toggler") return;

    if (!(key === "about" || key === "logout" || key === "support")) {
      navigate(key);
      setSelectedKeys([key]);
    }
    key === "about" && aboutModal();
  };

  return (
    <div className={cn.header} id="menuId">
      <div className={cn.tophead}>{project && <ModeSwitch className={cn.button} />}</div>
      <div className={cn.bottomhead}>
        <Menu items={useMenu(isAdminRole)} onClick={handleClick} selectedKeys={selectedKeys} triggerSubMenuAction="click" mode="vertical" />
      </div>
    </div>
  );
};

export { ModesMenu };
