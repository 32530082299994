import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { ScenarioLog } from "features/scenariosList/scenarioLog/scenarioLog";
import { Scenarios as ScenariosFeature } from "features/scenariosList/scenarios";
import { global } from "models/global";
import { useProject } from "models/project/project";

const useForecasts = () => useProject()?.fact?.forecasts;

const TABS: TabItem[] = [
  {
    key: "compareScenario",
    label: "Перечень сценариев",
    children: <ScenariosFeature />,
  },
  {
    key: "factorAnalysis",
    label: "Журнал действий",
    default: true,
    children: <ScenarioLog />,
  },
];

const Scenarios = () => (
  <Preloader hooks={[useForecasts, useProject, () => global.logger]}>
    <PageFrame title="Перечень сценариев" tabs={TABS}>
      <ScenariosFeature />
    </PageFrame>
  </Preloader>
);

export { Scenarios };
