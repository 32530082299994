import { action, computed, makeObservable, observable, runInAction } from "mobx";

type Storable = {
  id: number;
  title: string;
};

class LoadableStore<T extends Storable, Raw = T> {
  private data?: Array<T>;

  constructor(protected getData: () => Promise<T[]>, protected setData?: (dataRaw: Raw[]) => Promise<T[]>) {
    makeObservable<LoadableStore<T, Raw>, "data">(this, {
      data: observable,
      isLoading: computed,
      selector: computed,
      add: action,
      size: computed,
      length: computed,
      first: computed,
      last: computed,
    });

    getData().then((data) => {
      runInAction(() => {
        this.data = data;
      });
    });
  }

  public async reset(data?: Raw[]) {
    let result: T[];
    if (data === undefined || this.setData === undefined) {
      result = await this.getData();
    } else {
      result = await this.setData(data);
    }
    runInAction(() => {
      this.data = result;
    });
  }

  public get isLoading(): boolean {
    return this.data === undefined;
  }

  public get entries(): IterableIterator<[index: number, item: T]> | undefined {
    return this.data?.entries();
  }

  public get values(): IterableIterator<T> | undefined {
    return this.data?.values();
  }

  public map<R>(callback: (entry: T) => R): R[] | undefined {
    if (this.data === undefined) {
      return undefined;
    }
    return Array.from(this.values!, callback);
  }

  public find(predicate: (entry: T) => boolean): T | null | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return this.data!.find(predicate) ?? null;
  }

  public findIndex(predicate: (entry: T) => boolean): number | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return this.data!.findIndex(predicate);
  }

  public get size(): number | undefined {
    return this.data?.length;
  }

  public get length(): number | undefined {
    return this.size;
  }

  public get selector(): Array<{ value: number; label: string }> | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return Array.from(this.values!, ({ id, title }) => ({ value: id, label: title }));
  }

  public get mapIds(): Record<number, T> | undefined {
    if (this.isLoading) {
      return undefined;
    }
    const res: Record<number, T> = {};
    for (const value of Array.from(this.values!)) {
      res[value.id] = value;
    }
    return res;
  }

  get last(): T | undefined {
    return this.data?.at(-1);
  }

  get first(): T | undefined {
    return this.data?.[0];
  }

  add(entry: T) {
    console.assert(this.data !== undefined, "Добавление элемента до окончания загрузки стора не имеет эффекта");
    this.data?.push(entry);
  }

  public has(id: number): boolean | undefined {
    if (this.data === undefined) {
      return undefined;
    }
    return this.at(id) !== null;
  }

  public at(id: number): T | null | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return this.data!.find((v) => v.id === id) ?? null;
  }

  public insert(v: T, pos: number) {
    console.assert(this.isLoading === false, "не поддерживается вставка до загрузки");
    this.data!.splice(pos, 0, v);
  }

  public delete(id: number) {
    if (this.isLoading || !this.data) {
      console.error("Попытка удалить элемент во время загрузки");
      return false;
    }
    if (!this.has(id)) {
      console.error("Попытка удалить не существующий элемент");
      return false;
    }
    this.data = this.data.filter((v) => v.id !== id);
    return true;
  }
}

export type { Storable };
export { LoadableStore };
