import { ACTION_STATUS, StatusActionType, Statuses, StatusSections } from "features/scenariosList/statusCard";

import { backendStorage } from "./backendStorage";

type ScenarioStatusInfo = {
  status: Statuses;
  userId?: number;
};

const setScenarioStatus = async (
  projectId: number,
  scenarioId: number,
  action: StatusActionType,
  userId: number,
  section?: StatusSections
): Promise<ScenarioStatusInfo | null> =>
  backendStorage.setItem<ScenarioStatusInfo>(
    { status: ACTION_STATUS[action], userId },
    `status/${section ?? ""}`,
    projectId,
    scenarioId,
    true,
    { action }
  );

const getScenarioStatus = async (
  projectId: number,
  scenarioId: number,
  section?: StatusSections
): Promise<ScenarioStatusInfo | null> =>
  backendStorage.getItem<ScenarioStatusInfo>(`status/${section ?? ""}`, projectId, scenarioId);

export { getScenarioStatus, setScenarioStatus };
