import { Statuses, StatusSections } from "features/scenariosList/statusCard";
import { global } from "models/global";
import { Forecast } from "models/project/fact/forecast/forecast";
import { Project } from "models/project/project";
import { reqCamel } from "utils/request";

import { reqLogged } from "./logger";

type Role = {
  title: string;
  id: number;
  userIds: number[];
  projectIds: number[];
};

type ProjectRole = Omit<Role, "projectIds">;

type AssignRole = {
  roleId: number;
  projectId: number;
  userId: number;
};

type AssignFunctionalRole = {
  roleId: number;
  userId: number;
};

type PermissionInfo = {
  value: boolean;
  customTooltip?: string;
};

type Permissions = Record<Sections, PermissionInfo>;
type Sections =
  | "tech"
  | "economic"
  | "ranking"
  | "infrastructure"
  | "users"
  | "license"
  | "producingObject"
  | "prediction" // страница prediction/settings
  | "wellsReport"
  | "general";

//Администратор системы - 1, Руководитель проекта - 2
const ADMIN_ROLES_IDS = [1, 2];

const ROLES_PERMISSIONS: Record<number, Permissions> = {
  //Администратор системы
  1: {
    tech: {
      value: false,
    },
    economic: {
      value: false,
    },
    ranking: {
      value: false,
    },
    infrastructure: {
      value: false,
    },
    users: {
      value: true,
    },
    license: {
      value: false,
    },
    producingObject: {
      value: false,
    },
    prediction: {
      value: false,
    },
    wellsReport: {
      value: false,
    },
    general: {
      value: false,
    },
  },
  //Руководитель проекта
  2: {
    tech: {
      value: true,
    },
    economic: {
      value: true,
    },
    ranking: {
      value: true,
    },
    infrastructure: {
      value: true,
    },
    users: {
      value: true,
    },
    license: {
      value: true,
    },
    producingObject: {
      value: true,
    },
    prediction: {
      value: true,
    },
    wellsReport: {
      value: true,
    },
    general: {
      value: true,
    },
  },
  //Специалист по разработке месторождений
  3: {
    tech: {
      value: true,
    },
    economic: {
      value: false,
    },
    ranking: {
      value: true,
    },
    infrastructure: {
      value: false,
    },
    users: {
      value: false,
    },
    license: {
      value: false,
    },
    producingObject: {
      value: true,
    },
    prediction: {
      value: true,
    },
    wellsReport: {
      value: true,
    },
    general: {
      value: false,
    },
  },
  //Специалист по инфраструктуре
  4: {
    tech: {
      value: false,
    },
    economic: {
      value: false,
    },
    ranking: {
      value: false,
    },
    infrastructure: {
      value: true,
    },
    users: {
      value: false,
    },
    license: {
      value: false,
    },
    producingObject: {
      value: false,
    },
    prediction: {
      value: false,
    },
    wellsReport: {
      value: false,
    },
    general: {
      value: false,
    },
  },
  //Специалист по экономике
  5: {
    tech: {
      value: false,
    },
    economic: {
      value: true,
    },
    ranking: {
      value: true,
    },
    infrastructure: {
      value: false,
    },
    users: {
      value: false,
    },
    license: {
      value: false,
    },
    producingObject: {
      value: false,
    },
    prediction: {
      value: false,
    },
    wellsReport: {
      value: true,
    },
    general: {
      value: true,
    },
  },
  //Эксперт по разработке месторождений
  6: {
    tech: {
      value: false,
    },
    economic: {
      value: false,
    },
    ranking: {
      value: false,
    },
    infrastructure: {
      value: false,
    },
    users: {
      value: false,
    },
    license: {
      value: false,
    },
    producingObject: {
      value: false,
    },
    prediction: {
      value: false,
    },
    wellsReport: {
      value: false,
    },
    general: {
      value: false,
    },
  },
  //Эксперт по инфраструктуре
  7: {
    tech: {
      value: false,
    },
    economic: {
      value: false,
    },
    ranking: {
      value: false,
    },
    infrastructure: {
      value: false,
    },
    users: {
      value: false,
    },
    license: {
      value: false,
    },
    producingObject: {
      value: false,
    },
    prediction: {
      value: false,
    },
    wellsReport: {
      value: false,
    },
    general: {
      value: false,
    },
  },
  //Эксперт по экономике
  8: {
    tech: {
      value: false,
    },
    economic: {
      value: false,
    },
    ranking: {
      value: false,
    },
    infrastructure: {
      value: false,
    },
    users: {
      value: false,
    },
    license: {
      value: false,
    },
    producingObject: {
      value: false,
    },
    prediction: {
      value: false,
    },
    wellsReport: {
      value: false,
    },
    general: {
      value: false,
    },
  },
  //Куратор проектов
  9: {
    tech: {
      value: false,
    },
    economic: {
      value: false,
    },
    ranking: {
      value: false,
    },
    infrastructure: {
      value: false,
    },
    users: {
      value: false,
    },
    license: {
      value: false,
    },
    producingObject: {
      value: false,
    },
    prediction: {
      value: false,
    },
    wellsReport: {
      value: false,
    },
    general: {
      value: false,
    },
  },
};

const STATUS_SECTIONS: StatusSections[] = ["tech", "economic", "infrastructure", "ranking"];

const getUserPermission = (project: Project, forecast?: Forecast | null): Permissions => {
  if (window.location.host.endsWith("develop.iprm.online") || process.env.REACT_APP_ADMIN) {
    return {
      license: {
        value: true,
      },
      producingObject: {
        value: true,
      },
      tech: {
        value: true,
      },
      economic: {
        value: true,
      },
      ranking: {
        value: true,
      },
      infrastructure: {
        value: true,
      },
      users: {
        value: true,
      },
      prediction: {
        value: true,
      },
      wellsReport: {
        value: true,
      },
      general: {
        value: true,
      },
    };
  }
  const userRoles = project.participants.getById(global.user?.id ?? 0).roles;

  const res: Permissions = {
    tech: {
      value: false,
    },
    economic: {
      value: false,
    },
    ranking: {
      value: false,
    },
    infrastructure: {
      value: false,
    },
    users: {
      value: false,
    },
    license: {
      value: false,
    },
    producingObject: {
      value: false,
    },
    prediction: {
      value: false,
    },
    wellsReport: {
      value: false,
    },
    general: {
      value: false,
    },
  };
  for (const role of userRoles) {
    Object.entries(ROLES_PERMISSIONS[role.id]).forEach(([section, permission]) => {
      const sSection = STATUS_SECTIONS.find((sec) => section === sec);
      const status = sSection ? forecast?.getScenarioStatus(sSection) : undefined;

      if (status?.status && (["onApproval", "approved"] as Statuses[]).includes(status.status as Statuses)) {
        res[section as Sections] = {
          value: false,
          customTooltip:
            status.status === "onApproval"
              ? "Невозможно редактировать. Секция на согласовании"
              : "Невозможно редактировать. Секция согласована",
        };
      } else if (permission.value) {
        res[section as Sections] = { value: true };
      }
    });
  }
  return res;
};

const canManageRoles = async (projectId: number) => {
  const currentUserId = global.user?.id;
  if (!currentUserId) {
    return false;
  }
  const adminProjectRoles = (await getProjectRoles(projectId)).filter(({ id }) => ADMIN_ROLES_IDS.includes(id));
  for (const { userIds } of adminProjectRoles) {
    if (userIds.includes(currentUserId)) {
      return true;
    }
  }
  const adminRole = global.roles.find(({ id }) => id === 1)!;
  if (adminRole.userIds.includes(currentUserId)) {
    return true;
  }
  return false;
};

const isAdminRole = () => {
  const currentUserRoles = global.user?.roles;
  if (!currentUserRoles) {
    return false;
  }

  return currentUserRoles.some((role) => role === "Администратор системы");
};

const getRoles = async (): Promise<Role[]> => reqCamel.get<Role[]>("roles");

const getProjectRoles = async (projectId: number): Promise<ProjectRole[]> =>
  reqCamel.get<ProjectRole[]>(`roles/projects/${projectId}`);

const assignRole = async (roleId: number, projectId: number, userId: number) => {
  if (await canManageRoles(projectId)) {
    return await reqLogged.post<AssignRole>(
      "roles/assign_role",
      { roleId, projectId, userId },
      undefined,
      JSON.stringify({ roleId, projectId, userId })
    );
  }
  console.warn("Недостаточно прав для назначения ролей");
};

const assignFunctionalRole = (roleId: number, userId: number) =>
  reqLogged.post<AssignFunctionalRole>(
    "roles/assign_role",
    { roleId, userId },
    undefined,
    JSON.stringify({ type: "functional", roleId, userId })
  );

const deleteFunctionalRole = (roleId: number, userId: number) =>
  reqLogged.delete<AssignFunctionalRole>(
    "roles/users",
    { roleId, userId },
    undefined,
    JSON.stringify({ type: "functional", roleId, userId })
  );

const deleteRole = async (roleId: number, projectId: number, userId: number, needLog = true) => {
  if ((await canManageRoles(projectId)) && roleId !== 1) {
    if (!needLog) {
      await global.logger.addNote("delete-user");
      return await reqLogged.delete<AssignRole>(
        "roles/users",
        { roleId, projectId, userId },
        undefined,
        JSON.stringify({ roleId, projectId, userId })
      );
    } else {
      return await reqCamel.delete<AssignRole>("roles/users", { roleId, projectId, userId });
    }
  }
  console.warn("Недостаточно прав для удаления ролей");
};

const deleteUser = (roleIds: number[], projectId: number, userId: number) =>
  Promise.all(roleIds.map((roleId) => deleteRole(roleId, projectId, userId, false)));

export {
  ADMIN_ROLES_IDS,
  assignFunctionalRole,
  assignRole,
  deleteFunctionalRole,
  deleteRole,
  deleteUser,
  getProjectRoles,
  getRoles,
  getUserPermission,
  isAdminRole,
  ROLES_PERMISSIONS,
};
export type { AssignFunctionalRole, AssignRole, PermissionInfo, ProjectRole, Sections };
