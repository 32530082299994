import { action, computed, makeObservable, observable, runInAction } from "mobx";

import { getGTMProductions, getProduction, getProductions, ProductionFields } from "services/back/production";

import { Fact } from "../fact";
import { Forecast } from "../forecast/forecast";

import { aggregateByDate, DateDataSeries } from "./aggregate";
import { sumUp } from "./aggregateFunctions";
import { ProductionDataPack } from "./productionData";

type ProductionDatum = Record<ProductionFields, number | null>;

type ProductionDataFilter = {
  base: boolean;
  new: boolean;
  gtmTypeIds: number[];
};

class Production {
  private readonly wellProduction = observable.map<number, ProductionDataPack>();
  private readonly interventionProduction = observable.map<number, ProductionDataPack>();

  private isUpdating: boolean = false;
  private isLoadingWellsProduciton: boolean = true;
  private isLoadingInterventionsProduction: boolean;

  constructor(public readonly fact: Fact, public readonly forecast: Forecast | null = null) {
    makeObservable<Production, "isUpdating" | "isLoadingWellsProduciton" | "isLoadingInterventionsProduction">(this, {
      isUpdating: observable,
      isLoadingWellsProduciton: observable,
      isLoadingInterventionsProduction: observable,
      isLoading: computed,
      update: action,
    });

    this.isLoadingInterventionsProduction = this.forecast !== null;

    getProductions(fact.projectId, forecast?.id)
      .then((data) => {
        if (data === null) return;
        this.wellProduction.replace(data.map((d) => [d.wellId, new ProductionDataPack(d)]));
      })
      .then(() => runInAction(() => (this.isLoadingWellsProduciton = false)));

    if (this.forecast !== null) {
      getGTMProductions(this.forecast.id)
        .then((data) => {
          if (data === null) return;
          this.interventionProduction.replace(data.map((d) => [d.gtmId!, new ProductionDataPack(d)]));
        })
        .then(() => runInAction(() => (this.isLoadingInterventionsProduction = false)));
    }
  }

  public get isLoading(): boolean {
    return this.isLoadingWellsProduciton || this.isLoadingInterventionsProduction || this.isUpdating;
  }

  public wellStatus(wellId: number): { isMining: boolean; isInjecting: boolean } | undefined {
    const factData = this.fact.production.wellProduction.get(wellId);
    const forecastData = this.forecast?.production.wellProduction.get(wellId);
    if (!factData && !forecastData) {
      return undefined;
    }
    const isMining = factData?.isMining || forecastData?.isMining || false;
    const isInjecting = factData?.isInjecting || forecastData?.isInjecting || false;
    return { isMining, isInjecting };
  }

  public wellData(wellId: number): ProductionDataPack | undefined {
    return this.wellProduction.get(wellId);
  }

  public interventionData(gtmId: number): ProductionDataPack | undefined {
    return this.interventionProduction.get(gtmId);
  }

  public get(wellId: number, gtmId?: number): ProductionDataPack | undefined {
    return gtmId === undefined ? this.wellData(wellId) : this.interventionData(gtmId);
  }

  public wholeWellData(
    wellId: number,
    byDecember: boolean = false,
    producingObjectsIds?: number[],
    filter?: ProductionDataFilter
  ): DateDataSeries<ProductionDatum> {
    const producingObjects = producingObjectsIds?.map((id) => this.fact.producingObjects.at(id));
    const stratumIds = producingObjects && [...new Set(producingObjects.flatMap((po) => po?.data.stratumIds ?? []))];
    let factData = this.fact.production.wellProduction.get(wellId);
    let forecastData = this.forecast?.production.wellProduction.get(wellId);
    const interventions = this.forecast?.interventions.getInterventionsByWellId(wellId);

    if (filter !== undefined) {
      const well = (this.forecast ?? this.fact).wells.at(wellId);
      console.assert(well !== null, "Запрос данных добычи по неизвестной скважине");
      const fond = well!.fond;
      if ((!filter.base && fond === "Base") || (!filter.new && fond === "New")) {
        factData = undefined;
        forecastData = undefined;
      }
    }

    const selector = byDecember ? "byDecember" : "byYear";

    const toMerge = [];
    if (factData !== undefined) {
      toMerge.push(factData[selector](stratumIds));
    }
    if (forecastData !== undefined) {
      toMerge.push(forecastData[selector](stratumIds));
    }
    interventions?.forEach(({ id, data }) => {
      if (filter !== undefined) {
        if (data.gtmTypeId !== null && !filter.gtmTypeIds.includes(data.gtmTypeId)) {
          return;
        }
      }
      const interventionData = this.forecast?.production.interventionData(id);
      if (interventionData !== undefined) {
        toMerge.push(interventionData[selector](stratumIds));
      }
    });

    return aggregateByDate(sumUp, toMerge);
  }

  public async update(ids: { wellId: number; gtmId?: number }[]) {
    if (this.forecast === null) {
      console.error("Перезапрос данных по добыче для факта");
      return;
    }
    this.isUpdating = true;
    ids.forEach(({ wellId, gtmId }) => {
      if (gtmId !== undefined) {
        this.interventionProduction.delete(gtmId);
      } else {
        this.wellProduction.delete(wellId);
      }
    });

    if (ids.length < 5) {
      await Promise.all(
        ids.map((id) =>
          getProduction(id, this.fact.projectId, this.forecast!.id).then((prod) => {
            if (prod === null) {
              return;
            }
            if (id.gtmId !== undefined) {
              this.interventionProduction.set(id.gtmId, new ProductionDataPack(prod));
            } else {
              this.wellProduction.set(id.wellId, new ProductionDataPack(prod));
            }
          })
        )
      );
    } else {
      await Promise.all([
        getProductions(this.fact.projectId, this.forecast.id).then((data) => {
          if (data === null) {
            return;
          }
          this.wellProduction.replace(data.map((d) => [d.wellId, new ProductionDataPack(d)]));
        }),
        getGTMProductions(this.forecast.id).then((data) => {
          if (data === null) return;
          this.interventionProduction.replace(data.map((d) => [d.gtmId!, new ProductionDataPack(d)]));
        }),
      ]);
    }

    this.isUpdating = false;
  }
}

export type { ProductionDatum };
export { Production };
