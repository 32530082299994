import { FC, ReactNode, useCallback, useMemo } from "react";
import { useMatches } from "react-router-dom";
import { ExclamationCircleOutlined, LaptopOutlined, UserOutlined } from "@ant-design/icons";
import { Widget } from "@okopok/components/Table";
import { Button, Input, Tooltip, UploadFile } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { debugColumnsForSimpleTable } from "elements/debugColumn/debugColumn";
import { EmptyScreen } from "elements/emptyScreen";
import { Format } from "elements/format/format";
import { FullScreen } from "elements/fullScreen/fullScreen";
import { Icon } from "elements/icon/icon";
import { Plus } from "elements/icons/plus";
import { type FallbackType, useFallBack } from "elements/importModalContent/useFallBack";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ModeSelector } from "elements/modeSelector/modeSelector";
import { ModeSelectorModel, TechMode } from "elements/modeSelector/modeSelectorModel";
import { ToolbarButton } from "elements/toolbarButton/toolbarButton";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { useProjectContext } from "models/project/context/projectContext";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import type { DRow } from "models/project/fact/well/list/listFlat";
import { WellsList as WellsListModel } from "models/project/fact/well/list/listFlat";
import { Well } from "models/project/fact/well/well";
import { GenericTableRow } from "services/back/genericTable/genegicTableService";
import { conditionallyArr } from "utils/conditionally";

import { DeleteIcon } from "./icons/deleteIcon";
import { ReactComponent as UploadIcon } from "./icons/uploadIcon.svg";
import { useImportWellResourcesModal } from "./importWellResourcesModal";
import { useImportWellsModal } from "./importWellsModal";

import cn from "./wellsList.module.less";

const WellTitleWarningSuffix: FC<{ isPreview: boolean; rowData: DRow }> = ({ isPreview, rowData }) => {
  let tooltip: string | undefined;
  if (isPreview && typeof rowData?.wellId === "number") {
    tooltip = "Скважина уже существует в проекте. Данные не будут импортированы до её удаления";
  } else if (rowData.isDuplicatedWell) {
    tooltip = `Скважина с таким названием уже упоминается ранее. Данные не будут ${isPreview ? "импортированы" : "сохранены"}`;
  }
  return tooltip ? (
    <Tooltip title={tooltip}>
      <ExclamationCircleOutlined style={{ color: "#faad14" }} />
    </Tooltip>
  ) : null;
};

const useCustomRenders = (
  editable: boolean = false,
  isPreviewColumns: boolean = false
): Record<string, (value: any, row: GenericTableRow) => ReactNode> => {
  const forecast = useForecast()!;
  const fact = useFact()!;
  const wellPads = (forecast ?? fact).wellPads;

  return useMemo(
    () => ({
      isRankingResultDate: (value: boolean | null | undefined) => {
        if (value === null || value === undefined) {
          return value;
        }
        const icon = value ? <LaptopOutlined /> : <UserOutlined />;
        const tooltip = value ? "Вычислено в результате РКП" : "Определено пользователем";
        return <Tooltip title={tooltip}>{icon}</Tooltip>;
      },
      mine: (value: [id: number | null, searchValue: string | undefined] | undefined, row: GenericTableRow) => {
        if (!editable) {
          return value?.[0] !== null ? wellPads.at(value?.[0]!)?.title ?? null : null;
        }
        return (
          <SelectStorable
            values={value}
            notFoundContent={
              <Button
                block
                onClick={() => {
                  if (!value?.[1]) {
                    return;
                  }
                  const newPad = wellPads.createPad(value?.[1]);
                  newPad && row.update?.("mine", [newPad.id, newPad.title]);
                }}
              >
                Создать
              </Button>
            }
            store={wellPads}
            setValues={(id, title) => {
              row.update?.("mine", [id, title]);
              if (id) {
                const lzFinder = ({ licenseRegionId, mineId }: Well) => mineId === id && licenseRegionId !== null && licenseRegionId !== undefined;
                const lzSelected = fact.wells.wells.find(lzFinder) ?? forecast?.wells.wells.find(lzFinder);
                if (lzSelected) {
                  row.update?.("licenseZone", [lzSelected.licenseRegionId, lzSelected.licenseRegion?.title]);
                }
              }
            }}
          />
        );
      },
      title: (title, { update, expand, indexPath, value: wellData }) => {
        if (expand !== undefined || !editable || indexPath[0] === -1) {
          return <Format>{title}</Format>;
        }
        return (
          <Input
            value={title}
            onChange={(event) => update?.("title", event.currentTarget.value)}
            width="100%"
            variant={"borderless"}
            suffix={wellData && <WellTitleWarningSuffix isPreview={isPreviewColumns} rowData={wellData} />}
            allowClear={false}
          />
        );
      },
    }),
    [fact, forecast, wellPads, editable, isPreviewColumns]
  );
};

const useColumns = (editable: boolean = false, isPreviewColumns: boolean = false, modeModel: ModeSelectorModel | null = null) => {
  const fact = useFact()!;
  const getFallback = useFallBack();
  const forecast = useForecast()!;
  const wellPads = (forecast ?? fact).wellPads;

  const getTooltip = useCallback(
    (v: any, type?: FallbackType) => (isPreviewColumns ? getFallback(v, type).tooltip : ""),
    [isPreviewColumns, getFallback]
  );

  const isFallback = useCallback(
    (v: any, type?: FallbackType) => {
      if (!isPreviewColumns) return false;
      return getFallback(v, type).isFallback;
    },
    [isPreviewColumns, getFallback]
  );

  const disabledColumn = useCallback(
    (modeModel: ModeSelectorModel | null, mode: TechMode, value: any) => {
      if (isPreviewColumns) return false;
      return !!(mode === modeModel?.mode || typeof value?.producingObject?.[0] !== "number");
    },
    [isPreviewColumns]
  );

  return useMemo(
    (): Column[] => [
      ...debugColumnsForSimpleTable([
        {
          dataKey: "wellId",
          title: "ID",
          width: 50,
          hideFilter: true,
        },
      ]),
      {
        title: "Скважина",
        dataKey: "title",
        key: "title",
        isSticky: true,
        type: "string",
        width: { min: 250, max: 350, competitiveness: 1 },
        editable: editable,
      },
      {
        title: "Куст",
        dataKey: "mine",
        key: "mine",
        width: { min: 120, max: 180, competitiveness: 1 },
        editable: editable,
        hideFilter: true,
        type: "select",
        options: wellPads,
      },
      {
        title: "Объект разработки",
        dataKey: "producingObject",
        width: { min: 160, max: 300, competitiveness: 1 },
        type: "select",
        options: fact.producingObjects,
        editable: editable,
        hideFilter: true,
        onCell: ({ value }: any) => ({ className: classNames(isFallback(value.producingObject, "producingObject") && cn.fallBackBg) }),
        tooltip: ({ value }: any) => getTooltip(value.producingObject, "producingObject"),
      },
      ...conditionallyArr<Column>(!isPreviewColumns, {
        title: "Залежь",
        dataKey: "stratum",
        width: { min: 200, max: 300, competitiveness: 1 },
        type: "select",
        options: fact.stratums,
        hideFilter: true,
        onCell: () => ({ className: classNames(editable && cn.disabled) }),
      }),
      {
        title: "ЛУ",
        dataKey: "licenseZone",
        width: { min: 180, max: 300, competitiveness: 1 },
        type: "select",
        options: global.licenseRegions,
        hideFilter: true,
        editable: editable,
        onCell: () => ({ className: classNames(editable && cn.disabled) }),
      },
      {
        title: "Тип заканчивания",
        dataKey: "type",
        width: { min: 160, max: 250, competitiveness: 1 },
        type: "select",
        options: global.wellTypes,
        onCell: ({ value }: any) => ({ className: classNames(isFallback(value.type, "type") && cn.fallBackBg) }),
        tooltip: ({ value }: any) => getTooltip(value.type, "type"),
        editable: editable,
        hideFilter: true,
      },
      {
        title: `Устье X, м`,
        dataKey: "topX",
        width: 100,
        type: "number",
        editable: editable,
        hideFilter: true,
        tooltip: ({ value }: any) => getTooltip(value.topX),
        onCell: ({ value }: any) => ({ className: classNames(isFallback(value.topX) && cn.fallBackBg) }),
      },
      {
        title: `Устье Y, м`,
        dataKey: "topY",
        width: 100,
        type: "number",
        editable: editable,
        hideFilter: true,
        tooltip: ({ value }: any) => getTooltip(value.topY),
        onCell: ({ value }: any) => ({ className: classNames(isFallback(value.topY) && cn.fallBackBg) }),
      },
      {
        title: `Забой X, м`,
        dataKey: "botX",
        width: 100,
        type: "number",
        editable: editable,
        hideFilter: true,
        tooltip: ({ value }: any) => getTooltip(value.botX),
        onCell: ({ value }: any) => ({ className: classNames(isFallback(value.botX) && cn.fallBackBg) }),
      },
      {
        title: `Забой Y, м`,
        dataKey: "botY",
        width: 100,
        type: "number",
        editable: editable,
        hideFilter: true,
        tooltip: ({ value }: any) => getTooltip(value.botY),
        onCell: ({ value }: any) => ({ className: classNames(isFallback(value.botY) && cn.fallBackBg) }),
      },
      {
        title: "Стартовый дебит жид-ти, м³/сут",
        type: "number",
        dataKey: "liquidRate",
        width: { min: 160, max: 180, competitiveness: 1 },
        editable: ({ value }) => editable && !disabledColumn(modeModel, "liquidRate", value),
        hideFilter: true,
        onCell: ({ value }: any) => ({
          className: classNames({
            [cn.tableCell]: true,
            [cn.disabled]: disabledColumn(modeModel, "liquidRate", value),
            [cn.fallBackBg]: isFallback(value.liquidRate),
          }),
        }),
        onHeaderCell: () => ({ className: cn.tableHeader }),
        tooltip: ({ value }: any) => getTooltip(value.liquidRate),
      },
      {
        title: "Стартовый дебит нефти, т/сут",
        dataKey: "oilRate",
        type: "number",
        width: { min: 160, max: 180, competitiveness: 1 },
        editable: ({ value }) => editable && !disabledColumn(modeModel, "oilRate", value),
        hideFilter: true,
        onHeaderCell: () => ({ className: cn.tableHeader }),
        onCell: ({ value }: any) => ({
          className: classNames({
            [cn.tableCell]: true,
            [cn.disabled]: disabledColumn(modeModel, "oilRate", value),
            [cn.fallBackBg]: isFallback(value.oilRate),
          }),
        }),
        tooltip: ({ value }: any) => getTooltip(value.oilRate),
      },
      {
        title: !editable ? "ОИЗ, тыс т" : "Извлекаемые запасы, тыс т",
        dataKey: "recoverableResources",
        type: "number",
        editable: true,
        hideFilter: true,
        width: { min: 140, max: 160, competitiveness: 1 },
        onHeaderCell: () => ({ className: classNames(cn.tableHeader) }),
        onCell: ({ value }: any) => ({ className: classNames(cn.tableCell, isFallback(value.recoverableResources) && cn.fallBackBg) }),
        tooltip: ({ value }: any) => getTooltip(value.recoverableResources),
      },
      ...conditionallyArr<Column>(!isPreviewColumns, {
        title: "Обводненность, %",
        dataKey: "waterCut",
        type: "number",
        width: { min: 180, max: 200, competitiveness: 1 },
        editable: ({ value }) => editable && !disabledColumn(modeModel, "waterCut", value),
        hideFilter: true,
        onCell: ({ value }: any) => ({
          className: classNames({
            [cn.disabled]: disabledColumn(modeModel, "waterCut", value),
            [cn.fallBackBg]: isFallback(value.waterCut, "waterCut"),
          }),
        }),
        onHeaderCell: () => ({ className: classNames(cn.tableHeader) }),
        tooltip: ({ value }: any) => getTooltip(value.waterCut, "waterCut"),
      }),
      {
        title: "Проходка, м",
        dataKey: "md",
        width: 120,
        type: "number",
        editable: editable,
        hideFilter: true,
        onHeaderCell: () => ({ className: classNames(cn.tableHeader) }),
        onCell: ({ value }: any) => ({ className: classNames(isFallback(value.md) && cn.fallBackBg) }),
        tooltip: ({ value }: any) => getTooltip(value.md),
      },
      ...conditionallyArr<Column>(!isPreviewColumns && editable, {
        title: <div />,
        dataKey: "isRankingResultDate",
        key: "isRankingResultDate",
        width: 30,
        type: "boolean",
        exportTitle: "Дата выставлена в результате РКП",
        isExported: false,
      }),
      {
        title: "Дата ввода",
        dataKey: "date",
        width: 150,
        type: "date",
        renderFormat: "date",
        editable: editable,
        hideFilter: true,
        onCell: ({ value }: any) => ({ className: classNames(isFallback(value.date, "date") && cn.fallBackBg) }),
        tooltip: ({ value }: any) => getTooltip(value.date, "date"),
      },
    ],
    [editable, wellPads, fact.producingObjects, fact.stratums, isPreviewColumns, isFallback, getTooltip, disabledColumn, modeModel]
  );
};

const WellsList: FC = observer(() => {
  const fact = useFact()!;
  const forecast = useForecast();

  const importWellsModal = forecast ? useImportWellsModal() : undefined;
  // const importResourcesErrorModal = useImportResourcesErrorModal();

  const tab = useMatches().at(-1)!.pathname.split("/")[4] ?? "base";

  const projectContext = useProjectContext();
  const tree = projectContext.wellsTree;

  const modeModel = useMemo(() => new ModeSelectorModel(), []);
  const columns = useColumns(tab !== "base", false, modeModel);
  const customRenders = useCustomRenders(tab !== "base");

  const store = useMemo(
    () => (tab === "base" ? new WellsListModel(fact, null, modeModel, tree) : new WellsListModel(fact, forecast, modeModel)),
    [fact, tree, forecast, modeModel, tab]
  );

  const importWellResourcesModal = useImportWellResourcesModal(store);

  const handleUpload = (file: UploadFile) => {
    importWellsModal?.(file).then((data) => {
      if (data?.newRows && data.replace !== undefined) {
        store.importWells(data.newRows, data.replace);
      }
    });
  };

  const addExcelData = () => {
    importWellsModal?.(null).then((data) => {
      if (data?.newRows && data.replace !== undefined) {
        store.importWells(data.newRows, data.replace);
      }
    });
  };

  const addExcelResources = () => {
    importWellResourcesModal();
  };

  return (
    <SimpleTableContext
      selectable={tab !== "base"}
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className:
            indexPath[0] + 1 === 0
              ? cn.summaryRow
              : expand === undefined
              ? cn.tableRowPlain
              : indexPath.length === 1
              ? cn.tableRowPrimary
              : cn.tableRowSecondary,
        }),
      }}
      customRenders={customRenders}
      data={store}
      columns={columns}
      exportFileName={`Список скважин (${tab !== "base" ? "новый фонд" : "базовый фонд"})`}
    >
      <PageFrameTitlePortal model={store} onSave={store.submit} permissionSection="tech">
        {tab === "base" && (
          <>
            <Button onClick={addExcelResources} icon={<Plus />}>
              Импорт ОИЗ
            </Button>
          </>
        )}
        {tab !== "base" ? (
          <>
            <ModeSelector onUpdate={modeModel.onChangeMode} value={modeModel.mode} />
            <Button type="text" icon={<Plus />} onClick={() => store.addEmptyWell()} />
            {forecast && (
              <Tooltip title="Импортировать из excel-файла">
                <Button
                  data-testid="upload"
                  type="text"
                  loading={store.isLoading}
                  onClick={addExcelData}
                  icon={<Icon width="24" height="24" content={<UploadIcon />} viewBox="0 0 16 16" />}
                />
              </Tooltip>
            )}
            <ToolbarButton
              tooltip={{ title: "Удалить выбранные скважины" }}
              popconfirm={{
                title: "Удаление скважин нового фонда",
                description: "Подтвердите удаление скважин нового фонда из сценария",
                onConfirm: () => store.deleteSelected(),
                onCancel: () => "",
                okText: "Удалить",
                cancelText: "Отмена",
              }}
              type="text"
              disabled={!store.hasSelected}
              icon={<DeleteIcon width="24" height="24" modified={!store.hasSelected} />}
            />
          </>
        ) : null}
      </PageFrameTitlePortal>
      {!store.childrenStore?.length ? (
        tab === "base" ? (
          <FullScreen className={cn.notSelectedWells}>Не выбрана ни одна скважина для отображения</FullScreen>
        ) : (
          <EmptyScreen text="Загрузите список скважин" onUpload={handleUpload} />
        )
      ) : (
        <Widget headerClassName={cn.tableHeader} />
      )}
    </SimpleTableContext>
  );
});

export { useColumns, useCustomRenders, WellsList };
