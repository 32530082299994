import { BackendStorageMock } from "services/back/backendStorage";
import { formatSchema } from "services/back/utils";
import { Range } from "utils/range";
import { req } from "utils/request";

import type { MetricTree } from "./utils";
import { riseMetrics } from "./utils";

export type ECYAsSourceFormat = {
  id: number;
  title: string;
  usc: MetricTree[];
  discount: number;
};

type USCBack = {
  id: number;
  title: string;
  usc: any[];
  discount: number;
};

const getVersions = () => {
  return req.get<{ cpversion: number; cpversion_txt: string }[]>("esu/versions");
};

const getVariants = (projectId: number, versionId: number) => {
  return req.get<USCBack[]>(`esu/${projectId}?cpversion_id=${versionId}`);
};

const getEcy = async (projectId: number, range: Range): Promise<ECYAsSourceFormat[]> => {
  const versions = await getVersions();
  const data = await getVariants(projectId, versions[0].cpversion);

  const res = data.map((v, i) => ({ ...v, id: -(i + 1), usc: riseMetrics(formatSchema(v.usc.slice(1), range)) }));
  return res;
};

const ecySource = (key: { fact: number }) => {
  const [get, set] = new BackendStorageMock<[number, Range], ECYAsSourceFormat[], ECYAsSourceFormat[]>(
    "EcyParams2-",
    key.fact
  ).decorators;
  return [
    (r: Range) => get(getEcy)(key.fact, r),
    (
      f: {
        id: number;
        title: string;
        usc: MetricTree[];
        discount: number;
      }[]
    ) => set(async (r) => r)(f),
  ] as const;
};

export { ecySource };
