import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8333 4.5H3.16667C1.69391 4.5 0.5 5.7536 0.5 7.3V8.7C0.5 10.2464 1.69391 11.5 3.16667 11.5H13.8333C15.3061 11.5 16.5 10.2464 16.5 8.7V7.3C16.5 5.7536 15.3061 4.5 13.8333 4.5ZM1.83333 7.3C1.83333 6.5268 2.43029 5.9 3.16667 5.9H13.8333C14.5697 5.9 15.1667 6.5268 15.1667 7.3V8.7C15.1667 9.4732 14.5697 10.1 13.8333 10.1H3.16667C2.43029 10.1 1.83333 9.4732 1.83333 8.7V7.3ZM3.5 6.5C2.94772 6.5 2.5 6.94772 2.5 7.5V8.5C2.5 9.05228 2.94772 9.5 3.5 9.5H10.5C11.0523 9.5 11.5 9.05228 11.5 8.5V7.5C11.5 6.94772 11.0523 6.5 10.5 6.5H3.5Z"
      fill="currentColor"
    />
  </g>
);

const InProgress: FC<SharedIconProps> = (props) => <Icon {...props} viewBox="0 0 17 16" content={ASSET} />;

export { InProgress };
