import { FC, memo, MutableRefObject, PropsWithChildren } from "react";
import { useGradient, useMapContext } from "@okopok/axes_context";

type Coordinates =
  | {
      x1: number;
      y1: number;
      x2: number;
      y2: number;
    }
  | {
      from: { x: number; y: number };
      to: { x: number; y: number };
    };

type LinePublicProps = Omit<React.SVGProps<SVGLineElement>, "x1" | "x2" | "y1" | "y2" | "from" | "to" | "stroke">;

type Value =
  | {
      v1: number;
      v2: number;
    }
  | {
      stroke?: string;
    };

type GradientLineProps = Coordinates &
  Value & {
    childrenPos?: number;
    angleRef?: MutableRefObject<number | null>;
  } & PropsWithChildren &
  LinePublicProps;

function unpackCoordinates(props: Coordinates) {
  if ("from" in props) {
    return {
      x1: props.from.x,
      y1: props.from.y,
      x2: props.to.x,
      y2: props.to.y,
    };
  }
  return {
    x1: props.x1,
    y1: props.y1,
    x2: props.x2,
    y2: props.y2,
  };
}

const GradientLine: FC<GradientLineProps> = memo(({ children, childrenPos = 0, angleRef, ...props }) => {
  const { x1, y1, x2, y2 } = unpackCoordinates(props);
  const { id: gradientID, scale: colorScale } = useGradient();
  const domain = colorScale.domain();

  const {
    v1 = domain.at(0)!,
    v2 = domain.at(-1)!,
    stroke,
    ...lineProps
  } = props as {
    v1?: number;
    v2?: number;
    stroke?: string;
    childrenPos?: number;
    children?: React.ReactNode;
  } & LinePublicProps;

  const { scale } = useMapContext();
  const [ax, ay] = [scale.x(x1), scale.y(y1)];
  const [bx, by] = [scale.x(x2), scale.y(y2)];
  const [dx, dy] = [bx - ax, by - ay];

  const angle = Math.atan2(dy, dx) * (180 / Math.PI);
  if (angleRef) {
    angleRef.current = angle;
  }
  const isSingleColor =
    Math.abs(v2 - v2) < 1e-9 || (v1 < domain[0] && v2 < domain[0]) || (v1 > domain[domain.length - 1] && v2 > domain[domain.length - 1]);

  let finalStroke = stroke;
  if (!finalStroke) {
    if (isSingleColor) {
      finalStroke = colorScale(v1);
    } else {
      finalStroke = `url(#${gradientID})`;
    }
  }
  const ratio = childrenPos;
  const [cx, cy] = [ax + dx * ratio, ay + dy * ratio];
  const angleRad = Math.atan2(dy, dx);
  const angleDeg = (angleRad * 180) / Math.PI;

  return (
    <svg>
      <defs>
        {!isSingleColor && (
          <linearGradient id={gradientID} x1={ax} y1={ay} x2={bx} y2={by} gradientUnits="userSpaceOnUse">
            {domain.map((dVal, idx) => {
              const offset = (dVal - domain[0]) / (domain[domain.length - 1] - domain[0]);
              return <stop key={idx} offset={offset} stopColor={colorScale(dVal)} />;
            })}
          </linearGradient>
        )}
      </defs>
      <line {...lineProps} x1={ax} y1={ay} x2={bx} y2={by} stroke={finalStroke} />
      {children && <g transform={`translate(${cx}, ${cy}) rotate(${angleDeg})`}>{children}</g>}
    </svg>
  );
});

export { GradientLine };
