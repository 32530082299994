import { global } from "models/global";
import { Args, reqCamel } from "utils/request";

const reqLogged = {
  get: async <T extends object>(path: string, arg?: Args, additionalInfo?: string) => {
    try {
      const response = await reqCamel.get<T>(path, arg);
      try {
        await global.logger.addNote(`get:${path}${additionalInfo ? `::${additionalInfo}` : ""}`);
      } catch {}
      return response;
    } catch (error) {
      throw error;
    }
  },

  post: async <T extends object, B extends object = any>(
    path: string,
    body: B,
    arg?: Args,
    additionalInfo?: string
  ) => {
    try {
      const response = await reqCamel.post<T>(path, body, arg);
      try {
        await global.logger.addNote(`post:${path}${additionalInfo ? `::${additionalInfo}` : ""}`);
      } catch {}
      return response;
    } catch (error) {
      throw error;
    }
  },

  delete: async <T extends object>(path: string, body?: any, arg?: Args, additionalInfo?: string) => {
    try {
      const response = await reqCamel.delete<T>(path, body, arg);
      try {
        await global.logger.addNote(`delete:${path}${additionalInfo ? `::${additionalInfo}` : ""}`);
      } catch {}
      return response;
    } catch (error) {
      throw error;
    }
  },

  put: async <T extends object>(path: string, body: any, arg?: Args, additionalInfo?: string) => {
    try {
      const response = await reqCamel.put<T>(path, body, arg);
      try {
        await global.logger.addNote(`put:${path}${additionalInfo ? `::${additionalInfo}` : ""}`);
      } catch {}
      return response;
    } catch (error) {
      throw error;
    }
  },

  patch: async <T extends object>(path: string, body: any, arg?: Args, additionalInfo?: string) => {
    try {
      const response = await reqCamel.patch<T>(path, body, arg);
      try {
        await global.logger.addNote(`patch:${path}${additionalInfo ? `::${additionalInfo}` : ""}`);
      } catch {}
      return response;
    } catch (error) {
      throw error;
    }
  },

  args: reqCamel.args,
};

export { reqLogged };
