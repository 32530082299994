import { useCallback, useEffect, useState } from "react";

const usePersistableState = <T>(name: string, defaultValue: T): [value: T, updateValue: (v: T) => void] => {
  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    const localValue = localStorage.getItem(name);
    if (!localValue) return;
    try {
      const parseValue = JSON.parse(localValue) as T;
      setValue(parseValue);
    } catch (error) {
      console.error("Ошибка парсинга данных из localStorage:", error);
    }
  }, [name]);

  const updateValue = useCallback(
    (v: T) => {
      setValue(v);
      localStorage.setItem(name, JSON.stringify(v));
    },
    [name]
  );

  return [value, updateValue];
};

export { usePersistableState };
