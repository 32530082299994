import { saveAs } from "file-saver";

import type { Forecast } from "models/project/fact/forecast/forecast";
import { Metric } from "services/finance/utils";
import { Range } from "utils/range";

import { CALCULATE_PREDEFINE } from "./calculatePredefine";

const replace = <T extends {}>(obj: T, path: string, value: any): T => {
  const result = JSON.parse(JSON.stringify(obj));
  const pathElements = path.split("/");
  let cur = result;

  for (const key of pathElements.slice(0, -1)) {
    console.assert(key in cur, "Ошибка подмены значений в шаблоне расчета");
    cur = cur[key];
  }
  cur[pathElements[pathElements.length - 1]] = value;

  return result;
};

type ChangedAnalyzeResult = { unchanged: string[]; changed: string[] };

const arrayType = (nullableVal: Array<any>, title: string): "boolean" | "number" | "null" => {
  const val = nullableVal.filter((v) => v !== null);
  if (val.length === 0) {
    return "null";
  }
  const type = typeof val[0];
  for (let i = 1; i < val.length; ++i) {
    if (val[i] === undefined) {
      val[i] = val[i - 1];
    }
    const v = val[i];
    console.assert(type === typeof v, `Неоднородный массив данных в запросе параметра ${title}`, val, type, typeof v);
  }
  console.assert(["boolean", "number"].includes(type), `Неизвестный тип данных параметра ${title}`);
  return type as "boolean" | "number";
};

const replaceYearsRange = (years: Range) => {
  let counter = { v: 0 };
  // меняем год прямо в моке, чтобы получить возможность менять года до того, как полностью подключим все данные
  const r = (node: any, path: string[]) => {
    for (const [key, value] of Object.entries(node)) {
      if (key === "deposit") {
        continue;
      }
      if (key === "schema" && Array.isArray(value)) {
        for (const param of value) {
          if ("values" in param && Array.isArray(param.values) && param.values.length > 1) {
            counter.v += 1;
            param.values =
              param.title === "Год"
                ? [...years]
                : years.array.fill(null).map(
                    {
                      number: (_: null, id: number) => param.values[id] ?? null,
                      boolean: (_: null, id: number) => param.values[id],
                      null: (v: null) => v,
                    }[arrayType(param.values, param.title)]
                  );
          }
        }
      } else if (typeof value === "object" && value !== null) {
        if (Array.isArray(value)) {
          for (const [index, item] of value.entries()) {
            r(item, [...path, `${index}`]);
          }
        } else {
          r(value, [...path, key]);
        }
      }
    }
  };
  r(CALCULATE_PREDEFINE, []);
};

const unchangedPaths = (initial: any): ChangedAnalyzeResult => {
  const result: ChangedAnalyzeResult = {
    changed: [],
    unchanged: [],
  };
  const r = (changed: any, pathStack: string[] = [], current: any = CALCULATE_PREDEFINE) => {
    if (Array.isArray(changed) || typeof changed !== "object") {
      return;
    }
    for (const [key, val] of Object.entries(changed)) {
      const subpath = [...pathStack, key];
      if (val === current[key]) {
        result.unchanged.push(subpath.join("/"));
      } else {
        result.changed.push(subpath.join("/"));
        r(val, subpath, current[key]);
      }
    }
  };
  r(initial);
  return result;
};

function fillValues(
  { factRange, range }: Forecast,
  stateMetric: { title: string; values?: null | (number | null)[] } | undefined,
  forecastMetric: { title: string; values?: null | (number | null)[] } | undefined,
  metric: any
) {
  const { visible, editable, title } = metric; // predefine metric
  const result = [];
  if (stateMetric !== undefined) {
    if (stateMetric.values === null || stateMetric.values === undefined) {
      throw new Error(`State metric no values: "${stateMetric.title}"`);
    }
    result.push(...stateMetric.values);
  } else {
    if (visible.column?.only_forecast !== true && editable.only_forecast !== true) {
      console.error(`State metric not presented: "${title}"`);
    }
    result.push(...factRange.array.fill(null));
  }
  if (forecastMetric !== undefined) {
    if (forecastMetric.values === null || forecastMetric.values === undefined) {
      throw new Error(`Forecast metric no values: "${forecastMetric.title}"`);
    }
    result.push(...forecastMetric.values);
  } else {
    if (visible.column?.only_fact !== true && editable.only_fact !== true) {
      console.error(`Forecast metric not presented: "${title}"`);
    }
    result.push(...range.array.fill(null));
  }
  return result;
}

const save = (data: any) => {
  const now = new Date().toLocaleString();
  const fileData = JSON.stringify(data, null, 2);
  const blob = new Blob([fileData], { type: "text/plain" });
  saveAs(
    blob,
    `iprm_calculation_request_dump_${now.replaceAll(".", "_").replaceAll(", ", "_").replaceAll(":", "_")}.json`
  );
};

type ParentedMetric = Metric & { parentItem: Metric | null };

const sophisticatedSearch = (
  parentsMap: Record<string, Record<string, string>>,
  metric:
    | (typeof CALCULATE_PREDEFINE)["constants"]["schema"][0]
    | (typeof CALCULATE_PREDEFINE)["lz"][number]["schema_operation"]["schema"][0]
) => {
  if (metric.title in parentsMap) {
    const parents = parentsMap[metric.title];
    return (item: ParentedMetric) =>
      item.title === metric.title && parents[item.parentItem!.title] === metric.code_title;
  }
  return (item: ParentedMetric) => item.title === metric.title;
};

const linkParent = (data: Metric[]): ParentedMetric[] => {
  const m = new Map<number | null, Metric>();
  const result: ParentedMetric[] = [];
  for (const metric of data) {
    const copy: ParentedMetric = { ...metric, parentItem: null };
    result.push(copy);
    m.set(copy.key, copy);
  }
  for (const item of result) {
    (item as ParentedMetric).parentItem = m.get(item.parent) ?? null;
  }
  return result;
};

export {
  fillValues,
  linkParent,
  type ParentedMetric,
  replace,
  replaceYearsRange,
  save,
  sophisticatedSearch,
  unchangedPaths,
};
