import { type FC, useCallback, useEffect, useState } from "react";
import Markdown from "react-markdown";
import { Outlet } from "react-router-dom";
import { Button, message } from "antd";

import { Loader } from "elements/loader";
import { Logo } from "elements/logo/logo";
import { ModalContextProvider, useModal } from "elements/modal/modal";
import { global } from "models/global";

import { ModesMenu } from "./modesMenu/modeMenu";

import cn from "./authorizedOutline.module.less";

const ReleaseNotes: FC = () => {
  const [text, setText] = useState<string | undefined | null>(undefined);
  const [title, ...rows] = text?.split("\n") ?? [];
  const version = title?.replace(/^[ ]?# /, "");
  useEffect(() => {
    fetch("/static/ReleaseInfo.md")
      .then(async (response) => {
        if (response.ok) {
          setText(await response.text());
        } else {
          setText(null);
        }
      })
      .catch(() => setText(null));
  }, []);
  const showModal = useModal();
  const showChangelog = useCallback(() => {
    showModal(
      () => (
        <div className={cn.markdown}>
          <Markdown>{rows.join("\n")}</Markdown>
        </div>
      ),
      null,
      version,
      undefined,
      null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, showModal]);
  if (text === null) {
    return <div />;
  }
  if (text === undefined) {
    return <Loader />;
  }
  return (
    <Button type="text" onClick={showChangelog}>
      {version}
    </Button>
  );
};

const AuthorizedOutline: FC = () => (
  <>
    <header>
      <Logo className={cn.logo} />
      <ReleaseNotes />
    </header>
    <div className={cn.content}>
      <ModesMenu />
      <div className={cn.layout}>
        <Outlet />
      </div>
    </div>
  </>
);

const AuthorizedPageWithModalContext = () => {
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    global.messageApi = messageApi;
    return () => {
      global.messageApi = null;
    };
  }, [messageApi]);
  return (
    <ModalContextProvider>
      {contextHolder}
      <AuthorizedOutline />
    </ModalContextProvider>
  );
};

export { AuthorizedPageWithModalContext as AuthorizedOutline };
