import { ReactNode } from "react";

import { reqCamel } from "utils/request";

import { backendStorage } from "../backendStorage";
import { reqLogged } from "../logger";

import type { Production, WellFactProduction } from "./techForecast";

type FN<T> = { fn: T };

type FNType<T> = FN<{ fnType: T }>;

type A<T> = FN<{
  fnType: T;
  a: number;
  k?: number;
}>;

type ForecastMode = "liquid" | "oil" | "waterCut";

const OIL_CURVES = ["linear", "geometric_progression", "exponential", "harmonic", "hyperbolic", "duong", "stretched_exponential"] as const;
type OilCurves = "linear" | "geometric_progression" | "exponential" | "harmonic" | "hyperbolic" | "duong" | "stretched_exponential";
const NEED_K = new Set(["duong", "stretched_exponential", "hyperbolic"]);

const LIQUID_CURVES = ["linear", "geometric_progression"] as const;
type LiquidCurves = "linear" | "geometric_progression";

const WATER_CURVES = ["linear", "geometric_progression", "dc_water_cut_vol", "dc_ln_vnf_vol"] as const;
type WaterCurves = "linear" | "geometric_progression" | "dc_water_cut_vol" | "dc_ln_vnf_vol";

type AllApproximations = OilCurves | LiquidCurves | WaterCurves;
const ALL_APPROXIMATIONS = new Set([...OIL_CURVES, ...LIQUID_CURVES, ...WATER_CURVES] as string[]);

type ScalarApproximations = OilCurves | LiquidCurves;
const SCALAR_APPROXIMATIONS = new Set([...OIL_CURVES, ...LIQUID_CURVES] as string[]);

// будут ещё метрики ручного задания прогноза
type VectorApproximations = "dc_water_cut_vol" | "dc_ln_vnf_vol";
const VECTOR_APPROXIMATIONS = new Set((WATER_CURVES as readonly string[]).filter((v) => !SCALAR_APPROXIMATIONS.has(v)));

type FittingKeys = Omit<WellTechProduction, "factProduction" | "forecastProduction">;
const fittingKeys: Set<keyof FittingKeys> = new Set(["oilRateTFitted", "liquidRateM3Fitted", "waterCutVolFitted"]);

const isFittingKey = (key: string): key is keyof FittingKeys => {
  return fittingKeys.has(key as keyof FittingKeys);
};

const isApproximation = (key: string): key is AllApproximations => {
  return ALL_APPROXIMATIONS.has(key as AllApproximations);
};

const isScalarApproximation = (key: string): key is ScalarApproximations => {
  return SCALAR_APPROXIMATIONS.has(key as ScalarApproximations);
};

const isVectorApproximation = (key: string): key is VectorApproximations => {
  return VECTOR_APPROXIMATIONS.has(key as VectorApproximations);
};

const isNeedK = (key: string): key is "duong" | "stretched_exponential" | "hyperbolic" => NEED_K.has(key);

const PARAM_META = {
  linear: {
    a: {
      min: -100,
      max: 100,
      default: {
        liquid: -0.25,
        oil: 0.25,
        waterCut: 0.25,
      },
    },
  },
  geometric_progression: {
    a: {
      min: -100,
      max: 100,
      default: {
        liquid: 0.01,
        oil: 0.02,
        waterCut: -0.01,
      },
    },
  },
  exponential: {
    a: {
      min: 0,
      max: 100,
      default: {
        liquid: 0.05,
        oil: 0.05,
        waterCut: 0.05,
      },
    },
  },
  harmonic: {
    a: {
      min: 0,
      max: 100,
      default: {
        liquid: 0.05,
        oil: 0.05,
        waterCut: 0.05,
      },
    },
  },
  hyperbolic: {
    a: {
      min: 0,
      max: 1,
      default: {
        liquid: 0.1,
        oil: 0.1,
        waterCut: 0.1,
      },
    },
    k: {
      min: 0,
      max: 1000,
      default: {
        liquid: 1,
        oil: 1,
        waterCut: 1,
      },
    },
  },
  duong: {
    a: {
      min: -2,
      max: 2,
      default: {
        liquid: 0.5,
        oil: 0.5,
        waterCut: 0.5,
      },
    },
    k: {
      min: 0,
      max: 100,
      default: {
        liquid: 1,
        oil: 1,
        waterCut: 1,
      },
    },
  },
  stretched_exponential: {
    a: {
      min: -Infinity,
      max: Infinity,
      default: {
        liquid: 0.5,
        oil: 0.5,
        waterCut: 0.5,
      },
    },
    k: {
      min: -Infinity,
      max: Infinity,
      default: {
        liquid: 1,
        oil: 1,
        waterCut: 1,
      },
    },
  },
} as const;

const CURVES_TR: Record<string, ReactNode> = {
  linear: "Линейная зависимость",
  geometric_progression: "Темп падения",
  exponential: "Арпс (экспоненциальный)",
  harmonic: "Арпс (гармонический)",
  hyperbolic: "Арпс (гиперболический)",
  duong: "Дуонг",
  stretched_exponential: "Растянутая экспонента",
  dc_water_cut_vol: (
    <>
      ХВ (W<sub>c</sub> - Отбор от НИЗ)
    </>
  ),
  dc_ln_vnf_vol: "ХВ (ln(ВНФ) - Накопленная добыча)",
};

const methodSelector = (mode: ForecastMode) => {
  const value = {
    liquid: LIQUID_CURVES,
    oil: OIL_CURVES,
    waterCut: WATER_CURVES,
  }[mode];
  return value.map((value) => ({ value, label: value in CURVES_TR ? CURVES_TR[value] : value }));
};

// это для нового фонда, когда А и К можно только задать
type OilNew = FN<A<OilCurves>>;
type LiquidNew = FN<A<LiquidCurves>>;
type WaterNew = FN<{
  fnType: WaterCurves;
  x: number[];
  y: number[];
}>;

const BINDING_SELECTOR = [
  { value: "last_fact", label: "Последний фактический" },
  { value: "extrapolate", label: "Экстраполяция" },
  { value: "manual", label: "Задать" },
];

type Binding = "last_fact" | "extrapolate" | number;
type BindingMode = "last_fact" | "extrapolate" | "manual";

type BaseAddition = {
  intervalParams: {
    startStep?: number;
    endStep?: number;
    skippedSteps?: number[];
  };
  binding?: Binding;
};

// Тут А и К не задаются, вместо этого нужно задать диапазон, на основе которого построятся А и К, не понятно почему только тут биндинг
type OilBase = FNType<OilCurves> & BaseAddition;
type LiquidBase = FNType<LiquidCurves> & BaseAddition;
type WaterBase = FNType<WaterCurves> & BaseAddition;

// тут для части, не связанной с А и К всё-равно нужен интервал, но А и К заданы руками, их вычислять не надо
type OilBaseSpecified = OilNew & BaseAddition;
type LiquidBaseSpecified = LiquidNew & BaseAddition;

// аналоги полностью отдельные
type Analogs = {
  isApplyConstruction: boolean;
  prodMonthDuration: number | null;
  oilRatio: number | null;
  oilRatioDiv: number | null;
  liquidRatio: number | null;
  liquidRatioDiv: number | null;
  hidden: string[];
};

type Excel = {
  values: number[] | null;
};

type Fitting = {
  a: number;
  k: number | null;
  b: number;
  mse: number;
  r2: number;
  xAttr: "steps";
  yAttr: "liquid_rate_m3" | "liquid_rate_t" | "oil_rate_m3" | "oil_rate_t";
  x: number[];
  y: number[];
  yRaw?: number[];
};

type WellTechProduction = Omit<WellFactProduction, "factProduction"> & {
  factProduction?: Production;
  forecastProduction: Production;
  oilRateTFitted?: Fitting;
  liquidRateM3Fitted?: Fitting;
  waterCutVolFitted?: Fitting;
};

const FITTING_RESULT_MAP = {
  oil: "oilRateTFitted",
  liquid: "liquidRateM3Fitted",
  waterCut: "waterCutVolFitted",
} as const;

type StopCriterion = {
  waterCut: number | null;
  waterCutMin: null | number;
  oilRate: number | null;
  oilProdMonthDuration: number | null;
  oilDropRate: number | null;
  liquidDropRate: number | null;
  recoverableResources: number | null;
  accumOilProd: number | null;
};

const getTechForecast = (req: any, isStoppedWell: boolean | undefined): Promise<WellTechProduction[]> => {
  if (isStoppedWell) {
    const nullPlug: WellTechProduction[] = [];
    if ("liquidRateM3Decline" in req && typeof req.liquidRateM3Decline.binding !== "number") {
      return Promise.resolve(nullPlug);
    }
    if ("oilRateTDecline" in req && typeof req.oilRateTDecline.binding !== "number") {
      return Promise.resolve(nullPlug);
    }
  }
  return reqCamel.post<WellTechProduction[]>("decline-new/forecast", Array.isArray(req) ? req : [req]) as any as Promise<WellTechProduction[]>;
};

type TechResultsForSave = Pick<WellTechProduction, "forecastProduction" | "wellId" | "gtmId" | "stratumId" | "scenarioId">;

type TechFlags = {
  isCorrectLiquidRateM3: boolean;
  isCorrectOilRateT: boolean;
  isCorrectWaterCutVol: boolean;
};
type TechFlagsStorage = Record<string, TechFlags>;

const TECH_FLAGS_STORAGE_KEY = "tech_result_flags" as const;

const getTechFlags = (projectId: number, forecastId: number): Promise<TechFlagsStorage | null> => {
  return backendStorage.getItem<TechFlagsStorage>(TECH_FLAGS_STORAGE_KEY, projectId, forecastId);
};

const setTechFlags = (projectId: number, forecastId: number, flags: TechFlagsStorage) => {
  return backendStorage.setItem<TechFlagsStorage>(flags, TECH_FLAGS_STORAGE_KEY, projectId, forecastId);
};

const submitTechForecast = async (req: TechResultsForSave[]): Promise<unknown> => {
  return await reqCamel.post("decline-new/forecast-production", req);
};

type TechResultsForDelete = Omit<TechResultsForSave, "forecastProduction">;

const deleteTechForecast = (req: TechResultsForDelete[]): Promise<TechResultsForDelete[]> => {
  return reqLogged.delete<TechResultsForDelete[]>("decline-new/forecast-production", req);
};

type OverlapsData = {
  wellId: number;
  scenarioId: number;
};

const deleteOverlaps = (req: OverlapsData[]): Promise<OverlapsData[]> => {
  return reqCamel.delete<OverlapsData[]>("productions/gtm-production-overlaps/", req);
};

type AnalogsRequest = {
  scenarioId: number;
  prodMonthDuration: number;
  stratumId: number | null;
  producingObjectId: number | null;
  wellTypeId: number | null;
  wellIds: number[] | null;
  oilRatio: number | null;
  oilRatioDiv: number | null;
  liquidRatio: number | null;
  liquidRatioDiv: number | null;
};

type AnalogsResult = {
  items: Array<{
    factProduction: Production;
    scenarioId: number;
    stratumId: number;
    gtmId?: number;
    wellId: number;
    wellTypeId: number;
  }>;
  liquidRateM3Fitted: Fitting;
  oilRateTFitted: Fitting;
};

const itemKey = ({ wellId, stratumId, gtmId }: AnalogsResult["items"][number]): string => [wellId, stratumId, gtmId].join();

const getAnalogs = (req: AnalogsRequest, onlyFittings: boolean = false): Promise<AnalogsResult> => {
  return reqCamel.post<AnalogsResult>(`decline-new/analog-wells${onlyFittings ? "?only_fittings=true" : ""}`, req);
};

const RATE_FITTED = {
  liquid: "liquidRateM3Fitted",
  oil: "oilRateTFitted",
} as const;

export {
  ALL_APPROXIMATIONS,
  type AllApproximations,
  type Analogs,
  type AnalogsRequest,
  type AnalogsResult,
  type Binding,
  BINDING_SELECTOR,
  type BindingMode,
  CURVES_TR,
  deleteOverlaps,
  deleteTechForecast,
  type Excel,
  type Fitting,
  FITTING_RESULT_MAP,
  type FittingKeys,
  type ForecastMode,
  getAnalogs,
  getTechFlags,
  getTechForecast,
  isApproximation,
  isFittingKey,
  isNeedK,
  isScalarApproximation,
  isVectorApproximation,
  itemKey,
  LIQUID_CURVES,
  type LiquidBase,
  type LiquidBaseSpecified,
  type LiquidNew,
  methodSelector,
  NEED_K,
  OIL_CURVES,
  type OilBase,
  type OilBaseSpecified,
  type OilNew,
  PARAM_META,
  RATE_FITTED,
  SCALAR_APPROXIMATIONS,
  type ScalarApproximations,
  setTechFlags,
  type StopCriterion,
  submitTechForecast,
  type TechFlags,
  type TechFlagsStorage,
  type TechResultsForSave,
  VECTOR_APPROXIMATIONS,
  type VectorApproximations,
  WATER_CURVES,
  type WaterBase,
  type WaterNew,
  type WellTechProduction,
};
