import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3569 3.14258H10.4998C11.7621 3.14258 12.7855 4.16593 12.7855 5.42829V8.48043C12.4281 8.35415 12.0435 8.28544 11.6429 8.28544H11.6426V5.42829C11.6426 4.79711 11.1309 4.28544 10.4998 4.28544H7.64233C7.95793 4.28544 8.21376 4.0296 8.21376 3.71401C8.21376 3.39842 7.95793 3.14258 7.64233 3.14258C7.32674 3.14258 7.07091 3.39842 7.07091 3.71401C7.07091 4.0296 7.32674 4.28544 7.64233 4.28544H4.78547C4.15429 4.28544 3.64261 4.79711 3.64261 5.42829V11.714C3.64261 12.3452 4.15429 12.8569 4.78547 12.8569H8.40941C8.55993 13.2827 8.79219 13.67 9.08734 13.9997H4.78547C3.5231 13.9997 2.49976 12.9764 2.49976 11.714V5.42829C2.49976 4.16593 3.52311 3.14258 4.78547 3.14258H5.92833C5.92848 2.51152 6.4401 2 7.07118 2H8.21404C8.84513 2 9.35675 2.51152 9.3569 3.14258ZM10.4995 4.57143C10.4995 5.04482 10.1157 5.42857 9.64233 5.42857L5.64233 5.42857C5.16895 5.42857 4.78519 5.04481 4.78519 4.57143V4.28571L5.64233 4.28571L5.64282 4.28571H9.64233L10.4995 4.28571V4.57143ZM14.3297 10.9781C14.5545 10.7566 14.5571 10.3948 14.3356 10.17C14.1141 9.94527 13.7523 9.94263 13.5275 10.1642L11.6093 12.0546L10.9011 11.3567C10.6764 11.1352 10.3146 11.1378 10.093 11.3626C9.87151 11.5874 9.87416 11.9492 10.0989 12.1707L11.2082 13.2639C11.4307 13.4831 11.7879 13.4831 12.0104 13.2639L14.3297 10.9781Z"
      fill="currentColor"
    />
  </g>
);

const ApproveIcon: FC<SharedIconProps> = (props) => <Icon {...props} viewBox="0 0 16 16" content={ASSET} />;

export { ApproveIcon };
