import { FC, useMemo } from "react";
import { Table } from "@okopok/components/Table/Table";
import { Select } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Loader } from "elements/loader";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { axisInference, channelKeys, META } from "features/techForecast/models/well/wellTechChartChannelsMeta";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import { ResultTableStore } from "./resultTableStore";

import cn from "./resultTable.module.less";

const ResultTable: FC = observer(() => {
  const model = useTechForecastModel();
  const result = model.currentForecast.channels;
  const store = useMemo(() => new ResultTableStore(result!) ?? undefined, [result]);

  const event = model.wells.atEvent(model.selected, 0);
  const wellTitle = `${event.well.title} ${[event.well.title, event.intervention?.typeName, event.well.type, event.stratum?.title].filter(Boolean)}`;

  const columns = useMemo<Column[]>(() => {
    if (!result) return [];

    const titleToAxisMap: { [key: string]: { axis: string; dataKey: string }[] } = {};
    const metaColumns: Column[] = [];

    channelKeys(result).forEach((key) => {
      let title = META[key]?.title ?? key;
      let axis = axisInference(key);

      if (title.includes("обводнённость")) {
        axis = title.includes("Весовая") ? "массовая, %" : "объёмная, %";
        title = "Обводнённость";
      }

      if (!titleToAxisMap[title]) {
        titleToAxisMap[title] = [{ axis, dataKey: key }];
        metaColumns.push({
          title,
          dataKey: key,
          type: "number",
          width: key === "waterCutVol" || key === "waterCutMass" ? 280 : { min: 210, max: 210, competitiveness: 1 },
        });
      } else {
        const axisExists = titleToAxisMap[title].some((item) => item.axis === axis);
        if (axis && !axisExists) {
          titleToAxisMap[title].push({ axis, dataKey: key });
        }
      }
    });

    const resultColumns = metaColumns.map((col) => {
      const title = typeof col.title === "string" ? col.title : "";
      const axes = titleToAxisMap[title];

      if (axes.length > 1) {
        const selectedAxis = store.selectedAxes.get(title) || axes[0].axis;
        const currentDataKey = axes.find((a) => a.axis === selectedAxis)?.dataKey || col.dataKey;
        return {
          ...col,
          dataKey: currentDataKey,
          title: (
            <div className={cn.title}>
              {title}
              <Select
                defaultValue={selectedAxis}
                value={selectedAxis}
                onChange={(newValue: string) => store.setSelectedAxes(title, newValue)}
                options={axes.map((axisObj) => ({ value: axisObj.axis, label: axisObj.axis }))}
              />
            </div>
          ),
          exportTitle: `${title}, ${selectedAxis}`,
        };
      }
      return {
        ...col,
        title: axes[0] ? `${title}, ${axes[0].axis}` : title,
      };
    });

    return [
      {
        title: "Дата",
        dataKey: "date",
        type: "date",
        renderFormat: "date",
        width: 140,
        isSticky: true,
      },
      ...resultColumns,
    ]; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, store, store.isChangedSelectedAxes]);

  return (
    <Loader wrapperClassName={cn.loader} spinning={result === undefined}>
      <div className={cn.layout}>
        <SimpleTableContext exportFileName={`Прогноз по скважине ${wellTitle}`} data={store} columns={columns} hideExpandColumn>
          <PageFrameTitlePortal model={store ?? undefined} />
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </SimpleTableContext>
      </div>
    </Loader>
  );
});

export { ResultTable };
