/* eslint-disable @typescript-eslint/no-unused-vars */
type OperatingGTMData = {
  title: string;
  dataKey: string;
  children: {
    title: string;
    dataKey: string;
    measure?: string;
    accuracy?: number | null;
    data?: (number | null)[];
    children?: {
      title: string;
      dataKey: string;
      measure?: string;
      accuracy?: number | null;
      data?: (number | null)[];
    }[];
  }[];
}[];
const commonParams = [
  {
    title: "Среднее количество дней работы скважины",
    measure: "сут",
  },
  {
    title: "Количество ГТМ",
    measure: "шт",
  },
  {
    title: "Удельное количество ГТМ",
    measure: "шт/скв",
  },
  {
    title: "Коэффициент снижения эффекта ГТМ во времени",
    measure: "доли ед",
  },
  {
    title: "Средний прирост дебита на одну скважину",
    measure: "т/сут",
  },
  {
    title: "Доп. добыча за год проведения ГТМ",
    measure: "тыс т",
  },
  {
    title: "Переходящая доп. добыча от ГТМ",
    measure: "тыс т",
  },
];

const PARAMS: {
  title: string;
  dataKey: string;
  children: {
    title: string;
    dataKey: string;
    measure?: string;
    children?: { title: string; dataKey: string; measure: string }[];
  }[];
}[] = [
  {
    title: "Действующий фонд добывающих скважин",
    dataKey: "miningWells",
    children: [
      {
        title: "Количество скважин базового фонда",
        dataKey: "miningWells-1",
        measure: "скв",
      },
      {
        title: "Количество ГТМ",
        dataKey: "miningWells-2",
        measure: "шт",
      },
      {
        title: "Доп. добыча за год проведения ГТМ",
        dataKey: "miningWells-3",
        measure: "тыс т",
      },
      {
        title: "Переходящая доп. добыча от ГТМ",
        dataKey: "miningWells-4",
        measure: "тыс т",
      },
      {
        title: "Коэффициент эксплуатации скважины",
        dataKey: "miningWells-5",
        measure: "д. ед.",
      },
    ],
  },
  {
    title: "Действующий фонд нагнетательных скважин",
    dataKey: "pumpingWells",
    children: [
      {
        title: "Количество скважин",
        dataKey: "pumpingWells-1",
        measure: "скв",
      },
      {
        title: "Количество ремонтов нагнетательных скважин",
        dataKey: "pumpingWells-2",
        measure: "шт",
      },
      {
        title: "Количество ФХ МУН",
        dataKey: "pumpingWells-3",
        measure: "шт",
      },
      {
        title: "Доп. добыча за год проведения ФХ МУН",
        dataKey: "pumpingWells-4",
        measure: "тыс т",
      },
      {
        title: "Переходящая доп. добыча от ФХ МУН",
        dataKey: "pumpingWells-5",
        measure: "тыс т",
      },
    ],
  },
  {
    title: "Оптимизация",
    dataKey: "optimization",
    children: commonParams.map((params, index) => ({ ...params, dataKey: `optimization-${index + 1}` })),
  },
  {
    title: "РИР",
    dataKey: "rir",
    children: commonParams.map((params, index) => ({ ...params, dataKey: `rir-${index + 1}` })),
  },
  {
    title: "ГРП",
    dataKey: "grp",
    children: commonParams.map((params, index) => ({ ...params, dataKey: `grp-${index + 1}` })),
  },
  {
    title: "Дострел/реперфорация",
    dataKey: "perforation",
    children: commonParams.map((params, index) => ({ ...params, dataKey: `perforation-${index + 1}` })),
  },
  {
    title: "Прочие ОПЗ",
    dataKey: "otherOPZ",
    children: commonParams.map((params, index) => ({ ...params, dataKey: `otherOPZ-${index + 1}` })),
  },
  {
    title: "Прочие ГТМ",
    dataKey: "otherGTM",
    children: [
      {
        title: "Интегральные показатели",
        dataKey: "otherGTM-i",
        children: commonParams.map((params, index) => ({ ...params, dataKey: `otherGTM-i-${index + 1}` })),
      },
      {
        title: "Спуск ГНО",
        dataKey: "otherGTM-s",
        children: [
          {
            title: "Количество ГТМ",
            dataKey: "otherGTM-s-1",
            measure: "шт",
          },
          {
            title: "Доп. добыча за год проведения ГТМ",
            dataKey: "otherGTM-s-2",
            measure: "тыс т",
          },
        ],
      },
      {
        title: "Перевод скважин на мехдобычу",
        dataKey: "otherGTM-p",
        children: [
          {
            title: "Количество ГТМ",
            dataKey: "otherGTM-p-1",
            measure: "шт",
          },
          {
            title: "Доп. добыча за год проведения ГТМ",
            dataKey: "otherGTM-p-2",
            measure: "тыс т",
          },
        ],
      },
      {
        title: "Пароциклические обработки",
        dataKey: "otherGTM-pa",
        children: [
          {
            title: "Количество ГТМ",
            dataKey: "otherGTM-pa-1",
            measure: "шт",
          },
          {
            title: "Доп. добыча за год проведения ГТМ",
            dataKey: "otherGTM-pa-2",
            measure: "тыс т",
          },
        ],
      },
      {
        title: "ОРЭ",
        dataKey: "otherGTM-o",
        children: [
          {
            title: "Количество ГТМ",
            dataKey: "otherGTM-o-1",
            measure: "шт",
          },
          {
            title: "Доп. добыча за год проведения ГТМ",
            dataKey: "otherGTM-o-2",
            measure: "тыс т",
          },
        ],
      },
      {
        title: "Ликвидация аварий",
        dataKey: "otherGTM-l",
        children: [
          {
            title: "Количество ГТМ",
            dataKey: "otherGTM-l-1",
            measure: "шт",
          },
          {
            title: "Доп. добыча за год проведения ГТМ",
            dataKey: "otherGTM-l-2",
            measure: "тыс т",
          },
        ],
      },
      {
        title: "Прочие работы по КРС",
        dataKey: "otherGTM-pr",
        children: [
          {
            title: "Количество ГТМ",
            dataKey: "otherGTM-pr-1",
            measure: "шт",
          },
          {
            title: "Доп. добыча за год проведения ГТМ",
            dataKey: "otherGTM-pr-2",
            measure: "тыс т",
          },
        ],
      },
      {
        title: "Сезонно работающие скважины",
        dataKey: "otherGTM-se",
        children: [
          {
            title: "Количество ГТМ",
            dataKey: "otherGTM-se-1",
            measure: "шт",
          },
          {
            title: "Доп. добыча за год проведения ГТМ",
            dataKey: "otherGTM-se-2",
            measure: "тыс т",
          },
        ],
      },
    ],
  },
  {
    title: "Физико-химические методы ПНП (справочно)",
    dataKey: "physic",
    children: [
      {
        title: "Количество ФХ МУН",
        dataKey: "physic-1",
        measure: "шт",
      },
      {
        title: "Удельное количество ГТМ",
        dataKey: "physic-2",
        measure: "шт/скв",
      },
      {
        title: "Коэффициент снижения эффекта ГТМ во времени",
        dataKey: "physic-3",
        measure: "доли ед",
      },
      {
        title: "Удельная добыча от ФХ МУН",
        dataKey: "physic-4",
        measure: "тыс т/ГТМ",
      },
      {
        title: "Доп. добыча за год проведения ФХ МУН",
        dataKey: "physic-5",
        measure: "тыс т",
      },
      {
        title: "Переходящая доп. добыча от ФХ МУН",
        dataKey: "physic-6",
        measure: "тыс т",
      },
    ],
  },
  {
    title: "Ремонт нагнетательных скважин",
    dataKey: "repair",
    children: [
      {
        title: "Количество ГТМ",
        dataKey: "repair-1",
        measure: "шт",
      },
      {
        title: "Удельное количество ГТМ",
        dataKey: "repair-2",
        measure: "шт/скв",
      },
    ],
  },
];

const getOperatingGTM = async (): Promise<OperatingGTMData> => {
  return PARAMS.map((parentParam) => ({
    ...parentParam,
    accuracy: 3,
    children: parentParam.children.map((child) => ({
      ...child,
      accuracy: (() => {
        switch (child.dataKey) {
          case "miningWells-1":
          case "miningWells-2":
          case "pumpingWells-1":
          case "pumpingWells-2":
          case "pumpingWells-3":
          case "grp-1":
          case "grp-2":
          case "perforation-2":
          case "otherOPZ-2":
          case "physic-1":
          case "repair-1":
            return 0;
          default:
            return null;
        }
      })(),
    })),
  }));
};

const operatingTitleResolver = (dataKey: string, childDataKey?: string, subChildDataKey?: string): string => {
  if (childDataKey !== undefined && subChildDataKey !== undefined) {
    return (
      PARAMS.find((param) => param.dataKey === dataKey)
        ?.children.find((child) => child.dataKey === childDataKey)
        ?.children?.find((child) => child.dataKey === subChildDataKey)?.title ?? ""
    );
  }
  if (childDataKey !== undefined) {
    return (
      PARAMS.find((param) => param.dataKey === dataKey)?.children.find((child) => child.dataKey === childDataKey)
        ?.title ?? ""
    );
  }
  return PARAMS.find((param) => param.dataKey === dataKey)?.title ?? "";
};

export { getOperatingGTM, operatingTitleResolver };
