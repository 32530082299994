import { FC, PropsWithChildren } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { TableItem, Widget } from "@okopok/components/Table";
import { Button, Checkbox, Col, Input, Row, Tooltip, Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { DelayedShow } from "elements/modal/delayShow";
import { SimpleTableContext } from "features/tableDebug/simpleTable";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { optimizeReq } from "services/back/optimize";

import { useOptimizerModalContext } from "./optimizerModalProvider";

import cn from "./useOptimizedModal.module.less";

type OptimizerModalProps = {
  accept: () => void;
  reject: () => void;
};

const { Text: AntText } = Typography;

const Text: FC<PropsWithChildren & TextProps> = ({ children, className, ...props }) => (
  <AntText className={classNames(cn.text, className)} {...props}>
    {children}
  </AntText>
);

const onRow = (tableItem: TableItem<any>) => tableItem.value.optimizerModel?.onRow(tableItem);

const OptimizerModal: FC<OptimizerModalProps> = observer(({ accept, reject }) => {
  const model = useOptimizerModalContext();
  const forecast = useForecast()!;
  const fact = useFact()!;
  const {
    columns,
    store,
    setAccumulatedNpv,
    selectedDelete,
    setCurrentNpv,
    setCurrentCf,
    changeFilterRemove,
    changeFilterStop,
    currentNpv,
    currentCf,
    accumulatedNpv,
    selectedStop,
  } = model;

  const apply = async () => {
    model.setUpload(true);
    if (model.createCopy) {
      const newForecast = await forecast.copy(model.scenarioCopyTitle, `Копия создана при оптимизации сценария ${forecast.title}`);
      await optimizeReq({ ...model.summary, scenarioId: newForecast.id });
      fact!.forecasts.insertCopy(newForecast, newForecast.id);
      newForecast.dropCalculation();
      newForecast.wells.refresh();
      newForecast.interventions.init();
    } else {
      await optimizeReq(model.summary);
      forecast?.dropCalculation();
      forecast?.wells.refresh();
      forecast?.interventions.init();
    }

    model.setUpload(false);
    accept();
  };

  return (
    <SimpleTableContext
      columns={columns}
      data={store}
      exportFileName="test"
      showSelectColumn
      tableOptions={{
        onRow,
      }}
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
      topExportIcon={104}
    >
      <div className={cn.container}>
        <div className={cn.header}>
          <Row className={cn["header-row"]} align="middle">
            <Col span={5}>
              <Text>Удаление скважин(НФ + Инвестиционные ГТМ)</Text>
            </Col>
            <Col span={3}>
              <Text>Накопленный NPV</Text>
            </Col>
            <Col span={2}>
              <InputNumber
                placeholder="млн долл"
                bordered
                value={accumulatedNpv}
                onUpdate={(v) => setAccumulatedNpv(v)}
                disabled={model.isUploaded}
              />
            </Col>
            <Col span={14} className={cn["filter"]}>
              <Text>Выбрано: {selectedDelete} скв</Text>
              <Tooltip title="Отобразить только скважины на исключение">
                <Button
                  className={cn["filter-button"]}
                  icon={<WarningOutlined />}
                  danger
                  disabled={selectedDelete === 0}
                  onClick={changeFilterRemove}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row className={cn["header-row"]} align="middle">
            <Col span={5}>
              <Text>Остановка(БФ, НФ + Инвестиционные ГТМ)</Text>
            </Col>
            <Col span={3}>
              <Text>Текущий NPV</Text>
            </Col>
            <Col span={2}>
              <InputNumber placeholder="млн долл" bordered value={currentNpv} onUpdate={(v) => setCurrentNpv(v)} disabled={model.isUploaded} />
            </Col>
            <Col span={3}>
              <Text className={cn.cf}>Текущий CF</Text>
            </Col>
            <Col span={2}>
              <InputNumber placeholder="млн руб" bordered value={currentCf} onUpdate={(v) => setCurrentCf(v)} disabled={model.isUploaded} />
            </Col>
            <Col span={9} className={cn["filter"]}>
              <Text>Выбрано: {selectedStop} скв</Text>
              <Tooltip title="Отобразить только скважины, которые будут остановлены">
                <Button className={cn["filter-button"]} icon={<WarningOutlined />} danger disabled={selectedStop === 0} onClick={changeFilterStop} />
              </Tooltip>
            </Col>
          </Row>
        </div>
        <div className={cn.table}>
          <DelayedShow delay={200}>
            <Widget headerClassName={cn.tableHeader} />
          </DelayedShow>
        </div>
        <div className={cn.footer}>
          <Button onClick={apply} type="primary" loading={model.isUploaded}>
            Применить настройки оптимизации
          </Button>
          <Button onClick={reject} className={cn["cancel-button"]}>
            Отмена
          </Button>
          <Input
            disabled={!model.createCopy}
            className={cn.input}
            value={model.scenarioCopyTitle}
            onChange={(e) => model.setCopyTitle(e.target.value)}
          />
          <Checkbox className={cn.checkbox} checked={model.createCopy} onChange={model.changeCreateCopy}>
            Создать копию сценария с результатами оптимизации
          </Checkbox>
        </div>
      </div>
    </SimpleTableContext>
  );
});

export { OptimizerModal };
