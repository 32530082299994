import { FC, PropsWithChildren, useEffect } from "react";
import { observer } from "mobx-react";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { global } from "models/global";
import { useProjects } from "models/project/projects";

const PreloaderLogs: FC<PropsWithChildren> = observer(({ children }) => {
  const projects = useProjects()!;
  const projectsArr = [undefined, ...Array.from(projects.values!)];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => global.logger.loadAllLogs(projectsArr.map((project) => project?.id)), []);

  if (global.logger.isLoading || global.logger.logsNumber !== projectsArr.length) {
    return <FullScreenLoader />;
  }

  return <>{children}</>;
});

export { PreloaderLogs };
