import { FC, ReactNode } from "react";
import { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";

import { Mine, SegmentType } from "services/back/infrastructure/calculate";

import { calculateDistance, getSelectedText } from "../utils";

import { InfrastructureMapManager, NodeType, PipeType } from "./InfrastructureMapManager";

import cn from "../InfrastructureMap.module.less";

type ParamType = [title: string, value: number];

type TooltipParamsProps = {
  title: [kind: string, title: string];
  firstParam?: ParamType;
  secondParam?: ParamType;
  date?: Dayjs;
};

const ToltipParams: FC<TooltipParamsProps> = ({ title, firstParam, secondParam, date }) => {
  return (
    <div className={cn.tooltipWrapper}>
      <div className={cn.tooltipActionTitle}>Кликните, чтобы выбрать</div>
      <div className={cn.tooltipValues}>
        <div>{title[0]}</div>
        <div className={cn.tooltipValuesTitle}>{title[1]}</div>
        {firstParam && (
          <>
            <div>{firstParam[0]}</div>
            <div>{firstParam[1].toFixed(3)}</div>
          </>
        )}
        {secondParam && (
          <>
            <div>{secondParam[0]}</div>
            <div>{secondParam[1].toFixed(3)}</div>
          </>
        )}
        {(firstParam || secondParam) && (
          <>
            <div>Дата</div>
            <div>{date && date.format("MM.YYYY")}</div>
          </>
        )}
      </div>
    </div>
  );
};

type ActiveObjectType = { uuid: string; type: "node" | "pipe" } | null;
class TooltipInfrastructureMap {
  private activeObject: ActiveObjectType = null;
  private hideTooltip: boolean = false;

  constructor(private manager: InfrastructureMapManager) {
    makeObservable<this, "distanceMeasurementText" | "position" | "activeObject" | "hideTooltip" | "activeNode" | "activePipe" | "calculationData">(
      this,
      {
        activeObject: observable,
        hideTooltip: observable,
        setActiveObject: action,
        setHideTooltip: action,
        tooltip: computed,
        distanceMeasurementText: computed,
        position: computed,
        activeNode: computed,
        activePipe: computed,
        calculationData: computed,
      }
    );
  }

  private get calculationData(): { pipes: SegmentType[]; mines: Mine[] } {
    const data = this.manager.infrastructure.calculateStore.hydraulicData;
    if (!data) return { mines: [], pipes: [] };
    return {
      mines: data.mines,
      pipes: data[this.manager.pipeMode === "oil" ? "prod" : "inj"]?.segments ?? [],
    };
  }

  private calculationDataAt = (uuid: string, type: "mine" | "pipe") => {
    if (type === "mine") {
      return this.calculationData.mines.find((el) => el.nodeUuid === uuid);
    } else {
      return this.calculationData.pipes.find((el) => el.uuid === uuid);
    }
  };

  private get activeNode(): ({ isFactual: boolean; kind: string } & (NodeType & Partial<Mine>)) | null {
    if (this.activeObject === null) return null;

    const { uuid, type } = this.activeObject;
    const { nodes } = this.manager;

    if (type === "node") {
      const node = nodes.find((node) => node.uuid === uuid);
      const calculateNode = node?.type === "mine" ? this.calculationDataAt(uuid, "mine") : {};

      let kind = "";
      if (node?.type === "node") {
        kind = "Узел";
      } else if (node?.type === "mine") {
        kind = "Куст";
      } else if (node?.type === "drain") {
        kind = "Пункт сбора";
      } else if (node?.type === "source") {
        kind = "Источник";
      } else if (node?.type === "externalSource") {
        kind = "Внешний источник";
      } else {
        kind = "Насосная станция";
      }

      return node ? { ...node, isFactual: !!node.isFactual, kind, ...calculateNode } : null;
    }
    return null;
  }

  private get activePipe(): ({ isFactual: boolean } & (Partial<PipeType> & Partial<SegmentType>)) | null {
    if (this.activeObject === null) return null;

    const { uuid, type } = this.activeObject;
    const { pipes } = this.manager;

    if (type === "pipe") {
      const pipe = pipes.find((pipe) => pipe.uuid === uuid);
      const calculatePipe = (this.calculationDataAt(uuid, "pipe") ?? {}) as any;
      return pipe ? { ...pipe, ...calculatePipe, isFactual: pipe.oisLength !== null } : null;
    }
    return null;
  }

  private get position() {
    const { cursorPosition } = this.manager;
    if (cursorPosition === null) {
      return { x: 0, y: 0 };
    }
    return { x: cursorPosition.x.toFixed(2), y: cursorPosition.y.toFixed(2) };
  }

  private get distanceMeasurementText() {
    const distance = calculateDistance({ from: this.manager.ruler, to: this.manager.zoomedCursorPosition });
    return `Расстояние: ${(distance || 0 * 100).toFixed(2)} м. Кликните еще раз, чтобы прекратить измерение`;
  }

  public get tooltip(): string | ReactNode | undefined {
    const lineEndUuid = this.manager.lineEnd?.uuid;

    if (this.hideTooltip || this.manager.isDragging) {
      return undefined;
    }

    if (this.manager.isSelection) {
      if (this.activeObject !== null) {
        if (!(this.activeNode ?? this.activePipe)?.isSelected) {
          if (this.activePipe !== null) {
            const pipe = this.activePipe;
            const isNotData = !this.manager.calculateStore.hydraulicData;
            const firstParam: ParamType | undefined = !isNotData ? ["Скорость потока, м/сек", pipe.velocity ?? 0] : undefined;
            const secondParam: ParamType | undefined = !isNotData ? ["Градиент давления, атм/км", pipe.pressureGradient ?? 0] : undefined;
            return (
              <ToltipParams
                title={["Трубопровод", pipe.title ?? ""]}
                firstParam={firstParam}
                secondParam={secondParam}
                date={this.manager.infrastructure.currentDate}
              />
            );
          }

          if (this.activeNode !== null) {
            const node = this.activeNode;
            const isNotData = node.type !== "mine" || !this.manager.calculateStore.hydraulicData;
            const firstParam: ParamType | undefined = !isNotData ? ["Расход жидкости, т/сут", node.fluidRateT ?? 0] : undefined;
            const secondParam: ParamType | undefined = !isNotData ? ["Расход нефти, т/сут", node.oilRateT ?? 0] : undefined;
            return (
              <ToltipParams
                title={[node.kind, node.title]}
                firstParam={firstParam}
                secondParam={secondParam}
                date={this.manager.infrastructure.currentDate}
              />
            );
          }
        } else {
          return getSelectedText(this.manager.selection.selectedNodeIds.length, this.manager.selection.selectedPipeIds.length);
        }
      }
      if (!this.manager.selection.isGrab) {
        return "Кликните, чтобы начать выделение";
      }
    }
    if (this.manager.isRuler) {
      if (this.activeObject !== null) {
        return undefined;
      }
      if (!this.manager.ruler) {
        return "Кликните  для того, чтобы начать измерять расстояние";
      } else {
        return this.distanceMeasurementText;
      }
    }
    if (this.manager.isDrawing) {
      if (!(this.manager.lineEnd as NodeType).uuid) {
        return "Кликните, чтобы создать новый объект, который будет соединен трубопроводом";
      }
    }
    if (this.manager.isCreate) {
      if (this.activeObject !== null) {
        if (this.activeObject?.type === "node") {
          if (this.manager.isDrawing && lineEndUuid && this.manager.newPipe?.start!.uuid !== lineEndUuid) {
            return "Кликните по этому объекту, чтобы связать его трубопроводом";
          } else {
            return "Кликните по объекту, чтобы провести от него трубопровод или зажмите левую кнопку мыши, чтобы перетащить его";
          }
        }
      }

      if (this.manager) return `Кликните, чтобы создать объект с координатами: X: ${this.position.x} Y:${this.position.y}`;
    }
    return undefined;
  }

  public setActiveObject = (v: ActiveObjectType | null) => {
    this.activeObject = v;
  };

  public setHideTooltip = (v: boolean) => {
    this.hideTooltip = v;
  };
}

export { TooltipInfrastructureMap };
