import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { NodeType } from "models/project/fact/infrastructure/nodes";

type DRow = {
  title: string;
  startedAt: Dayjs;
  coords: string;
  altitude: number;
  remove?: () => void;
};

class Element extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    ...this.data,
    coords: `X: ${this.coords.x.toFixed(2)} Y: ${this.coords.y.toFixed(2)}`,
    remove: this.remove,
  });

  public readonly data: NodeType;

  constructor(private parent: DrainSources, data: NodeType) {
    super(parent);

    this.data = data;
    makeObservable(this, {
      data: observable,
      coords: computed,
      updateValue: action,
    });
  }

  get coords() {
    return { x: this.data.x || 0, y: this.data.y || 0 };
  }

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    const [k, value] = [key as keyof NodeType, newValue as never];
    if (key === "startedAt" || key === "title" || key === "altitude") {
      value && this.parent.infrastructure.nodes.update({ ...this.data, [k]: value });
      const prev = this.data[k];
      this.data[k] = value;
      return [prev, value];
    }
    return [undefined, undefined];
  }

  public remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }
    this.parent.infrastructure.nodes.remove([this.data.uuid]);
    this.parent.childrenStore?.splice(this.index, 1);
  };
}

class DrainSources extends TableNode<DRow, Element> {
  constructor(public infrastructure: Infrastructure, private mode: "drain" | "source") {
    super();

    this.initChildren();
  }

  private initChildren() {
    const nodes = this.infrastructure.nodes.data || [];
    const drainSources = nodes.filter(({ type }) => type === this.mode);
    this.childrenStore = new ChildrenStoreArray(
      this,
      drainSources.map((el) => new Element(this, el))
    );
  }

  public save = () => {
    this.infrastructure.save();
    this.mutationsManager?.dropMutations();
    this.addedChildren?.clear();
    this.deletedChildren?.clear();
  };
}

export { DrainSources, type DRow as DRowDrainSources };
