import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.083 8.61169H20.861C21.4133 8.61169 21.861 9.05941 21.861 9.61169C21.861 10.164 21.4133 10.6117 20.861 10.6117H17.0834C16.2472 10.6123 15.5708 11.2893 15.5706 12.123C15.5713 12.9576 16.2476 13.634 17.0831 13.6349H20.861C21.4133 13.6349 21.861 14.0826 21.861 14.6349C21.861 15.1871 21.4133 15.6349 20.861 15.6349H17.083C15.1445 15.6337 13.5718 14.0633 13.5706 12.1239C13.5706 10.1834 15.145 8.61288 17.083 8.61169Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.89729 5C5.74492 5 4 6.74492 4 8.89729V15.5951C4 17.7475 5.74492 19.4924 7.89729 19.4924H15.9636C18.116 19.4924 19.8609 17.7475 19.8609 15.5951V8.89729C19.8609 6.74492 18.116 5 15.9636 5H7.89729ZM2 8.89729C2 5.64035 4.64035 3 7.89729 3H15.9636C19.2205 3 21.8609 5.64035 21.8609 8.89729V15.5951C21.8609 18.8521 19.2205 21.4924 15.9636 21.4924H7.89729C4.64035 21.4924 2 18.8521 2 15.5951V8.89729Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.23235 8.23519C6.23235 7.68291 6.68007 7.23519 7.23235 7.23519H12.2708C12.8231 7.23519 13.2708 7.68291 13.2708 8.23519C13.2708 8.78748 12.8231 9.23519 12.2708 9.23519H7.23235C6.68007 9.23519 6.23235 8.78748 6.23235 8.23519Z"
      fill="currentColor"
    />
  </g>
);

const EconomicIcon: FC<SharedIconProps> = (props) => <Icon {...props} viewBox="0 0 24 24" content={ASSET} />;

export { EconomicIcon };
