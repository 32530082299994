import { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Loader } from "elements/loader";
import { FilterPicker } from "elements/tree/filterPicker";
import { Tree as TreeRoot } from "elements/tree/model/tree";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";

import { Chart } from "./chart";
import { SummaryModel } from "./summaryModel";
import { Table } from "./table";

const View: FC<{ tree: TreeRoot<Well>; className?: string }> = observer(({ tree, className }) => {
  const forecast = useForecast()!;

  const summaryModel = useMemo(() => new SummaryModel(tree, forecast), [forecast, tree]);
  const { filters } = summaryModel;

  return (
    <>
      <PageFrameTitlePortal>
        <FilterPicker value={filters.selected} groups={filters.groups} onChange={filters.onChange} />
      </PageFrameTitlePortal>
      <div className={className}>
        {summaryModel.data?.length ? (
          <>
            <Chart summaryModel={summaryModel} />
            <Table summaryModel={summaryModel} />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
});

export { View };
