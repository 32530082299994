import { FC } from "react";
import { Radio, Tooltip } from "antd";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";

import { ReactComponent as PressureIcon } from "./icons/pressure.svg";
import { ReactComponent as VelocityIcon } from "./icons/velocity.svg";
import { PipeColorMode, useInfrastructureMapContext } from "./InfrastructureMapManager/InfrastructureMapManager";
import { HidePipesButton } from "./hidePipesButton";
import { LEGEND_MAP_COLOR } from "./utils";

import cn from "./InfrastructureMap.module.less";

const modePipeColor = [
  {
    value: "velocity",
    title: "Скорость потока",
    icon: <VelocityIcon />,
  },
  {
    value: "pressure",
    title: "Давление",
    icon: <PressureIcon />,
  },
];

const LegendMap: FC<{ mode: PipeColorMode; range: [number, number] }> = observer(({ mode, range }) => {
  const manager = useInfrastructureMapContext();
  const [minValue, maxValue] = range;
  return (
    <div className={cn.legendMap}>
      {manager.calculateStore.hydraulicData && (
        <div className={cn.actionButtons}>
          <Radio.Group value={manager.pipeColorMode} buttonStyle="solid" onChange={({ target }) => manager.setPipeColorMode(target.value)}>
            {modePipeColor.map((el, index) => (
              <Tooltip key={index} title={manager.showMapTooltip ? el.title : undefined}>
                <Radio.Button type="text" value={el.value}>
                  <Icon width="16px" height="16px" viewBox="0 0 16 16" content={el.icon} />
                </Radio.Button>
              </Tooltip>
            ))}
          </Radio.Group>
          <HidePipesButton />
        </div>
      )}

      <div className={cn.legendFlex}>
        <div className={cn.horizontalLine}></div>
        Построенный трубопровод
      </div>
      <div className={cn.legendFlex}>
        <div className={cn.dottedLine}></div>
        Проектируемый трубопровод
      </div>
      <div>
        <div className={cn.scaleLabels}>
          <Format>{minValue}</Format>
          <span>{mode === "pressure" ? "Градиент давления, атм/км" : "Скорость потока, м/с"}</span>
          <Format>{maxValue}</Format>
        </div>
        <div className={cn.colorBlock} style={{ background: `linear-gradient(to right, ${LEGEND_MAP_COLOR.join(", ")})` }}></div>
      </div>
    </div>
  );
});

export { LegendMap };
