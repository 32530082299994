import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { PopconfirmProps } from "antd";
import { makeObservable, observable, runInAction } from "mobx";

import { ProjectParticipantInfo } from "services/back/projectParticipants";
import { ADMIN_ROLES_IDS, assignFunctionalRole, deleteFunctionalRole } from "services/back/roles";
import { addUser, deleteUser } from "services/back/users";

import { global } from "./global";

type UserInfo = Omit<ProjectParticipantInfo, "roles"> & {
  remove?: (email: string) => Promise<void>;
  removeConfirm?: PopconfirmProps;
  roles: number[];
};

const getAction = (prev: number[], newValue: number[]): { action: "deleteRole" | "addRole"; value: number } => {
  if (prev.length > newValue.length) {
    return { action: "deleteRole", value: prev.find((pValue) => !newValue.includes(pValue))! };
  } else {
    return { action: "addRole", value: newValue.find((nValue) => !prev.includes(nValue))! };
  }
};

const roleTitleToId = (roleTitle: string) => global.roles.find(({ title }) => title === roleTitle)!.id;

class UsersStore extends TableNode<UserInfo, ParticipantNode> {
  constructor(private canDelete?: boolean) {
    super();

    this.init();
  }

  init = () => {
    const participants = Array.from(global.users.values!);

    this.childrenStore = new ChildrenStoreArray(
      this,
      participants.map(
        (info) =>
          new ParticipantNode(
            this,
            { ...info, roles: info.roles.map(roleTitleToId) },
            this.addFunctionalRole,
            this.removeFunctionalRole,
            this.canDelete ? () => this.deleteUser(info.email) : undefined,
            {
              title: "Удалить пользователя?",
              cancelText: "Отмена",
              okText: "Удалить",
            }
          )
      )
    );
  };

  deleteUser = async (email: string) => {
    const { id } = await deleteUser(email);
    global.users.delete(id);
    this.init();
  };

  addUser = async (email: string) => {
    const newUser = await addUser(email);
    if (newUser.id && !global.users.at(newUser.id)) {
      global.users.add(newUser);
      this.init();
    }
  };

  addFunctionalRole = async (userId: number, roleId: number) => {
    try {
      await assignFunctionalRole(roleId, userId);
      global.users.reset();
      return true;
    } catch {
      return false;
    }
  };

  removeFunctionalRole = async (userId: number, roleId: number) => {
    try {
      await deleteFunctionalRole(roleId, userId);
      global.users.reset();
      return true;
    } catch {
      return false;
    }
  };
}

class ParticipantNode extends TableNode<UserInfo> {
  public asDRow(): UserInfo {
    return {
      ...this.personInfo,
      remove: this.remove,
      removeConfirm: this.removeConfirm,
    };
  }
  public updateValue?: (key: keyof UserInfo, newValue: any) => [prevValue: any, currValue: any];

  constructor(
    parentNode: UsersStore,
    public readonly personInfo: Omit<UserInfo, "roles"> & { roles: number[] },
    public readonly addRole: (userId: number, roleId: number) => Promise<boolean>,
    public readonly deleteRole: (userId: number, roleId: number) => Promise<boolean>,
    public readonly remove?: (email: string) => Promise<void>,
    public readonly removeConfirm?: PopconfirmProps
  ) {
    super(parentNode, { isExpandedChildren: true });
    runInAction(() => (this.childrenStore = null));

    const isAdmin = global.user?.roles.includes(global.roles.at(ADMIN_ROLES_IDS[0])!.title);
    if (isAdmin) {
      this.updateValue = (key: keyof UserInfo, newValue: any): [prevValue: any, currValue: any] => {
        if (key === "roles") {
          const prev = this.personInfo.roles;
          const { action, value } = getAction(prev, newValue);

          this[action](this.personInfo.id, value).then((res) => {
            if (res) {
              this.personInfo.roles = newValue;
            }
          });

          return [prev, newValue];
        }
        return [undefined, undefined];
      };
    }

    makeObservable(this, {
      personInfo: observable,
    });
  }
}

export { UsersStore };
export type { UserInfo };
