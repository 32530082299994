import { FC, memo } from "react";

import { NodeType } from "../InfrastructureMapManager/InfrastructureMapManager";

import { ReactComponent as DefaultNodeIcon } from "./icons/defaultNode.svg";
import { ReactComponent as DefaultNodeDisabledIcon } from "./icons/defaultNodeDisabled.svg";
import { ReactComponent as DefaultNodeSelectedIcon } from "./icons/defaultNodeSelected.svg";
import { ReactComponent as DefaultOilNodeIcon } from "./icons/defaultOilNode.svg";
import { ReactComponent as DefaultWaterNodeIcon } from "./icons/defaultWaterNode.svg";
import { ReactComponent as DrainIcon } from "./icons/drain.svg";
import { ReactComponent as DrainDisabledIcon } from "./icons/drainDisabled.svg";
import { ReactComponent as DrainDisabledDateIcon } from "./icons/drainDisabledDate.svg";
import { ReactComponent as DrainSelectedIcon } from "./icons/drainSelected.svg";
import { ReactComponent as ExternalSourceIcon } from "./icons/externalSource.svg";
import { ReactComponent as ExternalSourceDisabledIcon } from "./icons/externalSourceDisabled.svg";
import { ReactComponent as ExternalSourceSelectedIcon } from "./icons/externalSourceSelected.svg";
import { ReactComponent as MineDisabledDateIcon } from "./icons/mineDisabledDate.svg";
import { ReactComponent as MineFactualIcon } from "./icons/mineFactual.svg";
import { ReactComponent as MineFactualSelectedIcon } from "./icons/mineFactualSelected.svg";
import { ReactComponent as MineProjectIcon } from "./icons/mineProject.svg";
import { ReactComponent as MineProjectDisabledIcon } from "./icons/mineProjectDisabled.svg";
import { ReactComponent as MineProjectSelectedIcon } from "./icons/mineProjectSelected.svg";
import { ReactComponent as PumpingIcon } from "./icons/pumping.svg";
import { ReactComponent as PumpingDisabledIcon } from "./icons/pumpingDisabled.svg";
import { ReactComponent as PumpingSelectedIcon } from "./icons/pumpingSelected.svg";
import { ReactComponent as SourceIcon } from "./icons/source.svg";
import { ReactComponent as SourceDisabledIcon } from "./icons/sourceDisabled.svg";
import { ReactComponent as SourceDisabledDateIcon } from "./icons/sourceDisabledDate.svg";
import { ReactComponent as SourceSelectedIcon } from "./icons/sourceSelected.svg";
import { ReactComponent as PumpingDisabledDateIcon } from "./icons/stationDisabledDate.svg";

const icons = {
  node: <DefaultNodeIcon />,
  nodeWater: <DefaultWaterNodeIcon />,
  nodeOil: <DefaultOilNodeIcon />,
  drain: <DrainIcon />,
  pumping: <PumpingIcon />,
  source: <SourceIcon />,
  externalSource: <ExternalSourceIcon />,
} as const;

const iconsSelected = {
  node: <DefaultNodeSelectedIcon />,
  drain: <DrainSelectedIcon />,
  pumping: <PumpingSelectedIcon />,
  source: <SourceSelectedIcon />,
  externalSource: <ExternalSourceSelectedIcon />,
} as const;

const iconsDisabled = {
  node: <DefaultNodeDisabledIcon />,
  drain: <DrainDisabledIcon />,
  pumping: <PumpingDisabledIcon />,
  source: <SourceDisabledIcon />,
  externalSource: <ExternalSourceDisabledIcon />,
} as const;

const iconsDisabledDate = {
  node: <DefaultNodeDisabledIcon />,
  drain: <DrainDisabledDateIcon />,
  pumping: <PumpingDisabledDateIcon />,
  source: <SourceDisabledDateIcon />,
  externalSource: <ExternalSourceDisabledIcon />,
} as const;

type Props = {
  isDisabled: boolean;
  isDisabledDate: boolean;
  isSelected: boolean;
  type: NodeType["type"];
  pipeMode: "oil" | "water";
  isFactual?: boolean;
};

const Icon: FC<Props> = memo(({ isDisabled, isDisabledDate, isSelected, type, pipeMode, isFactual }) => {
  if (type === "mine") {
    if (isDisabled) {
      // Если нужна отдельная иконка для «фактической шахты» в disabled
      return isFactual ? <MineDisabledDateIcon /> : <MineProjectDisabledIcon />;
    }

    if (isDisabledDate) {
      // Аналогично, если нужно различать фактическую шахту при disabledDate:
      // (Нет отдельной иконки? — по желанию можно подставить ту же что и mineProjectDisabledIcon,
      // или оставить, как удобнее.)
      return isFactual ? <MineFactualIcon /> : <MineProjectDisabledIcon />;
    }

    if (isSelected) {
      return isFactual ? <MineFactualSelectedIcon /> : <MineProjectSelectedIcon />;
    }

    // Обычное состояние (не выделено, не отключено)
    return isFactual ? <MineFactualIcon /> : <MineProjectIcon />;
  }

  if (isDisabled) {
    return iconsDisabled[type];
  } else if (isDisabledDate) {
    return iconsDisabledDate[type];
  } else if (isSelected) {
    return iconsSelected[type];
  } else if (type === "node") {
    return pipeMode === "oil" ? icons.nodeOil : icons.nodeWater;
  }

  return icons[type] || <DefaultNodeIcon />;
});

export { Icon };
