import { ECY } from "models/project/fact/ecy";
import type { Forecast } from "models/project/fact/forecast/forecast";
import type { Range } from "utils/range";

import { CALCULATE_PREDEFINE } from "./calculatePredefine";
import { USCModel } from "./types";

function extractUSCModels(forecast: Forecast): USCModel[] {
  console.assert(forecast.ecyStore.isLoading === false, "Запрос ЕСУ до завершения загрузки");
  return [...(forecast.ecyStore.values ?? [])].map((usc) => ({
    uuid: usc.id,
    discount_coef: usc.discount,
    schema_usc: ecySchema(usc, forecast.wholeRange),
  }));
}

function ecySchema(usc: ECY, range: Range) {
  const predef = CALCULATE_PREDEFINE["usc_model"][0].schema_usc.schema;
  const ecyState = usc.metrics!.flatMap((m) => m.flatten());
  const schema = [];
  for (const metric of predef) {
    if (metric.parent_id === undefined) {
      metric.parent_id = null;
    }
    if (metric.editable.not_editable === true || metric.unit.quantity === 0) {
      if (metric.title === "Год") {
        metric.values = [...range];
      }
      schema.push(metric);
      continue;
    }
    if (metric.title === "Нетто-цена нефти на базисе ФСА (направление 3)") {
      schema.push({ ...metric, values: range.array.fill(null) });
      continue;
    }
    const stateMetric = ecyState.find((m) => m.code_title === metric.code_title);
    if (stateMetric === undefined) {
      throw new Error(`EcyMetric not found: '${metric.title}', '${metric.code_title}'`);
    }
    let values = stateMetric.values ?? range.array.fill(null);
    if (values.length !== range.length) {
      throw new Error(`Metric incomplete (${values.length}/${range.length}): ${metric.title} ${values}`);
    }
    schema.push({ ...metric, values });
  }
  return { schema };
}

export { extractUSCModels };
